import React, {useState, useContext, useEffect} from 'react';
import {Dimensions, View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, ActivityIndicator} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { UtilContext } from '../../../contexts/utilidade';
import { AuthContext } from '../../../contexts/auth';

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}){
    const [valueempresa, setValueEmpresa] = useState('');  
    const [loading, setLoading] =useState(false);
    const { Aviso, accessdev } = useContext(UtilContext);
    const { user} = useContext(AuthContext);
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [dadosempresa2, setDadosEmpresa2]=useState([]);
    /* const dadosempresa2 = [{
        ITEM: 1,
        DADOSMSQL: 'MySql - OK',
        DADOSRM: 'RM - OK',
        ATIVO: 1
    }] */

    const [nomeempresa, setNomeEmpresa]=useState('');

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading)
        setTimeOutLoading(0);
   
        if(loading)
        setTimeout(() => {
         setTimeOutLoading((parseInt(timeoutloading)+1))
       }, 1000);
     }, [loading, timeoutloading]);
    
    async function buscaempresa(valueempresa){
        try {
            if(valueempresa==''){
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## PESQUISA ##', // This is a title
                    'CNPJ é obrigatório!!!',
                    {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => { { } }, // Call your cancel function 
                        onConfirm: () => { { } } // Call your confirm function 
                    }
                )
                return false;
            }
            if(valueempresa.length != 11 && valueempresa.length != 14){
                alert(`Tamanho do CNPJ inválido!! CNPJ: ${valueempresa}`);
                return false;
            }
            setLoading(true);
            setNomeEmpresa('');
            setDadosEmpresa2([]);
            await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/buscaempresaconsulta`, {
                    method: 'POST',
                    body: JSON.stringify({
                        cnpj: valueempresa
                    }),
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    //console.log(JSON.stringify(responseJson))
                    if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    }
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                          //Aviso("Contratos", 'Retornando Lista de Contratos', "success", 3000); 
                          //console.log(JSON.stringify(responseJson));
                          setDadosEmpresa2(responseJson.dados);
                          //alert(JSON.stringify(responseJson))
                          setNomeEmpresa(`${responseJson.dados[0].NOME}`);
                      }

                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}

    }
    
    async function cadastroempresa(valueempresa){
        try {
            if(valueempresa==''){
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## PESQUISA ##', // This is a title
                    'CNPJ é obrigatório!!!',
                    {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => { { } }, // Call your cancel function 
                        onConfirm: () => { { } } // Call your confirm function 
                    }
                )
                return false;
            }
            if(valueempresa.length != 11 && valueempresa.length != 14){
                alert(`Tamanho do CNPJ inválido!! CNPJ: ${valueempresa}`);
                return false;
            }
            setLoading(true);
            setNomeEmpresa('');
            setDadosEmpresa2([]);
            await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/buscaempresa`, {
                    method: 'POST',
                    body: JSON.stringify({
                        cnpj: valueempresa
                    }),
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    //console.log(JSON.stringify(responseJson))
                    if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    }
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                          //Aviso("Contratos", 'Retornando Lista de Contratos', "success", 3000); 
                          //console.log(JSON.stringify(responseJson));
                          //setDadosEmpresa2(responseJson.dados);
                          //alert(JSON.stringify(responseJson))
                          //setNomeEmpresa(`${responseJson.dados[0].NOME}`);
                          alert(responseJson.cadmysql);
                          buscaempresa(valueempresa);
                      }

                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}

    }

    function getempresas({ item: empresa }) {
        //alert(JSON.stringify(empresa))
        return (    
            <View style={{ /* width: width*0.83,  */ flexDirection: 'column', backgroundColor: empresa.ITEM %2 ? '#D3D3D3' : ''}}>
              <View style={{flexDirection:'row',}}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>MYSQL:</Text>
                <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{empresa.DADOSMSQL.replace('MySql - ', '')}</Text>
                {(empresa.DADOSMSQL != 'MySql - OK' && empresa.DADOSRM == 'RM - OK') ? (                
                    <TouchableOpacity
                        style={{borderTopEndRadius:5, borderBottomEndRadius:5, left: -9, backgroundColor: '#004b9e', height: 30, width: 160, alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => { 
                            cadastroempresa(valueempresa);                                                                  
                            //alert(`Adicionar CNPJ ${empresa.CNPJ}, informe o Desenvolvedor!!!\nSistema inclusão não foi incluso no sistema.`);
                        }}
                        >
                        <View style={{ width: '100%', flexDirection: 'row', }}>
                            <Icon name="user-plus" size={15} color="#eee" style={{ padding:7,}} /> 
                            <Text style={{position: 'relative', fontWeight: 'bold', top: 6, color:'#FFF'}}>Incluir no MYSQL</Text>                                               
                        </View>
                    </TouchableOpacity>                
                ):null}
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>RM:</Text>
                <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{`${empresa.DADOSRM.replace('RM - ','')} - LOGIN ATIVO: ${empresa.ATIVO==1 ? 'SIM' : 'NÃO'}`}</Text>
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>Válido:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Icon name={(empresa.DADOSMSQL == 'MySql - OK' && empresa.ATIVO == 1) ? "check" : "times"} size={15} color={(empresa.DADOSMSQL == 'MySql - OK'  && empresa.ATIVO == 1) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                         
            </View>          
        )
      }


    return(
        <View style={{flexDirection:'column', padding: 10}}>
            <View style={{padding: 5, flexDirection: 'row', alignItems: 'center'}}>
                    <TextInput
                        placeholder={'Informe o CNPJ'}
                        style={{width: 250,height: 35,borderBottomStartRadius: 5, borderTopStartRadius: 5, borderWidth:3, padding: 5,borderColor:'#ddd', borderRadius:5 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        value={valueempresa}
                        onChangeText={(item) => {setValueEmpresa(item.replaceAll(".","").replaceAll("/","").replaceAll("-",""))}}
                        onSubmitEditing={(item) => {
                            buscaempresa(valueempresa);                            
                        }}                
                    />                            
                    <View style={{position:'relative', flexDirection:'row'}}>
                        <View style={{flex: 1, padding: 10}}>
                                <TouchableOpacity
                                    style={{borderTopEndRadius:5, borderBottomEndRadius:5, left: -9, backgroundColor: '#004b9e', height: 30, width: 30, alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => { 
                                        buscaempresa(valueempresa);                                                                  
                                    }}
                                    >
                                    <View style={{ width: '100%', flexDirection: 'row', }}>
                                        <Icon name="search" size={15} color="#eee" style={{ padding:7,}} />                                                
                                    </View>
                                </TouchableOpacity>
                        </View>
                    </View>
            </View>
            {nomeempresa!='' ? (
            <View style={{padding: 5, flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 15, fontWeight:'bold'}}>{`Empresa: ${nomeempresa!='' ? nomeempresa : ''}`}</Text>
                    <Text style={{fontSize: 13, }}> Se aparecer 
                        <Icon name="times" size={13} color="#FF0000" style={{paddingLeft: 5, paddingRight: 5}}/>
                        Login com pendencia para acessar o portal
                    </Text>
            </View>
            ):null}
            <View style={{padding: 5}}>
                {loading ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={50} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                   
                  </View>  
                ) : (
                  <>                   
                    <FlatList
                      data={dadosempresa2}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getempresas(item)}
                    />  
                  </>
                )} 
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        resizeMode: 'stretch',
        width: width,
        height: height,
      },
  });