module.exports = {
    URLT2K: 'https://t2kconsultoria.com.br/',
    LITERATUS: 'https://gpliterarest.literatus.edu.br',
    LITERATUSDEV: 'https://restdev.literatus.edu.br',
    
    SERVER_PHP: 'https://rel.literatus.edu.br',
    
    //SERVER_PHP2: 'https://bol.literatus.edu.br',
    TIMEOUT_CONNECTION: 20000,
    
}