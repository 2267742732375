import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Alert, Linking, Modal, TextInput, Pressable } from 'react-native'
import { CommonActions, useNavigation,useIsFocused  } from '@react-navigation/native';
//import {Card, TextInput} from 'react-native-paper'
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, RadioButton} from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import Tooltip from "react-native-walkthrough-tooltip";

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App() {
  const { Aviso, accessdev } = useContext(UtilContext);
  const navigation = useNavigation();
  const { user, signOut } = useContext(AuthContext);
  const [lstlogins, setlstlogins] = useState([]);
  const [pagnumber, setPagNumber] = useState(1);
  const [totalpag, setTotalPag] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingreset, setLoadingReset] = useState(-1);
  const [loadingexcluir, setLoadingExcluir] = useState(-1);
  const [loadingfaltas, setLoadingFaltas] = useState(false);
  const [showTip, setTip] = useState(false);
  const [alunosfaltas, setAlunosFaltas] = useState();
  const [nomedoaluno, setNomeAluno] = useState();
  const [alunosativos, setAlunosAtivo] = useState('S');
  const [modalVisible,setmodalVisible] = useState(false);
  const [login, setlogin] = useState('');
  const [loginnome, setloginnome] = useState('');
  const [loginpwd, setloginpwd] = useState('abc@123');
  const [loginadmin, setloginadmin] = useState(false);
  const [loginadminti, setloginadminTI] = useState(false);
  const [logintrocasenha, setlogintrocasenha] = useState(true);
  const [showpwd, setShowPwd] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [datafilter, setDataFilter] = useState('');
  /* const [lmt, setLmt] = useState(0);
  const [totallogin, setTotalLogin] = useState(0); */

  const RowspPage = 16;

  function capitalize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
       
        var w = words[a];
        if (
            w.toLowerCase() !== 'de' 
            && w.toLowerCase() !== 'da' 
            && w.toLowerCase() !== 'das' 
            && w.toLowerCase() !== 'do' 
            && w.toLowerCase() !== 'dos' 
            && w.toLowerCase() !== 'em' 
            && w.toLowerCase() !== 'e'  
          ){
          words[a] = w[0].toUpperCase() + w.slice(1);
        }else{
          words[a] = w[0] + w.slice(1);
        }
    }
    return words.join(" ");
  }

  function useTogglePasswordVisibility() {
    if (rightIcon === 'eye') {
        setRightIcon('eye-slash');
        setShowPwd(!showpwd);
    } else if (rightIcon === 'eye-slash') {
        setRightIcon('eye');
        setShowPwd(!showpwd);
    }
  }
  
  
  function getlogin({ item: login }) {
    return (
      <View style={{}}>
        <View style={{ width: width*0.9,  flexDirection: 'row', backgroundColor: login.item %2 ? '#D3D3D3' : ''}}>
          <View style={{ width: 100, left: 5 }}><Text style={{}}>{login.item}</Text></View>
          <View style={{ width: 150 }}><Text style={{}}>{login.login}</Text></View>
          <View style={{ width: 450 }}><Text style={{}}>{login.nome}</Text></View>
          <View style={{ width: 100 }}>
            {/* <Text style={{}}>{login.admin==0 ? 'NÃO' : 'SIM'} </Text> */}
            <Icon name={login.admin==0 ? "user-alt-slash": "user-check"} size={20} color={login.admin==0 ? "#A9A9A9": "green"} style={{padding: 1,}}/>
         </View>
          <View style={{ width: 100}}>
                {/* <Text style={{}}>{login.newpwd==0 ? 'NÃO' : 'SIM'}</Text> */}
                <Icon name={login.newpwd==1 ? "user-clock": "user-check"} size={20} color={login.newpwd==1 ? "#A9A9A9": "green"} style={{padding: 1,}}/>
          </View>
          <View style={{ width: 100, left: 30 }}>
            {(login.login!='segundo') ? (
            <TouchableOpacity onPress={() => {
                
                if(!user?.adminti && !user?.admin){
                    Aviso('Reset Senha Login', 'Perfil não tem autorização', 'danger');  
                    return false;
                }
                
                
                //alert(`ID: ${login.id}\nLogin: ${login.login}\n Nome: ${login.nome}\nDeseja Realmente Excluir?`);
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## REINICIAR Senha ##', // This is a title
                    `Login: ${login.login}\nNome: ${login.nome}\nSenha Default será: abc@123\nDeseja Realmente Continuar?`, // This is a alert message
                    {
                        textConfirm: 'SIM',      // Label of button confirm
                        textCancel: 'NÃO',     // Label of button cancel
                        onCancel: () => {{}}, // Call your cancel function 
                        onConfirm: () => {{
                            resetlogin(login.id, login.login);
                        }} // Call your confirm function 
                    }
                    )
            }}>
                    {/* <Text style={{fontWeight: 'bold', color: 'blue'}}>Reset</Text> */}
                    {loadingreset !== login.id ? (
                        <Icon name="user-lock" size={20} color="blue" style={{padding: 1,}}/>
                    ) : (
                        <ActivityIndicator size={20} style={{position:'relative', left: -40}} color='#004b9e' />
                    )}
            </TouchableOpacity>
            ) : null}
          </View>
          <View style={{ width: 100, left: 10 }}>
          {(login.login!='segundo') ? (
            <TouchableOpacity onPress={() => {
                
                if(!user?.adminti){
                    Aviso('Excluir Login', 'Perfil não tem autorização', 'danger');  
                    return false;
                }
                
                //alert(`ID: ${login.id}\nLogin: ${login.login}\n Nome: ${login.nome}\nDeseja Realmente Excluir?`);
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## EXCLUIR Login ##', // This is a title
                    `Login: ${login.login}\n Nome: ${login.nome}\nDeseja Realmente Excluir?`, // This is a alert message
                    {
                        textConfirm: 'SIM',      // Label of button confirm
                        textCancel: 'NÃO',     // Label of button cancel
                        onCancel: () => {{}}, // Call your cancel function 
                        onConfirm: () => {{
                            deletelogin(login.id);
                        }} // Call your confirm function 
                    }
                    )
            }}>
                {/* <Text style={{fontWeight: 'bold', color: 'red'}}>Excluir</Text> */}
                
                {loadingexcluir !== login.id ? (
                    <Icon name="user-times" size={20} color="red" style={{padding: 1,}}/>
                ) : (
                    <ActivityIndicator size={20} style={{position:'relative', left: -40}} color='red' />
                )}
            </TouchableOpacity>
            ) : null}
          </View>
          <TouchableOpacity onPress={()=>{}}>
              <View style={{ width: '10%', alignItems: 'center', left: 23,}}><Text></Text></View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }  

  const fetchData = async (pgselect) => {
      try {
          setLoading(true);
          await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/showlogins/${RowspPage}/${pgselect?? 0}`, {
                  method: 'POST',
                  timeout: 5000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  setLoading(false); 
                  //console.log(responseJson)
                  if (!responseJson){
                      alert('Ocorreu um Erro'); 
                      return
                  }
                  if (responseJson.validtoken==false){
                      //console.log(responseJson);                    
                      //Aviso("Error:", responseJson.message, "danger", 3000); 
                      alert(responseJson.message); 
                      signOut();
                      //setLoading(false); 
                      return
                  }
                    
                    if(responseJson.dados.length > 0){
                        setlstlogins(responseJson.dados);
                        setTotalPag(responseJson.dados[0].total);
                        setPagNumber(responseJson.offset);
                    } 
                    setLoading(false);  
              })
              .catch((error) => {
                  //console.log(error)
                  Aviso("Error:", error.message, "danger", 3000);  
                  setLoading(false);                       
              })
              .finally(function () {    
                  setLoading(false);                     
              });  
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
          setLoading(false); 
      } finally {
          {
            setLoading(false); 
          }
      }
  }
  

  const deletelogin = async (id) => {
      try {
          setLoadingExcluir(id);
          await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/deletelogin/${id}`, {
                  method: 'POST',
                  timeout: 5000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  //setLoading(false);
                  setLoadingExcluir(-1); 
                  ///console.log(responseJson)
                  if (!responseJson){
                      alert('Ocorreu um Erro'); 
                      return
                  }
                  if (responseJson.validtoken==false){
                      //console.log(responseJson);                    
                      //Aviso("Error:", responseJson.message, "danger", 3000); 
                      alert(responseJson.message);
                      //setLoading(false);
                      setLoadingExcluir(-1);  
                      signOut();
                      //setLoading(false); 
                      return
                  }
                  //setLoading(false);
                  setLoadingExcluir(-1); 
                  if(responseJson.sucesso){
                      Aviso('EXCLUIR Login', 'Login Excluído com Sucesso!', 'success', 1500);
                      fetchData(0);
                  }else{
                      Aviso('EXCLUIR Login', responseJson.message, 'danger');
                  }
                     
              })
              .catch((error) => {
                  //console.log(error)
                  Aviso("Error:", error.message, "danger", 3000);  
                  //setLoading(false);
                  setLoadingExcluir(-1);                        
              })
              .finally(function () {    
                  //setLoading(false);
                  setLoadingExcluir(-1);                      
              });  
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
          //setLoading(false);
          setLoadingExcluir(-1);  
      } finally {
          {
            //setLoading(false);
            setLoadingExcluir(-1);  
          }
      }
  }
  

  const resetlogin = async (id, login) => {
      try {
          setLoadingReset(id);
          await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/resetlogin/${id}/${login}`, {
                  method: 'POST',
                  timeout: 5000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  //setLoading(false); 
                  setLoadingReset(-1);
                  ///console.log(responseJson)
                  if (!responseJson){
                      alert('Ocorreu um Erro'); 
                      return
                  }
                  if (responseJson.validtoken==false){
                      //console.log(responseJson);                    
                      //Aviso("Error:", responseJson.message, "danger", 3000); 
                      alert(responseJson.message); 
                      setLoadingReset(-1);
                      signOut();
                      //setLoading(false); 
                      return
                  }
                  //setLoading(false); 
                  setLoadingReset(-1);
                  if(responseJson.sucesso){
                      Aviso('SENHA', 'Senha Alterada com Sucesso!', 'success', 1500);
                      fetchData(0);
                  }else{
                      Aviso('SENHA', responseJson.message, 'danger');
                  }
                     
              })
              .catch((error) => {
                  //console.log(error)
                  Aviso("Error:", error.message, "danger", 3000);  
                  //setLoading(false); 
                  setLoadingReset(-1);                      
              })
              .finally(function () {    
                  setLoading(false); 
                  setLoadingReset(-1);                    
              });  
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
          //setLoading(false);
          setLoadingReset(-1); 
      } finally {
          {
            setLoading(false); 
            setLoadingReset(-1);
          }
      }
  }
  

  const fetchDataFiltro = async (dados) => {
      try {
          setLoading(true);
          await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/filterlogins/${dados}`, {
                  method: 'POST',
                  timeout: 5000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  setLoading(false); 
                 //console.log(responseJson)
                  if (!responseJson){
                      alert('Ocorreu um Erro'); 
                      return
                  }
                  if (responseJson.validtoken==false){
                      //console.log(responseJson);                    
                      //Aviso("Error:", responseJson.message, "danger", 3000); 
                      alert(responseJson.message); 
                      signOut();
                      //setLoading(false); 
                      return
                  }

                    if(!responseJson.sucesso){
                        alert(responseJson.message);
                        return
                    }
                    
                    if(responseJson.dados.length > 0){
                        setlstlogins(responseJson.dados);
                        setTotalPag(responseJson.dados[0].total);
                        //setPagNumber(responseJson.offset);
                    } 
                    setLoading(false);  
              })
              .catch((error) => {
                  //console.log(error)
                  Aviso("Error:", error.message, "danger", 3000);  
                  setLoading(false);                       
              })
              .finally(function () {    
                  setLoading(false);                     
              });  
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
          setLoading(false); 
      } finally {
          {
            setLoading(false); 
          }
      }
  }
  
  async function salvarlogin(){
    
    try {        
            if(login == '' || loginnome =='' || loginpwd == ''){
                Aviso('Novo Login', 'Informações Pendente(s)!', 'danger',);
                return
            }
          var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/newlogin`;          
          await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        login: login,
                        loginnome: loginnome,
                        loginpwd: loginpwd,
                        loginadmin: loginadmin,
                        loginadminti: loginadminti,
                        logintrocasenha: logintrocasenha,
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  if (responseJson.validtoken==false){
                    alert(responseJson.message); 
                    //signOut();
                    return
                  }
                  

                  if(responseJson.sucesso){
                    Aviso('Novo Login', responseJson.message, 'success', 1500);
                    limpar(); 
                    setmodalVisible(false);
                    fetchData(0);
                  }else{
                    Aviso('Novo Login', responseJson.message, 'danger');                    
                  }                  
                  
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000);                                       
              })
              .finally(function () { });  
            
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
         
      } finally {{}}
  }

  async function limpar(){
    setlogin('');
    setloginnome('');
    setloginpwd('abc@123');
    setloginadmin(false);
    setlogintrocasenha(true);
  }
  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        fetchData();
      }
  }, [focus]);

  return (
    <ImageBackground
      /* source={require(width > 500 ? '../../img/background5.png' : '../../img/background5.1.png')}
      style={[styles.imagebg, { width: width, height: '100%', paddingTop: 10 }]} */
    >
      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={{flexDirection: 'column'}}>
            <Card style={[styles.card,{marginTop: 10}]} elevation={5} mode={'elevated'}>
                <View style={{ flexDirection: 'row',alignSelf:  'flex-start', top: -5, }}>
                    <TouchableOpacity
                        onPress={() => {
                            if(!user?.adminti){
                                Aviso('Novo Login', 'Perfil não tem autorização', 'danger');  
                                return false;
                            }
                            setmodalVisible(true);
                        }}>
                        <View style={{flexDirection: 'row'}}>
                            <Icon name="user-plus" size={25} color="#004b9e" style={{padding: 10,}}/>
                            <Text style={{top: 15, position: 'relative', fontWeight: 'bold', color: '#004b9e'}}>NOVO</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{ flexDirection: 'row', alignItems: 'center', position:'relative', width: 300, left: width * 0.5  }}>
                        {/* <Text style={{ fontSize: 13, fontFamily: 'Arial', fontWeight: 'bold', margin: 5 }}>
                            FILTRO:
                        </Text> */}
                        <TextInput
                            placeholder={`Pesquisar (Login/Nome)`}
                            style={{ height: 30, width: '90%', backgroundColor: '#eee', padding:10, borderTopStartRadius:5, borderBottomStartRadius:5 }}
                            editable={true}
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType='default'
                            value={datafilter}
                            //maxLength={7}
                            onChangeText={(item) => setDataFilter(item)}
                            onSubmitEditing={()=>{
                                if(datafilter === ''){
                                    alert('Informe os dados a ser pesquisado');
                                    return
                                }
                                fetchDataFiltro(datafilter)}}
                        />
                        <TouchableOpacity 
                            onPress={() => {
                                if(datafilter === ''){
                                    alert('Informe os dados a ser pesquisado');
                                    return
                                }
                                fetchDataFiltro(datafilter);
                            }}>
                            <Icon name="search" size={20} color="#FFD700" style={{ padding: 5, borderTopEndRadius: 5, borderBottomEndRadius: 5, backgroundColor: '#004b9e', height: 30, alignItems: 'center', justifyContent: 'center' }} />
                        </TouchableOpacity>
                        {datafilter!==''?(
                        <TouchableOpacity 
                            onPress={() => {
                                setDataFilter('');
                                fetchData(0);
                            }}>
                            <Icon name="window-close" size={20} color="#FFD700" style={{ padding: 5, borderRadius: 5, backgroundColor: 'red', height: 30, alignItems: 'center', justifyContent: 'center', marginLeft: 20 }} />
                        </TouchableOpacity>
                        ):null}
                        </View>
                </View>       
            </Card>
            <Card style={[styles.card,{marginTop: 10, height: 500}]} elevation={5} mode={'elevated'}>
              <ScrollView style={{height: '100%'}}> 
                    <View style={{/*top: 0,  padding: 10 */ }}>   
                        <View style={{ width: width * 0.9, paddingTop: 1, flexDirection: 'row', borderBottomColor: '#000', borderBottomWidth: 1.5 }}>
                            <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>#</Text></View>
                            <View style={{ width: 150 }}><Text style={{ fontWeight: 'bold' }}>Login</Text></View>
                            <View style={{ width: 450 }}><Text style={{ fontWeight: 'bold' }}>Nome</Text></View>
                            <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Admin</Text></View>
                            <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>1° Acesso</Text></View>
                            <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Reset Senha</Text></View>
                            <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Excluir</Text></View>
                        </View>
                        {loading ? (
                            <View style={{ top: 10, alignSelf: 'center', height: 200 }}><ActivityIndicator size={50} color='#004b9e' /></View>  
                            ) : (
                            <FlatList
                                data={lstlogins}
                                style={{ width: '100%', height: '100%' }}
                                keyExtractor={(item) => item.item}
                                showsVerticalScrollIndicator={true}
                                renderItem={(item) => getlogin(item)}
                            />                           
                        )}
                        {!loading && datafilter == '' ? (
                            <View style={{flexDirection: 'row',alignItems: 'center', justifyContent: 'center', height: 50, width: '100%',}}>
                            <TouchableOpacity
                                onPress={() => {
                                
                                fetchData(0);
                                }}>
                                <Icon name="step-backward" size={25} color="#000" style={{padding: 10, marginRight: 50}}/>
                            </TouchableOpacity>
                            
                            <TouchableOpacity
                                onPress={() => {
                                fetchData((parseInt(pagnumber) - parseInt(RowspPage)) < 1 ? 0 : parseInt(pagnumber) - parseInt(RowspPage));
                                }}>
                                <Icon name="outdent" size={25} color="#000" style={{padding: 10,  marginRight: 50}}/>
                            </TouchableOpacity>
                            
                                {/* <Text style={{padding: 10,  marginRight: 50, fontWeight: 'bold'}}>{pagnumber} a {(pagnumber + (RowspPage -1)) > totalpag ? totalpag : pagnumber + (RowspPage - 1)}  de {totalpag}</Text> */}
                                <Text style={{padding: 10,  marginRight: 50, fontWeight: 'bold'}}>{parseInt(pagnumber) + 1} a {(parseInt(pagnumber) + parseInt(RowspPage)) > totalpag ? totalpag : parseInt(pagnumber) + parseInt(RowspPage)}  de {totalpag}</Text>
                            
                            <TouchableOpacity
                                onPress={() => {
                                
                                fetchData((parseInt(pagnumber) + parseInt(RowspPage))>=totalpag ? pagnumber : parseInt(pagnumber) + parseInt(RowspPage));
                                }}>
                                <Icon name="indent" size={25} color="#000" style={{padding: 10,   marginRight: 50}}/>
                            </TouchableOpacity>                  
                            
                            <TouchableOpacity
                                onPress={() => {
                                
                                fetchData(parseInt(totalpag) - parseInt( RowspPage ));
                                }}>
                                <Icon name="step-forward" size={25} color="#000" style={{padding: 10}}/>
                            </TouchableOpacity>                  
                        </View>
                        ):(<></>)}
                    </View>
              </ScrollView>               
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -5}}>
                    <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={20} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={20} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
            </View>
        </View>      
      </View>
      <Modal animationType='fade' transparent={true} visible={modalVisible}>
                <ScrollView>
                    <View style={styles.viewmodal}>
                        <Card style={[styles.card,{marginTop: 10, width: 500, height: 300, borderColor: '#004b9e', borderWidth: 2}]} elevation={5} mode={'elevated'}>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>                                 
                                <TextInput
                                    placeholder='Nome ex.: João Silva'
                                    style={[styles.textinput,{width: '90%',height: 30, borderRadius: 5, margin: 5,/*  backgroundColor: '#eee', padding: 10, borderColor: '#000', borderWidth: 2  */}]}
                                    editable={true}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    keyboardType='default'
                                    //maxLength={18}                
                                    value={loginnome}
                                    onChangeText={(item) => {setloginnome(item)}}
                                                    
                                /> 
                                <TextInput
                                    placeholder='Login de Acesso ex.: jsilva'
                                    style={[styles.textinput,{width: '90%',height: 30, borderRadius: 5, margin: 5,/*  backgroundColor: '#eee', padding: 10, borderColor: '#000', borderWidth: 2  */}]}
                                    editable={true}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    keyboardType='default'
                                    //maxLength={18}                
                                    value={login}
                                    onChangeText={(item) => {setlogin(item.toLowerCase())}}
                                                    
                                />
                                <View style={{width: '90%'}}>
                                <TextInput
                                    placeholder='Senha Inicial'
                                    style={[styles.textinput,{width: '100%',height: 30, borderRadius: 5, margin: 5,/*  backgroundColor: '#eee', padding: 10, borderColor: '#000', borderWidth: 2  */}]}
                                    editable={true}
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    keyboardType='default'
                                    //maxLength={18}                
                                    value={loginpwd}
                                    onChangeText={(item) => {setloginpwd(item)}}
                                    secureTextEntry={showpwd}
                                                    
                                />
                                <Pressable
                                    style={{ position: 'absolute', right: 5, top: 8 }}
                                    onPress={() => useTogglePasswordVisibility()}>
                                    <Icon name={rightIcon} size={22} color="#232323" />
                                </Pressable>
                                </View>
                            </View>
                            <View style={{flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap'}}>                                 
                                {(user.adminti && user.nome=="segundo" /*|| user.nome=="paulovictor"*/) ? (
                                <View style={{flexDirection: 'row', marginLeft: 8, alignItems: 'center'}}>
                                    <Text style={{fontWeight:'bold', color: '#004b9e', paddingRight: 123}}>AdminTI?</Text>
                                    <RadioButton.Group onValueChange={newValue => setloginadminTI(newValue)} value={loginadminti}>
                                        <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: 100,  borderTopEndRadius: 5}}>
                                            <View style={{flex:1, flexDirection:'row', alignItems:'center', justifyContent: 'center', marginRight: 40}}>
                                                <RadioButton value={true} />
                                                <TouchableOpacity onPress={() => setloginadminTI(true)}>
                                                    <Text>Sim</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'center',}}>
                                                <RadioButton value={false} />
                                                <TouchableOpacity onPress={() => setloginadminTI(false)}>
                                                <Text>Não</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </RadioButton.Group> 
                                </View>
                                ) : null}
                                {(user.adminti /*&& user.admin*/) ? (
                                <View style={{flexDirection: 'row', marginLeft: 8, alignItems: 'center'}}>
                                    <Text style={{fontWeight:'bold', color: '#004b9e', paddingRight: 85}}>Administrador?</Text>
                                    <RadioButton.Group onValueChange={newValue => setloginadmin(newValue)} value={loginadmin}>
                                        <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: 100,  borderTopEndRadius: 5}}>
                                            <View style={{flex:1, flexDirection:'row', alignItems:'center', justifyContent: 'center', marginRight: 40}}>
                                                <RadioButton value={true} />
                                                <TouchableOpacity onPress={() => setloginadmin(true)}>
                                                    <Text>Sim</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'center',}}>
                                                <RadioButton value={false} />
                                                <TouchableOpacity onPress={() => setloginadmin(false)}>
                                                <Text>Não</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </RadioButton.Group> 
                                </View>
                                ) : null}
                                <View style={{flexDirection: 'row', marginLeft: 8, alignItems: 'center'}}>
                                    <Text style={{fontWeight:'bold', color: '#004b9e', paddingRight: 30}}>Trocar Senha 1° Acesso?</Text>
                                    <RadioButton.Group onValueChange={newValue => setlogintrocasenha(newValue)} value={logintrocasenha}>
                                        <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: 100,  borderTopEndRadius: 5}}>
                                            <View style={{flex:1, flexDirection:'row', alignItems:'center', justifyContent: 'center', marginRight: 40}}>
                                                <RadioButton value={true} />
                                                <TouchableOpacity onPress={() => setlogintrocasenha(true)}>
                                                    <Text>Sim</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'center',}}>
                                                <RadioButton value={false} />
                                                <TouchableOpacity onPress={() => setlogintrocasenha(false)}>
                                                <Text>Não</Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </RadioButton.Group> 
                                </View>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', position: 'relative', marginTop: (user.nome="segundo") ? 10 : 40}}>
                                <TouchableOpacity
                                    onPress={() => {salvarlogin();}}
                                    style={{backgroundColor:'#004b9e', borderRadius: 5, left:7,justifyContent:'center', height: 30, width: 100}}
                                >
                                    <View style={{flexDirection: 'row'}}>
                                        <Icon name="save" size={25} color="#eee" style={{padding: 10,}}/>
                                        <Text style={{top: 15, position: 'relative', fontWeight: 'bold', color: '#eee'}}>SALVAR</Text>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        setmodalVisible(false);
                                        limpar();
                                    }}
                                    style={{backgroundColor:'#004b9e', borderRadius: 5, right:23,justifyContent:'center', height: 30, width: 100}}
                                >
                                    <View style={{flexDirection: 'row'}}>
                                        <Icon name="door-closed" size={25} color="#eee" style={{padding: 10,}}/>
                                        <Text style={{top: 15, position: 'relative', fontWeight: 'bold', color: '#eee'}}>SAIR</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>       
                        </Card>                      
                    </View>
                </ScrollView>
      </Modal>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  viewmodal: {
    //backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: width*0.86,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },
  textinput:{
    width: 250,
    padding: 10,
    borderColor: '#000',
    borderWidth:1,
    backgroundColor: '#eee',
    borderColor: '#999'
  },

});
