import React, {useState, useContext, useEffect} from 'react';
import {Dimensions, View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, ActivityIndicator} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { UtilContext } from '../../../contexts/utilidade';
import { AuthContext } from '../../../contexts/auth';

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}){
    const [valuera, setValueRA] = useState('');  
    const [loading, setLoading] =useState(false);
    const [dadosalunos, setDadosAluno]=useState([]);
    const { Aviso, accessdev } = useContext(UtilContext);
    const { user} = useContext(AuthContext);
    const [timeoutloading, setTimeOutLoading]=useState(0);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading)
        setTimeOutLoading(0);
   
        if(loading)
        setTimeout(() => {
         setTimeOutLoading((parseInt(timeoutloading)+1))
       }, 1000);
     }, [loading, timeoutloading]);

    async function buscaempresaaluno(raaluno){
        try {
            if(raaluno==''){
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## PESQUISA ##', // This is a title
                    'RA é obrigatório!!!',
                    {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => { { } }, // Call your cancel function 
                        onConfirm: () => { { } } // Call your confirm function 
                    }
                )
                return false;
            }
            setLoading(true);
            setDadosAluno([]);
            await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/consaluno`, {
                    method: 'POST',
                    body: JSON.stringify({
                        raaluno: raaluno,                        
                    }),
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    }
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                          //Aviso("Contratos", 'Retornando Lista de Contratos', "success", 3000); 
                          //console.log(JSON.stringify(responseJson));
                          setDadosAluno(responseJson.dados);
                      }

                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}

    }

    function getdadosalunos({ item: alunos }) {
        return (    
            <View style={{ /* width: width*0.83,  */ flexDirection: 'column', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
              <View style={{flexDirection:'row',}}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>ITEM:</Text>
                <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.ITEM}</Text>
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>RA-ALUNO:</Text>
                <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.SALUNO_RA} - {alunos.PPESSOA_NOME}</Text>
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SALUNO_CODCFO_CNPJ:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{(alunos.SALUNO_CODCFO_CNPJ)}</Text>
                    <Icon name={(alunos.SALUNO_CODCFO_CNPJ==alunos.SRESPCONT_CODCFO_CNPJ) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_CNPJ==alunos.SRESPCONT_CODCFO_CNPJ) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SRESPCONT_CODCFO_CNPJ:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{(alunos.SRESPCONT_CODCFO_CNPJ)}</Text>
                    <Icon name={(alunos.SALUNO_CODCFO_CNPJ==alunos.SRESPCONT_CODCFO_CNPJ) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_CNPJ==alunos.SRESPCONT_CODCFO_CNPJ) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SALUNO_CODCFO_NOME:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.SALUNO_CODCFO_NOME}</Text>
                    <Icon name={(alunos.SALUNO_CODCFO_NOME==alunos.SRESPCONT_CODCFO_NOME) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_NOME==alunos.SRESPCONT_CODCFO_NOME) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SRESPCONT_CODCFO_NOME:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.SRESPCONT_CODCFO_NOME}</Text>                    
                    <Icon name={(alunos.SALUNO_CODCFO_NOME==alunos.SRESPCONT_CODCFO_NOME) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_NOME==alunos.SRESPCONT_CODCFO_NOME) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SALUNO_CODCFO_NOMEFANTASIA:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.SALUNO_CODCFO_NOMEFANTASIA}</Text>                    
                    <Icon name={(alunos.SALUNO_CODCFO_NOMEFANTASIA==alunos.SRESPCONT_CODCFO_NOMEFANTANSIA) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_NOMEFANTASIA==alunos.SRESPCONT_CODCFO_NOMEFANTANSIA) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>   
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>SRESPCONT_CODCFO_NOMEFANTASIA:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.SRESPCONT_CODCFO_NOMEFANTASIA}</Text>                    
                    <Icon name={(alunos.SALUNO_CODCFO_NOMEFANTASIA==alunos.SRESPCONT_CODCFO_NOMEFANTANSIA) ? "check" : "times"} size={15} color={(alunos.SALUNO_CODCFO_NOMEFANTASIA==alunos.SRESPCONT_CODCFO_NOMEFANTANSIA) ? "#3CB371" : "#FF0000"} />
                </View>
              </View>   
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 250, padding: 1}}>STIPOCURSO:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 500, padding: 1, fontWeight:'bold'}}>{alunos.STIPOCURSO}</Text>                    
                </View>
              </View> 
            </View>          
        )
      }


    return(
        <View style={{flexDirection:'column', padding: 10}}>
            <View style={{padding: 5, flexDirection: 'row', alignItems: 'center'}}>
                    <TextInput
                        placeholder={'Informe o RA'}
                        style={{width: 250,height: 35,borderBottomStartRadius: 5, borderTopStartRadius: 5, borderWidth:3, padding: 5,borderColor:'#ddd', borderRadius:5 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        value={valuera}
                        onChangeText={(item) => {setValueRA(item.replaceAll(".","").replaceAll("/","").replaceAll("-",""))}}
                        onSubmitEditing={(item) => {
                            buscaempresaaluno(valuera);                            
                        }}                
                    />                            
                    <View style={{position:'relative', flexDirection:'row'}}>
                        <View style={{flex: 1, padding: 10}}>
                                <TouchableOpacity
                                    style={{borderTopEndRadius:5, borderBottomEndRadius:5, left: -9, backgroundColor: '#004b9e', height: 30, width: 30, alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => { 
                                        buscaempresaaluno(valuera);                                                                  
                                    }}
                                    >
                                    <View style={{ width: '100%', flexDirection: 'row', }}>
                                        <Icon name="search" size={15} color="#eee" style={{ padding:7,}} />                                                
                                    </View>
                                </TouchableOpacity>
                        </View>
                    </View>
            </View>
            <View style={{padding: 5}}>
                {loading ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={50} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                   
                  </View>  
                ) : (
                  <>                   
                    <FlatList
                      data={dadosalunos}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getdadosalunos(item)}
                    />  
                  </>
                )} 
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        resizeMode: 'stretch',
        width: width,
        height: height,
      },
  });