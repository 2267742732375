import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Alert, Linking, Modal } from 'react-native'
import { CommonActions, useNavigation,useIsFocused  } from '@react-navigation/native';
//import {Card, TextInput} from 'react-native-paper'
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, RadioButton, TextInput} from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import Tooltip from "react-native-walkthrough-tooltip";
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import { PDFReader, MobilePDFReader } from 'react-read-pdf';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');
import API from '../../../server/axios'


export default function App({route, navigation}) {
  const { Aviso, tipovalue, alunosativos, setAlunosAtivo } = useContext(UtilContext);
 
  const { user, signOut } = useContext(AuthContext); 
  const [showTip, setTip] = useState(false);
  const [alunosnotas, setAlunosNotas] = useState();
  const [nomedoaluno, setNomeAluno] = useState();
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [filepdf, setFilePdf]=useState('')

  /* const [dia, setDia]=useState(0);
  const [mes, setMes]=useState(0);
  const [ano, setAno]=useState(0); */
  const [dataini, setDataIni]=useState(0);
  const [datafim, setDataFim]=useState(0);
  const [datainiatual, setDataIniAtual]=useState(0);
  const [datafimatual, setDataFimAtual]=useState(0);
  const [datafilterde, setDataFilterde] = useState('');
  const [datafilterate, setDataFilterate] = useState('');
  const [alunoslstnotas, setLstAlunosNotas] = useState([]);
  const [loadingalunosnotas, setLoadingAlunosNotas] = useState(false);
  const [nometipofiltronotas, setNomeTipoFiltronotas] = useState(null);

  const [modalVisibleFiltro, setModalVisibleFiltro] = useState(false);

  const [pageatual, setPageAtual] = useState(1);
  const [pagetotal, setPageTotal] = useState(2);
  const [proxpage, setProxPage] = useState(true);
  const [antpage, setAntPage] = useState(false);
  

  //Tipo de Curso
  const [searchQueryTipo, setSearchQueryTipo] = useState('');
  const onChangeSearchTipo = query => setSearchQueryTipo(query);
  const dropdownControllertipo = useRef(null);
  const searchRefTipo = useRef(null);
  const refde = useRef(null);
  const refate = useRef(null);

  const onOpenSuggestionsListTipo = useCallback(isOpened => {}, []);

  function capitalize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
       
        var w = words[a];
        if (
            w.toLowerCase() !== 'de' 
            && w.toLowerCase() !== 'da' 
            && w.toLowerCase() !== 'das' 
            && w.toLowerCase() !== 'do' 
            && w.toLowerCase() !== 'dos' 
            && w.toLowerCase() !== 'em' 
            && w.toLowerCase() !== 'e'  
          ){
          //if(w[0] !== undefined || w[0] !== null)
          words[a] = w[0].toUpperCase() + w.slice(1);
        }else{
          words[a] = w[0] + w.slice(1);
        }
    }
    return words.join(" ");
  }

  const DownloadFile = (data, name) => {
    //const linkSource = `data:application/pdf;base64,${data}`;
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;    
    downloadLink.click();
  };

  
  function validarfiltrode(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    //v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2")       
    v=v.replace(/^(\d{2})(\d)/,"$1/$2")      //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterde(v);
    setDataIni(v);

    
  }
  function validarfiltroate(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    //v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})(\d)/,"$1/$2")      //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterate(v);
    setDataFim(v);
  }

  function validarData (s) {
    var l = s.length
    var j = 0
    var dt = { 0: '', 1:'', 2:'' }
    
    // dias de cada mês
    var n = [ 0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
    
    // divide a data para o objeto "dt"
    for (var i=0; i<l; i++) {
      var c = s[i]
      if (c!=='/')
        dt[j] += c
      else
        j++
    }
    
    // converte strings em número
    var d = +dt[0]
    var m = +dt[1]
    var y = +dt[2]
    
    // atualiza dias do ano bisexto
    n[2] += +(y % 400 === 0 || y % 4 === 0 && y % 100 !== 0)
    
    // regras de validação
    // mês deve ser entre 1-12 e dia deve ser maior que zero
    if (m<1 || m>12 || d<1) {
      return false
    }
    // valida número de dias do mês
    else if (d > n[m]) {
      return false
    }
    
    // passou nas validações
    return true
  }

  const fetchData = async () => {
    try {

        if(user.codcgo == 0){
          alert(`Selecione o CNPJ da empresa.`);
          return false;
        }

        //alert(`${dotenv.SERVER_PHP}/relatoriopdf/relatorionotas.php.php?codcfo=${user.codcgo}&dtini=${dataini}&dtfim=${datafim}`);
        //return false;

        if(user.codcgo !== 0){
          setLoadingAlunosNotas(true);
          setFilePdf('');
          var url = `${dotenv.SERVER_PHP}/relatoriopdf/relatorionotas.php?codcfo=${user.codcgo}&dtini=${dataini}&dtfim=${datafim}`;
        await API.post(url)                        
          .then(function (responseJson) {
              setLoadingAlunosNotas(false); 
              if (!responseJson){
                alert('Ocorreu um Erro'); 
                return
              }
              /* if (responseJson.validtoken==false){
                alert(responseJson.message); 
                signOut();
                return
              } */
                //if(responseJson.data.length > 0){
                    //console.log(responseJson.data.file);
                    setFilePdf("data:application/pdf;base64,"  +  responseJson.data.file);
                //} 
                setLoadingAlunosNotas(false); 
          })
          .catch(function (error) {

            console.log(error);
            setLoadingAlunosNotas(false);   
            if(error.code ==='ERR_NETWORK'){
              alert('Erro Comunicação com Servidor dos Relatórios');
              return
            }else{
              Aviso("Error1:", error.message, "danger", 3000);  
            }

                
          })
          .finally(function () {  
            setLoadingAlunosNotas(false);
          });
        }
      } catch (err) {
          setLoadingAlunosNotas(false); 
          console.log(err);
          Aviso("Error2:", err.message, "danger", 3000);
      } finally {
            setLoadingAlunosNotas(false); 
      }
    
  }

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        //colocar data no filtro
        let date = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        let dataagora = `${("00"+month).slice(-2)}/${year}`;
        //setDataFilterde(dataagora);
        //setDataIni(dataagora);
        //setDataFilterate(moment().endOf('month').format('MM/YYYY'));
        //setDataFim(moment().endOf('month').format('MM/YYYY'));

      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
     if(!loadingalunosnotas)
     setTimeOutLoading(0);

     if(loadingalunosnotas)
     setTimeout(() => {
      setTimeOutLoading((parseInt(timeoutloading)+1))
    }, 1000);
  }, [loadingalunosnotas, timeoutloading]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if (pageatual == 1){
        setAntPage(false);
        setProxPage(true);
    }else{
      setAntPage(true);
    }
  
    if (parseInt(pageatual) < parseInt(pagetotal)){
      setProxPage(true);
    }else{
      setProxPage(false); 
    }
  
  }, [pageatual]);
  
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if (datafilterde.length == 7){
        refate.current.focus();
        //alert('De Tamanho 7');
    }
  }, [datafilterde]);

  //VIEW DO FORM MODAL - TIPO
  const ItemViewTipo = ({ item }) => {
    return (
        <Text style={styles.itemStyle} onPress={() => getItemTipo(item)}>
            {item.name}
        </Text>
    );
  }

  //GET DO FORM MODAL - TIPO
  const getItemTipo = (item) => {
     setNomeTipoFiltronotas(item.filtro.trim());
  };

  const Mountpdf = () => {
    return (
       <>
        {Device.osName=='Windows' ? (
           <PDFReader
             onDocumentComplete={(numPages) => {
             //console.log(numPages);
             setPageTotal(numPages);
           }}
           showAllPage={false} page={pageatual} url={filepdf} /> 
        ) : (          

          <MobilePDFReader
            onDocumentComplete={(numPages) => {
              //console.log(numPages);
              //setPageTotal(numPages);
            }}
            showAllPage={false} /* page={pageatual} */ url={filepdf} /> 
        )}
       </>
    )
  }


  return (
    <ImageBackground>      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={{flexDirection: 'column', left: Device.osName=='Windows' ? null : -70,}}>
            <Card style={[styles.card,{marginTop: 10, height: 80, zIndex: 5}]} elevation={5} mode={'elevated'}>
            <View style={{flexDirection: 'row'}}>                   
              <Text style={{
                color: '#4F4F4F',
                fontSize: Device.osName=='Windows'? 20 : 12,
                fontFamily: 'Time New Roman',
                fontWeight: 'bold',
              }}>:: NOTAS DOS ALUNOS ::</Text>
              <View style={{ margin: 10, marginLeft: Device.osName=='Windows' ? 350 : 0, top: Device.osName=='Windows' ? -15: 40, position: 'absolute' }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: '#004b9e', height: Device.osName=='Windows' ? 35 : 18, width: Device.osName=='Windows'? 120: 60, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    //setIsContrato(false);
                    navigation.navigate('Alunos');
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="reply-all" size={Device.osName=='Windows' ? 20: 10} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFD700', fontWeight: 'bold' }} >
                      VOLTAR
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              

            </View>
              <View style={{ flex: 1, width: '100%' }}>
                <Text style={{position: 'relative',top: Device.osName=='Windows' ? 15 :5, fontSize:Device.osName=='Windows'? 13 : 10 , fontWeight: 'bold', alignSelf: 'flex-start' }}>{`EMPRESA: ${user?.nomefantasia ? user?.nomefantasia : user?.nome }`}</Text>
                
              </View> 
              <View style={{ alignSelf:  'flex-end', top: Device.osName=='Windows' ?  -20 : 20, right: Device.osName=='Windows' ? 70 : null, }}>
                  
                        {Device.osName=='Windows' ? (                           
                        <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: 350,  borderTopEndRadius: 5,  }}>
                          <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'flex-start', marginBottom: 5, left: Device.osName=='Windows' ? null : 100}}>
                                <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, color: '#004b9e'}}> De:</Text>
                                <TextInput
                                  placeholder={`00/0000`}
                                  //style={{ height: 30, width: 130, marginLeft: 10, marginRight: 80 }}
                                  style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 70, fontSize: Device.osName=='Windows' ? 15 : 9,/*  marginLeft: 10, */ marginRight: 80  }}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  value={datafilterde}
                                  ref={refde}
                                  maxLength={7}
                                  onChangeText={(item) => validarfiltrode(item)}
                                />
                          </View>
                          <View style={{flexDirection: 'row', /* alignItems:'center', */ justifyContent: 'flex-start', marginBottom: Device.osName=='Windows' ? 5 : '', left: Device.osName=='Windows' ? -70 : 25}}>
                                <Text style={{position: 'relative',fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, top: Device.osName=='Windows' ? 5 : -3 ,color: '#004b9e'}}>Até:</Text>
                                <TextInput
                                  placeholder={`00/0000`}
                                  //style={{ height: 30, width: 130, marginLeft: 10, marginRight: 80 }}
                                  style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 70, fontSize: Device.osName=='Windows' ? 15 : 9,top:Device.osName=='Windows' ? null : -3,/*  marginLeft: 10, */ marginRight: 80  }}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  value={datafilterate}
                                  ref={refate}
                                  maxLength={7}
                                  onChangeText={(item) => validarfiltroate(item)}
                                  onSubmitEditing={()=> {
                                        if (!datafilterde || !datafilterate){
                                          alert(`Informe a data do Filtro.\n De: e Ate:`);
                                          return
                                        }

                                        var patternData = /^[0-9]{2}\/[0-9]{4}$/;
                                        if(!patternData.test(datafilterde)){
                                            alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                            return false;
                                        }
                                        if(!patternData.test(datafilterate)){
                                            alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                            return false;
                                        }

                                        fetchData();
                                  }}
                                />
                          </View>
                          <View style={{padding: 5,  borderRadius:5, width: Device.osName=='Windows' ? 120 : 55, height: Device.osName=='Windows' ? 30 : 20, backgroundColor: '#004b9e', left: Device.osName=='Windows' ? -130 : -35}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => { 

                                                                                
                                        if (!datafilterde || !datafilterate){
                                          alert(`Informe a data do Filtro.\n De: e Ate:`);
                                          return
                                        }

                                        var patternData = /^[0-9]{2}\/[0-9]{4}$/;
                                        if(!patternData.test(datafilterde)){
                                             alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                             return false;
                                        }
                                        if(!patternData.test(datafilterate)){
                                             alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                             return false;
                                        }

                                        fetchData();
                                    }}
                                  >
                                    <Icon name="search" size={Device.osName=='Windows' ? 20: 10} color="#FFD700" style={{marginRight: Device.osName=='Windows' ? 10 : 2}}/>
                                    <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 9}}>BUSCAR</Text>
                                  </TouchableOpacity>
                          </View>                      
                        </View>
                        
                    ) : (
                      <View style={{padding: 5,  borderRadius:5, width: Device.osName=='Windows' ? 120 : 60, height: Device.osName=='Windows' ? 30 : 20, backgroundColor: '#004b9e', left: Device.osName=='Windows' ? -130 : -15}}>
                              <TouchableOpacity
                                style={{flexDirection: 'row',}}
                                onPress={() => {                                                                   
                                    setModalVisibleFiltro(!modalVisibleFiltro);
                                }}
                              >
                                <Icon name="search" size={Device.osName=='Windows' ? 20: 10} color="#FFD700" style={{marginRight: Device.osName=='Windows' ? 10 : 10}}/>
                                <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 9}}>FILTRO</Text>
                              </TouchableOpacity>
                      </View>
                    )}
                    <View style={{ position: 'relative',flexDirection: 'row', width: '100%', left: -140,}}>                      
                          <View style={{flexDirection: 'column', top: 2, left: 40}}>
                                                           
                          </View>
                  </View>  
                </View>
            </Card>
            <Card style={[styles.card,{marginTop: 10, height: 500}]} elevation={5} mode={'elevated'}>
               <View style={{flexDirection: 'row', justifyContent:'space-between', }}>
                    {filepdf !== '' ? (
                    <TouchableOpacity
                        onPress={() => {
                          //alert('Teste Dowaload');
                          DownloadFile(filepdf, "Relatório_Notas_Aluno_"+user.cgccfo.replaceAll(".","").replaceAll("-","").replaceAll("/",""));
                        }}
                        style={{ /*flex: 1, */  position: 'relative',width: Device.osName=='Windows' ? 100 : 70,left: Device.osName=='Windows' ? 25 : 15, flexDirection:'row', backgroundColor: '#eee' }}>
                      <View style={{width: Device.osName=='Windows' ? 130 : 70, height: Device.osName=='Windows' ? 30 : 15,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: '#004b9e'}}>
                        <Icon name="file-download" size={Device.osName=='Windows' ? 20 : 10} color='#FFD700' style={{ padding: Device.osName=='Windows' ? 10 : 2 }} />
                        {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                        <Text style={{fontWeight:'bold', color:'#FFF', fontSize: Device.osName=='Windows' ? 12 : 10}}>Download</Text>
                      </View>
                    </TouchableOpacity>
                    ):null}
                    {filepdf != '' && Device.osName=='Windows' ? (
                      <View style={{flexDirection: 'row',}}>
                          <TouchableOpacity
                              onPress={() => { 
                                setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);                           
                              }}
                              style={{ /*flex: 1, */  position: 'relative',width: 100, right: 160, flexDirection:'row', backgroundColor: '#eee' }}>
                            <View style={{width: 100, height: 30,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: antpage ? '#004b9e' : '#C0C0C0'}}>
                              <Icon name="angle-double-left" size={20} color='#FFD700' style={{ padding: 10 }} />
                              <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Página'}</Text>
                            </View>
                          </TouchableOpacity>
                          <View>
                            <Text style={{position: 'relative', right: 110, fontSize: 18, color: '#004b9e', fontWeight: 'bold'}}>{parseInt(pageatual)}</Text>
                          </View>
                          <TouchableOpacity
                              onPress={() => { 
                                  setPageAtual((parseInt(pageatual) < parseInt(pagetotal)) ? (parseInt(pageatual) + 1) : pagetotal );                            
                              }}

                              style={{ /*flex: 1, */  position: 'relative',width: 100, right: 60, flexDirection:'row', backgroundColor: '#eee' }}>
                            <View style={{width: 100, height: 30,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: proxpage ? '#004b9e' : '#C0C0C0' }}>
                              <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Página'}</Text>
                              <Icon name="angle-double-right" size={20} color='#FFD700' style={{ padding: 10 }} />
                            </View>
                          </TouchableOpacity>
                      </View>
                    ) : null}
              </View>
              <ScrollView style={{height: height, marginTop: 10}}>   
              {/* <View style={{ flex: 1, width: '100%' }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>MATRICULADOS:</Text>
              </View> */}
                                                                                                     
              <View style={{width: '100%', alignItems: 'flex-start', marginTop: 1 }}>
                {(loadingalunosnotas) ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={Device.osName=='Windows' ? 50 : 20} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: Device.osName=='Windows' ? 15 : 11}}>{`Buscando as Informações...\nPesquisa pode demorar em torno de 20s.\nTempo: ${timeoutloading}s`}</Text>
                    {/* <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Tempo: ${timeoutloading}`}</Text> */}
                  </View>  
                ) : (
                  <>
                  
                    {/* <View style={{ width: width * 0.8, paddingTop: 0, flexDirection: 'row',}}>
                      <View style={{ width: 50, left: 5 }}><Text style={{fontWeight: 'bold'}}>#</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>RA</Text></View>
                      <View style={{ width: 300 }}><Text style={{fontWeight: 'bold'}}>NOME</Text></View>
                      <View style={{ width: 50 }}><Text style={{fontWeight: 'bold'}}>ATIVO</Text></View>
                      <View style={{ width: 120 }}><Text style={{fontWeight: 'bold'}}>TIPO</Text></View>
                      <View style={{ width: 170 }}><Text style={{fontWeight: 'bold'}}>CURSO</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>ÍNICIO</Text></View>
                      <View style={{ width: 90 }}><Text style={{fontWeight: 'bold'}}>FIM</Text></View>
                      <View style={{ width: 90 }}><Text style={{fontWeight: 'bold'}}>RESTANTE</Text></View>
                      <View style={{ width: '100%' }}><Text style={{fontWeight: 'bold'}}>FALTAS</Text></View>
                    </View>   */}  
                                    
                    {/* <FlatList
                      data={alunoslstnotas}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getalunos(item)}
                    />   */}

                                <View style={{width: '100%', height: Device.osName=='Windows' ? '' : 600}}>
                                    {/* <Text style={{flex:1, }}>{guid}</Text> */}    
                                    {/* https://github.com/forthealllight/react-read-pdf */}
                                    {/* <View style={{overflow:'scroll', height:600}}> */}
                                        { filepdf !== '' ? 
                                          (
                                            <Mountpdf />
                                             
                                          ):(
                                            <View style={{width: Device.osName=='Windows' ?  width* 0.9 : width*0.6, height: height * 0.5 ,alignItems: 'center', justifyContent: 'center', }}>
                                              <Text style={{textAlign: 'center', fontSize: Device.osName=='Windows' ? 18 : 12, fontWeight: 'bold', color: '#004b9e'}}>
                                                    Ajuste o filtro conforme a necessidade e acione o botão "Buscar".
                                              </Text>
                                            </View>
                                          )}
                                    {/* </View>  */}      
                                    
                                </View>
                  </>
                )}                                
              </View>  
              </ScrollView>               
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -5, right: Device.osName=='Windows'? '': 10}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
            </View>
        </View>        
      </View>
      <Modal animationType='slide' transparent={true} visible={modalVisibleFiltro}>
                    <ScrollView>
                    <View style={styles.viewmodal}>
                      <Card style={[styles.card,{height: 200, width: width * 0.9}]} elevation={5} mode={'elevated'}>                       
                            <Text style={{fontSize: 15, fontWeight: 'bold'}}>Selecione o Período:</Text>                             
                            <View style={{flexDirection: 'row', margin: 10, zIndex: -10, top: -10}}>  
                                <View style={{position: 'relative',flexDirection:'column', width: '80%',  borderTopEndRadius: 5,}}>                           
                                      <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'flex-start', marginBottom: 5, left: -10, padding: 10 }}>
                                            <Text style={{position: 'relative',fontWeight: 'bold', fontSize: 16,top: -10, color: '#004b9e', paddingRight: 10}}> De:</Text>
                                            <TextInput
                                              placeholder={`00/0000`}
                                              //style={{ height: 30, width: 130, marginLeft: 10, marginRight: 80 }}
                                              //style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 70, fontSize: Device.osName=='Windows' ? 15 : 9,/*  marginLeft: 10, */ marginRight: 80  }}
                                              style={{ height: 20, width: '90%', fontSize: 20, left: 5,/*  marginLeft: 10,  marginRight: 80*/ }}
                                              editable={true}
                                              autoCapitalize="none"
                                              autoCorrect={false}
                                              keyboardType='numeric'
                                              value={datafilterde}
                                              ref={refde}
                                              maxLength={7}
                                              onChangeText={(item) => validarfiltrode(item)}
                                            />
                                      </View>
                                      <View style={{flexDirection: 'row', /* alignItems:'center', */ justifyContent: 'flex-start', marginBottom: Device.osName=='Windows' ? 5 : '', left: -10, padding: 10}}>
                                            <Text style={{position: 'relative',fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 16,/* top: -10, */ color: '#004b9e', paddingRight: 10}}>Até:</Text>
                                            <TextInput
                                              placeholder={`00/0000`}
                                              //style={{ height: 30, width: 130, marginLeft: 10, marginRight: 80 }}
                                              //style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 70, fontSize: Device.osName=='Windows' ? 15 : 9,top:Device.osName=='Windows' ? null : -3,/*  marginLeft: 10, */ marginRight: 80  }}
                                              style={{ height: 20, width: '90%', fontSize: 20,/*  marginLeft: 10,  marginRight: 80*/ }}
                                              editable={true}
                                              autoCapitalize="none"
                                              autoCorrect={false}
                                              keyboardType='numeric'
                                              value={datafilterate}
                                              ref={refate}
                                              maxLength={7}
                                              onChangeText={(item) => validarfiltroate(item)}
                                              onSubmitEditing={()=> {
                                                    if (!datafilterde || !datafilterate){
                                                      alert(`Informe a data do Filtro.\n De: e Ate:`);
                                                      return
                                                    }

                                                    var patternData = /^[0-9]{2}\/[0-9]{4}$/;
                                                    if(!patternData.test(datafilterde)){
                                                        alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                                        return false;
                                                    }
                                                    if(!patternData.test(datafilterate)){
                                                        alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                                        return false;
                                                    }

                                                    fetchData();
                                              }}
                                            />
                                      </View>
                                     
                                </View>  
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 10, bottom: 5, zIndex: -10}}> 
                                <View style={{ position: 'relative', left: -10, padding: 5,  borderRadius:5, width: 120, height:  30, backgroundColor: '#004b9e'}}> 
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 
                                            setModalVisibleFiltro(false);
                                        }}
                                      >
                                        <Icon name="window-close" size={20} color="#FFD700" style={{marginRight: 10, paddingLeft: 10}}/>
                                        <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: 15}}>FECHAR</Text>
                                      </TouchableOpacity>                            
                                </View>
                                <View style={{padding: 5,  borderRadius:5, width: 120, height: 30, backgroundColor: '#004b9e', /* left: -35 */}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => {                                                                                
                                        if (!datafilterde || !datafilterate){
                                          alert(`Informe a data do Filtro.\n De: e Ate:`);
                                          return
                                        }

                                        var patternData = /^[0-9]{2}\/[0-9]{4}$/;
                                        if(!patternData.test(datafilterde)){
                                             alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                             return false;
                                        }
                                        if(!patternData.test(datafilterate)){
                                             alert("Digite a data no formato correto! \n ex.: XX/ZZZZ");
                                             return false;
                                        }

                                        fetchData();
                                        setModalVisibleFiltro(false);
                                    }}
                                  >
                                    <Icon name="search" size={20} color="#FFD700" style={{marginRight: 10}}/>
                                    <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: 15}}>BUSCAR</Text>
                                  </TouchableOpacity>
                              </View> 
                                

                            </View>
                      </Card>                                              
                    </View>
                    </ScrollView>
        </Modal>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: Device.osName=='Windows'? width*0.86 : width*0.7,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //left: Device.osName=='Windows'? 0 : -70
    //alignItems: 'center',
    //justifyContent: 'center',        
  },
  viewmodal: {
    backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.99,
  },

});
