import React, {useContext} from 'react'
import {View, TouchableOpacity, Text, Image, Dimensions} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome5';
const { width, height } = Dimensions.get('window');
import { UtilContext } from '../../../contexts/utilidade';

export default function ajuda(){
    const { modalVisible, setModalVisible } = useContext(UtilContext);
    return(
        <>
        <View style={{flex:1, position: 'absolute', top: 30, left: 10, }}>
            <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                <View style={{flexDirection: 'row'/*, top: 5 , width: width* 0.9 */,alignSelf: 'center',} }>
                    <Icon name="window-close" size={30} color='#eee' />
                    <Text style={{ marginLeft: 20,fontSize: 18, fontWeight: 'bold', color: '#eee', top: 3, position: 'relative' }}>FECHAR</Text>
                </View>                                
            </TouchableOpacity>                            
        </View> 
        <View style={{width: width * 0.99,flexDirection:'row', top: 0, position: 'relative', flexWrap: 'wrap', backgroundColor: ''}}> 
            <View style={{flexDirection: 'column',alignSelf: 'flex-start', width: 520, height: 140, borderColor: '#FFF', borderWidth: 1, borderRadius: 5,margin: 10}}>
                <Text style={{position: 'absolute', color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>CONTROLE DE ACESSO PELOS MENUS</Text>
                <Image
                    style={{resizeMode: 'stretch', width: 400, height: 30,position: 'absolute',marginLeft: 10, top: 25, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/inicio.jpg')}
                    contentFit="fill"
                />
            </View>                       
            <View style={{flexDirection: 'column',left: 0, width: 520, height: 140, borderColor: '#FFF', borderWidth: 1, borderRadius: 5, margin:10}}>
                <View style={{flexDirection: 'column', margin: 10, top: -8, left: -10}}>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>RESUMO DE ALUNOS / BOLETOS</Text>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 11,}}>Matriculado: Quantitativo de Alunos Ativos nos curso.</Text>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 11,}}>Boleto(s): Quanttativo de status aberto.</Text>
                </View>
                <Image
                    style={{resizeMode: 'stretch', width: 300, height: 80,position: 'absolute',left: 10, top: 55, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/dashboard.jpg')}
                    contentFit="cover"
                />
            </View>                       
            <View style={{flexDirection: 'column',left: 0, width: 520, height: 110, borderColor: '#FFF', borderWidth: 1, borderRadius: 5, margin:10}}>
                <Text style={{position: 'absolute', color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>NAVEGAÇÃO NA LISTA PRIMEIRO/ANTERIOR/PRÓXIMO/ÚLTIMO</Text>
                <Image
                    style={{resizeMode: 'stretch', width: 200, height: 30,position: 'absolute',left: 10, top: 25, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/navegacao.jpg')}
                    contentFit="cover"
                />
            </View>                       
            <View style={{flexDirection: 'column',left: 0, width: 520, height: 110, borderColor: '#FFF', borderWidth: 1, borderRadius: 5, margin:10, padding: 5}}>
                <View style={{flexDirection: 'column', margin: 10, top: -10, left: -13}}>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>SELEÇÃO DE ALUNOS</Text>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 11,}}>Ativo(s): Alunos Ativos na Instituição.</Text>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 11,}}>Finalizado(s): Alunos que Finalizaram o  Curso.</Text>
                    <Text style={{color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 11,}}>Todos: Ativos / Finalizados.</Text>
                </View>
                <Image
                    style={{resizeMode: 'stretch', width: 300, height: 30,position: 'absolute',left: 10, top: 70, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/selalunos.jpg')}
                    contentFit="cover"
                />
            </View>                       
            <View style={{flexDirection: 'column',left: 0, width: 520, height: 100, borderColor: '#FFF', borderWidth: 1, borderRadius: 5, margin:10}}>
                <Text style={{position: 'absolute', color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>FILTRO PARA SELECIONAR MÊS/ANO DO FINANCEIRO</Text>
                <Image
                    style={{resizeMode: 'stretch', width: 200, height: 30,position: 'absolute',left: 10, top: 25, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/filtro.jpg')}
                    contentFit="cover"
                />
            </View>                       
            <View style={{flexDirection: 'column',left: 0, width: 520, height: 100, borderColor: '#FFF', borderWidth: 1, borderRadius: 5, margin:10}}>
                <Text style={{position: 'absolute', color: '#eee', fontWeight: 'bold', marginLeft: 10, fontSize: 12}}>CLIQUE NO NÚMERO DA FALTA PARA VISUALIZAR OS DETALHES</Text>
                <Image
                    style={{resizeMode: 'stretch', width: 70, height: 55,position: 'absolute',left: 10, top: 25, borderRadius: 5, overflow:'hidden'}}
                    source={require('../../img/faltas.jpg')}
                    contentFit="cover"
                />
            </View>                 
        </View>
        </>

    )
}