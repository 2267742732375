import React, {useState, useContext, useEffect} from 'react';
import {Dimensions, View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, ActivityIndicator} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { UtilContext } from '../../../contexts/utilidade';
import { AuthContext } from '../../../contexts/auth';

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}){
    const [valuera, setValueRa] = useState('');  
    const [loading, setLoading] =useState(false);
    const [contradoaluno, setContratoAluno]=useState([]);
    const { Aviso, accessdev } = useContext(UtilContext);
    const { user} = useContext(AuthContext);
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [nomealuno, setNomeAluno]=useState('');

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading)
        setTimeOutLoading(0);
   
        if(loading)
        setTimeout(() => {
         setTimeOutLoading((parseInt(timeoutloading)+1))
       }, 1000);
     }, [loading, timeoutloading]);

    async function buscacontratoaluno(valuera){
        try {
            if(valuera==''){
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## PESQUISA ##', // This is a title
                    'RA é obrigatório!!!',
                    {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => { { } }, // Call your cancel function 
                        onConfirm: () => { { } } // Call your confirm function 
                    }
                )
                return false;
            }
            setLoading(true);
            setNomeAluno('');
            setContratoAluno([]);
            await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/alunoscontratosadminti2`, {
                    method: 'POST',
                    body: JSON.stringify({
                        raaluno: valuera
                    }),
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    }
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                          //Aviso("Contratos", 'Retornando Lista de Contratos', "success", 3000); 
                          //console.log(JSON.stringify(responseJson));
                          setContratoAluno(responseJson.dados);
                          setNomeAluno(responseJson.dados[0].NOME);
                      }

                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}

    }

    function getcontratoalunos({ item: alunos }) {
        //console.log(LstTicket);
        /* let date = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        let dataagora = `${month}/${date}/${year}`
        const date2 = new Date(dataagora);
        const date1 = new Date(alunos.DTPREVISTA);
        let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
        let dias = moment.duration(diff).asDays(); */  
       //alert(JSON.stringify(alunos))       
          let valuedtcontrato = alunos.DTCONTRATO!=null?  alunos.DTCONTRATO.split('T'): null;
          let valuedtcontrato2 = valuedtcontrato!=null ? valuedtcontrato[0].split('-') : null;
          let valuedtcontrato3 = valuedtcontrato2!=null ? valuedtcontrato2[2]+'/'+valuedtcontrato2[1]+'/'+valuedtcontrato2[0] + ' ' +valuedtcontrato[1].slice(0,8) : null;
          //let valuedtcontrato3 = alunos.DTCONTRATO;//valuedtcontrato2[2]+'/'+valuedtcontrato2[1]+'/'+valuedtcontrato2[0] + ' ' +valuedtcontrato[1].slice(0,8);
        
        return (    
            <View style={{ /* width: width*0.83,  */ flexDirection: 'column', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
              <View style={{flexDirection:'row',}}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>CONTRATO:</Text>
                <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{alunos.ITEM}</Text>
              </View>
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>RA:</Text>
                <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{alunos.RA}</Text>
              </View>
              {/* <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 5}}>NOME ALUNO:</Text>
                <Text style={{fontSize: 13, width: 200, padding: 5, fontWeight:'bold'}}>{alunos.NOME}</Text>
              </View> */}
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>CODTIPOCURSO (Diferente de 5):</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{alunos.CODTIPOCURSO}</Text>
                    <Icon name={alunos.CODTIPOCURSO!=5 ? "check" : "times"} size={15} color={alunos.CODTIPOCURSO!=5 ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>DTCONTRATO (Deve ter Data):</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{(alunos.DTCONTRATO!=null) ?  valuedtcontrato3 : 'NÃO INFORMADO'}</Text>
                    <Icon name={alunos.DTCONTRATO!=null ? "check" : "times"} size={15} color={alunos.DTCONTRATO!=null ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>DTCANCELAMENTO (Sem Data):</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{(alunos.DTCANCELAMENTO !=null) ? moment(alunos.DTCANCELAMENTO).utcOffset(-4).format("DD/MM/YYYY HH:mm:ss") : 'NÃO INFORMADO'}</Text>
                    <Icon name={alunos.DTCANCELAMENTO ==null ? "check" : "times"} size={15} color={alunos.DTCANCELAMENTO ==null ? "#3CB371" : "#FF0000"} />
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>ID CONTRATO (Conter Número):</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold', color: (alunos.TBCCONTRATO==null || alunos.CODPLANOPGTO==null) ? '#FF0000' : '' }}>{(alunos.TBCCONTRATO!=null && (alunos.TBCCONTRATO!=null && alunos.CODPLANOPGTO!=null)) ? alunos.TBCCONTRATO : (alunos.TBCCONTRATO==null || alunos.CODPLANOPGTO==null) ? 'ERRO' : ''}</Text>

                    <Icon name={(alunos.TBCCONTRATO!=null && alunos.CODPLANOPGTO!=null)  ? "check" : "times"} size={15} color={alunos.TBCCONTRATO!=null && alunos.CODPLANOPGTO!=null ? "#3CB371" : "#FF0000"} />

                    {alunos.CODMODALIDADECURSO==null || alunos.CODMODALIDADECURSO=='' ? (
                    <Text style={{fontSize: 11, width: 300, padding: 1, marginLeft: 100, fontWeight:'bold', color:'#FF0000'}}>MODALIDADE CURSO: {alunos.CODMODALIDADECURSO?? 'PENDENTE'}</Text>
                    ):null}
                    {alunos.CODPLANOPGTO==null || alunos.CODPLANOPGTO=='' ? (
                    <Text style={{fontSize: 11, width: 300, padding: 1, marginLeft: 10, fontWeight:'bold', color:'#FF0000'}}>PLANO PGTO: {alunos.CODPLANOPGTO?? 'PENDENTE'}</Text>
                    ) : null}
                </View>
              </View>                          
              <View style={{ flexDirection:'row', }}>
                <Text style={{fontSize: 13, width: 200, padding: 1}}>CURSO NOME:</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: 13, width: 200, padding: 1, fontWeight:'bold'}}>{alunos.CURSONOME?? 'NÃO TEM'}</Text>
                </View>
              </View>                          
            </View>          
        )
      }


    return(
        <View style={{flexDirection:'column', padding: 10}}>
            <View style={{padding: 5, flexDirection: 'row', alignItems: 'center'}}>
                    <TextInput
                        placeholder={'Informe o RA'}
                        style={{width: 250,height: 35,borderBottomStartRadius: 5, borderTopStartRadius: 5, borderWidth:3, padding: 5,borderColor:'#ddd', borderRadius:5 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        value={valuera}
                        onChangeText={(item) => {setValueRa(item.replaceAll(".","").replaceAll("/","").replaceAll("-",""))}}
                        onSubmitEditing={(item) => {
                            buscacontratoaluno(valuera);                            
                        }}                
                    />                            
                    <View style={{position:'relative', flexDirection:'row'}}>
                        <View style={{flex: 1, padding: 10}}>
                                <TouchableOpacity
                                    style={{borderTopEndRadius:5, borderBottomEndRadius:5, left: -9, backgroundColor: '#004b9e', height: 30, width: 30, alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => { 
                                        buscacontratoaluno(valuera);                                                                  
                                    }}
                                    >
                                    <View style={{ width: '100%', flexDirection: 'row', }}>
                                        <Icon name="search" size={15} color="#eee" style={{ padding:7,}} />                                                
                                    </View>
                                </TouchableOpacity>
                        </View>
                    </View>
            </View>
            {nomealuno!='' ? (
            <View style={{padding: 5, flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 15, fontWeight:'bold'}}>{`Aluno: ${nomealuno!='' ? nomealuno : ''}`}</Text>
                    <Text style={{fontSize: 13, }}> Se aparecer 
                        <Icon name="times" size={13} color="#FF0000" style={{paddingLeft: 5, paddingRight: 5}}/>
                        não será listado este contrato para o aluno
                    </Text>
            </View>
            ):null}
            <View style={{padding: 5}}>
                {loading ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={50} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                   
                  </View>  
                ) : (
                  <>                   
                    <FlatList
                      data={contradoaluno}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getcontratoalunos(item)}
                    />  
                  </>
                )} 
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        resizeMode: 'stretch',
        width: width,
        height: height,
      },
  });