import React, { createContext, useContext, useState } from 'react';
import { Dimensions } from 'react-native'
import { AuthContext } from './auth';
//import api from '../service/axios';
import { showMessage } from "react-native-flash-message";
import Icon from 'react-native-vector-icons/FontAwesome5';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Base64 from 'Base64';
import {  useNavigation  } from '@react-navigation/native';
import { Linking } from 'react-native';
import * as Device from 'expo-device';//Device.osName

const dotenv = require('../app.config');
const moment = require('moment');
const { width, height } = Dimensions.get('window');

export const UtilContext = createContext({});

function UtilProvider({ children }) {
  const navigation = useNavigation();
  const { user, userra, signUp,signUpRA, signOut } = useContext(AuthContext);
  const [stsmenu, setStsMenu] = useState('INICIO');
  const [newpwd, setNewPwd] = useState(false);
  const [loadinglogin, setLoadingLogin] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibletermo, setModalVisibleTermo] = useState(false);
  const [isaluno, setIsAluno] = useState(false);
  const [dadoslogin, setDadosLogin] = useState([]);
  const dadoslogins = [];
  const dadoscontrato = [];
  const [userlogin, setUserLogin] = useState('');//23008166
  const [userpwd, setUserPwd] = useState('');//123456
  const [userpwdnew, setUserPwdNew] = useState('');
  const [nomealuno, setNomeAluno] = useState('');
  const [iscontrato, setIsContrato] = useState(false);
  const [visiblesalvar, setVisibleSalvar] = useState(false);
  const [raalunoreturn, setRaAlunoReturn] = useState(false);
  const [accessdev, setAccessDev] = useState(false);

  const [islogin, setIsLogin] = useState(true);

  
  //INICIO DADOS ALUNOS##########################################
  const [loadingalunos, setLoadingAlunos] = useState(false);
  const [alunos, setAlunos] = useState([]);
  const [tipovalue, setTipoValue] = useState([]);
  const [lstalunos, setLstAlunos] = useState([]);
  const [totalpag, setTotalPag] = useState(0);
  const [pagnumber, setPagNumber] = useState(1);
  const [alunosativos, setAlunosAtivo] = useState('S');
  const [nometipofiltro, setNomeTipoFiltro] = useState(null);
  const RowspPage =  Device.osName=='Windows' ? 20 : 5000;
  //FINAL DADOS ALUNOS##########################################

  function AtivaMenu (Mnu){
    setStsMenu(Mnu);
  }

  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: 'R$'
  }
  const defaultOptionsSRS = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ''
  }

  function Aviso(titulo, mensagem, tipo, tempo) {
    let tempodelay = tempo?? 8000;
    showMessage({
      //message: `<- Clique para Fechar`,
      message: `${titulo}`,
      description: `\n${mensagem}`,
      type: `${tipo}`,
      duration: tempodelay,
      //autoHide: false,
      titleStyle: { fontSize: 20, },
      textStyle: { fontSize: 18, fontWeight: 'bold' },
      //icon: props => <Image style={{resizeMode: 'stretch', width: 200, height: 200}} source={require('../../assets/icon6.png')} {...props} />,
      icon: props => <Icon name="window-close" size={20} color="#FFF" {...props} />,
      onPress: () => {
        /* THIS FUNC/CB WILL BE CALLED AFTER MESSAGE PRESS */
        //alert('OK');
      },
    });

  }

  function currencyFormatter(value, options) {
    if (typeof value !== 'number') value = 0.0
    options = { ...defaultOptions, ...options }
    value = value.toFixed(options.significantDigits)

    const [currency, decimal] = value.split('.')
    return `${options.symbol} ${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`
  }
  function currencyFormatterSRS(value, options) {
    if (typeof value !== 'number') value = 0.0
    options = { ...defaultOptionsSRS, ...options }
    value = value.toFixed(options.significantDigits)

    const [currency, decimal] = value.split('.')
    return `${options.symbol} ${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`
  }

  function base64_encode(value) {
    return base64.encode(base64.encode(base64.encode(base64.encode(value))))

  }

  async function base64_decode(value) {
    return base64.decode(base64.decode(base64.decode(base64.decode(value))))
  }

  function ValidarCNPJ(cnpj) { 
    const scnpj = cnpj.replace(/[^\d]+/g,'');

    if(scnpj == '') return false;
    
    if (scnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        scnpj == "00000000000000" || 
        scnpj == "11111111111111" || 
        scnpj == "22222222222222" || 
        scnpj == "33333333333333" || 
        scnpj == "44444444444444" || 
        scnpj == "55555555555555" || 
        scnpj == "66666666666666" || 
        scnpj == "77777777777777" || 
        scnpj == "88888888888888" || 
        scnpj == "99999999999999")
        return false;
        
    // Valida DVs
    let tamanho = scnpj.length - 2
    let numeros = scnpj.substring(0,tamanho);
    let digitos = scnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
        
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
          
    return true;
    
  }

  const CleanCampoAlunos = async() => {
    setAlunos([]);
    setTipoValue([]);
    setLstAlunos([]);    
    setTotalPag(0);
    setPagNumber(1);
    setAlunosAtivo('S');
    setNomeTipoFiltro(null);
  }

  const BuscarAlunos = async (pgselect, dtini, dtfim) => {
    try {
      if(user.codcgo !== 0){
        setPagNumber(1);    
        setLoadingAlunos(true);

        let data = new Date();
        //let mesatual = ;
        //let anoatual = ;

        let url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/alunos2`;
        
        await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    codcfo: user.codcgo,
                    cgccfo: user.cgccfo,
                    pn: pgselect?? 1,
                    rp: RowspPage,
                    ativo: alunosativos,
                    filtro: nometipofiltro,
                    messel: dtini==undefined || dtini=='' ? ('00'+ (parseInt(data.getMonth()) + 1)).slice(-2) : null,
                    anosel: dtfim==undefined || dtfim=='' ? data.getFullYear() : null,
                    dtini: dtini!=undefined  && dtini!='' ? dtini : null,
                    dtfim: dtfim!=undefined  && dtini!=''? dtfim : null
                }),
                timeout: 30000,
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  "x-access-token": user.token,
                },
              })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoadingAlunos(false); 
                if (!responseJson){
                    alert('Ocorreu um Erro'); 
                    return
                }
                if (responseJson.validtoken==false){
                    alert(responseJson.message); 
                    signOut();
                    return
                }
                
                  if(responseJson.data.length > 0){
                    const newArray = [];
                    newArray.splice(); //Limpar Array
                    responseJson.data.forEach(obj => {
                      if (!newArray.some(o => o.RA === obj.RA)) {
                        
                        let date = new Date().getDate();
                        let month = new Date().getMonth() + 1;
                        let year = new Date().getFullYear();
                        let dataagora = `${month}/${date}/${year}`
                        const date2 = new Date(dataagora);
                        const date1 = new Date(obj.DTPREVISTA);
                        let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
                        
                        //console.log(`Dias: ${diff}`);
                        //console.log(`OBJ: ${JSON.stringify(obj)}`);

                        //ALUNOS ATIVOS DATA PREVISTA MAIOR OU IGUAL DATA ATUAL
                        if(obj.ATIVO === "SIM" && (parseInt(diff) >= 0)){
                            newArray.push({ 
                              ...obj
                            })
                        //ALUNOS ATIVOS DATA PREVISTA MENOR DATA ATUAL  
                        }else if(obj.ATIVO === "NÃO" && (parseInt(diff) < 0)){
                            newArray.push({ 
                              ...obj
                            })
                        }

                      }
                
                    });

                    setAlunos(newArray); 
                    //console.log(JSON.stringify(newArray));
                    //setAlunos(responseJson.data); 
                    
                   
                    //console.log(lstalunos)
                    setTipoValue(responseJson.cursos);
                    setTotalPag(responseJson.data[0].TOTAL);   
                    setPagNumber(responseJson.PN);
                  } 
                  setLoadingAlunos(false);
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);  
                setLoadingAlunos(false);                       
            })
            /* .finally(function () {    
              setLoadingAlunos(false);                     
            });  */ 
          }else{
            setPagNumber(0);
          }
    } catch (err) {
        Aviso("Error:", err.message, "danger", 3000);
        setLoadingAlunos(false); 
    }/*  finally {
        {
          setLoadingAlunos(false); 
        }
    } */
    
  }

 const DetalheErro = async (error) => {
    let msg;

    console.log(error)

    if (error === 'TypeError: Failed to fetch'){
        msg = 'Erro de Comunicação!'
    }else{
        msg = error
    }

    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
          '## ERROR ##', // This is a title
          `${msg}`,
          {
              textConfirm: 'OK',      // Label of button confirm
              //textCancel: '',     // Label of button cancel
              onCancel: () => { { } }, // Call your cancel function 
              onConfirm: () => { { } } // Call your confirm function 
          }
      )
    return
 }


 const cacheurl = (url) => {        
    const newUrl = url.split(".br/");    
  if( url === 'http://localhost:19006/'   ||   url === 'http://10.98.1.19:19006/'   ||  newUrl[0] ==='https://developer.literatus.edu' ||  newUrl[0] ==='http://172.16.100.116:7022'  ){
      setAcessDev(true);
   }else{
      setAcessDev(false);
    }  
 }
  
  const ValidarLogin = async (login, password, newpassword, strUrl, modelo, so, areaW, areaH) => {

    //console.log('URL: ' + strUrl);
    //console.log('URL2: ' + strUrl.slice(0,34));
    //console.log(`Teste:  ${modelo}, SO:  ${so}, Area:  ${area}`);
    setIsAluno(false);

    const NewUrl = strUrl.split(".br/");
    
    if(login=='' || password==''){
      Aviso('Error', `Campos Obrigatório Pendente!!!`, 'danger', 3000);
      return false
    }
    
    if((parseInt(login.length) < 10 && !isNaN(login))){ 

      if(NewUrl[0] !== 'https://contratos.literatus.edu'  &&  NewUrl[0] !== 'https://developer.literatus.edu' && NewUrl[0] ==='http://172.16.100.116:7022'  && NewUrl[0] !== 'http://localhost:19006/'  &&  NewUrl[0] !== 'http://10.98.1.19:19006/'  &&  NewUrl[0] !== 'http://10.98.1.29:19006/' ){
            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                '## LOGIN ##', // This is a title
                `Identificamos que está usando um Registro Acadêmico para acessar este portal que é exclusivo para EMPRESAS.\nDeseja ir para o Portal CONTRATOS?`,
                {
                    textConfirm: 'SIM',      // Label of button confirm
                    textCancel: 'NÃO',     // Label of button cancel
                    onCancel: () => { { return false;} }, // Call your cancel function 
                    onConfirm: () => { {
                      const url = `https://contratos.literatus.edu.br/`
                      //Linking.openURL(url);
                      window.location.replace(url);
                      
                      } } // Call your confirm function 
                }
                )  
                return false;
      }else{
            setLoadingLogin(true);
            const pwd = Base64.btoa(password);
            const usr = Base64.btoa(login);      
            
            const model = modelo;
            const sisoper = so;
            const areautilW = areaW;
            const areautilH = areaH;
            //let crypto = usr+"@"+pwd;
            let crypto = usr+"@"+pwd+"@"+model+"@"+sisoper+"@"+areautilW+"@"+areautilH;

            /* if (width<500){
                alert('Devido a necessidade do aluno de ler o contrato, se faz necessário utilizar um computador ou notebook! Para que possa ter visão correta para leitura.\nAcesso por dispositivo móvel bloqueado.');
                setLoadingLogin(false);
                return false;
            } */
            //alert('Tamanho de 8, e é número ' + ("00000000"+login).slice(-8));
            let url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/validloginrm/${crypto}`;
            //console.log(url);
            await fetch(url, {timeout: 5000,})
                .then((response) => response.json())
                .then((responseJson) => {
                  if(responseJson.sucesso){
                    setIsAluno(true);
                    //console.log(responseJson);


                    if(responseJson.tamanho== 0){
                      alert(`Nenhum Contrato encontrado! O Mesmo pode está sendo elaborado ou todos já foram assinados.`);
                      return false;
                    }
                    
                    //alert(`DADOS: ${!!responseJson.dados[0].ALUNO}`);
                    //return false
                    //console.log(JSON.stringify(responseJson.dados));

                    dadoscontrato.splice();
                    const contratoalunos = responseJson.dados.map(function(item){
                      dadoscontrato.push({
                            ITEM: item.ITEM,
                            COUNT: item.COUNT, 
                            RA: item.RA,
                            IDPERLET: item.IDPERLET,
                            CODCONTRATO: item.CODCONTRATO,
                            ALUNO: item.ALUNO,
                            ASSINADO: item.ASSINADO,
                            CURSO: item.CURSO,
                            TURNO: item.TURNO,
                            TURMA: item.TURMA,
                            ANOLETIVO: item.ANOLETIVO,
                            CODPLANOPGTO: item.CODPLANOPGTO, 
                            CODCURSO: item.CODCURSO, 
                            CODGRADE: item.CODGRADE, 
                            CODTURNO: item.CODTURNO,
                      })                                    


                    })
                    setNomeAluno(dadoscontrato[0].ALUNO);                            
                    //if(parseInt(dadoscontrato[0].COUNT) > 1){
                      if(dadoscontrato[0].ASSINADO=='N'){
                        Aviso(`Bem Vindo(a)`,`${dadoscontrato[0].ALUNO}!`, 'success', 4000);
                      }else{
                        Aviso(`Bem Vindo(a)`,`${dadoscontrato[0].ALUNO}! \nVocê não tem contrato(s) pendente(s).`, 'success', 3000);
                      }
                      navigation.navigate('LstContratos', {count: parseInt(dadoscontrato[0].COUNT), contratos: dadoscontrato, raaluno: responseJson.dados.RA, cmdvoltar: false});                
                    /* }
                    if(parseInt(dadoscontrato[0].COUNT) == 1){
                      Aviso("Bem Vindo(a)",`${dadoscontrato[0].ALUNO}! \nBuscando seu contrato, por favor aguarde carregar.`, 'success', 8000);
                      navigation.navigate('ContratosAuto', {
                                          count: parseInt(dadoscontrato[0].COUNT), 
                                          aluno: dadoscontrato[0].ALUNO , 
                                          ra: dadoscontrato[0].RA, 
                                          idperlet: dadoscontrato[0].IDPERLET, 
                                          codcontrato: dadoscontrato[0].CODCONTRATO,
                                          codplanopgto: dadoscontrato[0].CODPLANOPGTO,
                                          codcurso: dadoscontrato[0].CODCURSO,
                                          codgrade: dadoscontrato[0].CODGRADE,
                                          codturno: dadoscontrato[0].CODTURNO,
                                          assinado: dadoscontrato[0].ASSINADO,
                                          cmdvoltar: false
                                        });
                    } */

                  }else{
                    //alert(JSON.stringify(responseJson));
                    //alert(responseJson.message);
                    Aviso('',responseJson.message,'danger', 8000);
                  }            
                })
                .catch((error) => {
                  if(error.message === 'Failed to fetch'){
                      alert("Falha de Comunicação SOAP, aguarde uns minutos e tente novamente!\nSe persistir informe o suporte.");                      
                    }else if(error.message === "Cannot read properties of undefined (reading 'ALUNO')"){
                      alert('Nenhum contrato encontrado para este RA')
                    }else{
                      alert("Erro Login: " + error.message);
                    }
                    setLoadingLogin(false);                                    
                })
                .finally(()=>{
                  setLoadingLogin(false);  
                })
              
                return false;
            
      }
    }

    if(login.length == 14 && !isNaN(login)  && (NewUrl[0] === 'https://empresas.literatus.edu' || strUrl === 'http://localhost:19006/'  ||  strUrl[0] ==='http://172.16.100.116:7022'))
    if (!ValidarCNPJ(login)){
      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
        '## CNPJ Inválido! ##', // This is a title
        `Numeração não está no padrão exigido pelo orgão emissor. Verifique os números digitado.\n${login.replace(/\D/g, '').replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")}`, // This is a alert message
        {
            textConfirm: 'OK',      // Label of button confirm
            //textCancel: '',       // Label of button cancel
            onCancel: () => {{}},   // Call your cancel function 
            onConfirm: () => {{}}   // Call your confirm function 
        }
      )
    }

    if((parseInt(login.length) == 14 && !isNaN(login)) && NewUrl[0] !== 'https://empresas.literatus.edu' && strUrl !== 'http://localhost:19006/' && strUrl !== 'http://10.98.1.19:19006/' && NewUrl[0] !== 'https://developer.literatus.edu' && NewUrl[0] ==='http://172.16.100.116:7022'){ 
      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
           '## LOGIN ##', // This is a title
           `Identificamos que está usando um CNPJ para acessar este portal que é exclusivo para CONTRATOS/ALUNO.\nDeseja ir para o Portal EMPRESAS?`,
           {
               textConfirm: 'SIM',      // Label of button confirm
               textCancel: 'NÃO',     // Label of button cancel
               onCancel: () => { { return false;} }, // Call your cancel function 
               onConfirm: () => { {
                 const url = `https://empresas.literatus.edu.br/`
                 //Linking.openURL(url);
                 window.location.replace(url);
                 
                } } // Call your confirm function 
           }
          )   
          return false;      
    }

    /* if((parseInt(login.length) == 14 && !isNaN(login)) && strUrl === 'https://empresas.literatus.edu.br/'){       
        return false;
    } */

    setLoadingLogin(true);

    if(newpassword !== null){
      //console.log('Acesso Nova Senha');
        let bdintlogin = await AsyncStorage.getItem('bddadoslogin');  
        let parsedlogin = JSON.parse(bdintlogin); 
        
        //console.log('PARSED'); 
        
        const usr = Base64.btoa(login);      
        const pwd = Base64.btoa(newpassword);

        const model = modelo;
        const sisoper = so;
        const areautilW = areaW;
        const areautilH = areaH;
        //let crypto = usr+"@"+pwd;
        let crypto = usr+"@"+pwd+"@"+model+"@"+sisoper+"@"+areautilW+"@"+areautilH;
        
        setLoadingLogin(true);
        fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/updatepwd/${crypto}`)
          .then((response) => response.json())
          .then((responseJson) => {   
            if (responseJson.sucesso){ 
              signUp(
                parsedlogin.cgccf,
                parsedlogin.nomefantasia,
                parsedlogin.nome,
                parsedlogin.codcgo,
                parsedlogin.cgccfo,
                parsedlogin.expired,
                parsedlogin.token,
                parsedlogin.admin,
                parsedlogin.adminti,
                parsedlogin.aceitetermo,
                parsedlogin.dataaceitetermo
              );

              setNewPwd(false);
              setLoadingLogin(false);
            }                
          })
          .catch((error) => {
            setLoadingLogin(false);
            DetalheErro(error);

        });
    }else{
        const pwd = Base64.btoa(password);
        const usr = Base64.btoa(login);    

        const model = modelo;
        const sisoper = so;
        const areautilW = areaW;
        const areautilH = areaH;
        //let crypto = usr+"@"+pwd;
        let crypto = usr+"@"+pwd+"@"+model+"@"+sisoper+"@"+areautilW+"@"+areautilH;
        fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/validlogin/${crypto}`) //, {method: 'GET'}   
          .then((response) => response.json())
          .then((responseJson) => {
              //console.log('utilidade.js: '+ JSON.stringify(responseJson))
              if (!responseJson.sucesso){
                setLoadingLogin(false);
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## LOGIN ##', // This is a title
                    `${JSON.stringify(responseJson.message)}`, // This is a alert message
                    {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: '',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{}} // Call your confirm function 
                    }
                )
                return
              }              
              
              if(responseJson.meet == 0){
                setLoadingLogin(false);                
                //Aviso('Erro', `Login: ${login} não identificado! Verifique as informsções e teste novamente.`, 'danger', 4000);
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## LOGIN ##', // This is a title
                    `Login: ${login} não identificado!\nVerifique as informações e tente novamente.`, // This is a alert message
                    {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: '',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{}} // Call your confirm function 
                    }
                )
                return
              }else if(responseJson.sucesso && responseJson.meet >0){
                setLoadingLogin(false);
                let timeatual = new Date();
                timeatual.setHours(timeatual.getHours() + 4);                   
                
                //ACESSO NÃO ADMIN
                if (responseJson.admin === 0 ){

                  //NÃO É  NOVO ACESSO
                  if (responseJson.novoacesso === 0){
                        signUp(
                          responseJson.dados[0].CODCFO,
                          responseJson.dados[0].NOMEFANTASIA?? null,
                          responseJson.dados[0].NOME?? null,
                          responseJson.dados[0].CGCCFO,
                          timeatual,
                          responseJson.token,
                          responseJson.admin==0 ? false : true,
                          responseJson.adminti==0 ? false : true,
                          responseJson.aceitetermo?? false,
                          responseJson.dataaceitetermo?? false
                        ); 
                  
                  //SIM NOVO ACESSO DA EMPRESA
                  }else{

                    //console.log('Retorno: ',responseJson);
                    if(responseJson.novoacesso === 1){

                        //ATIVAR CAMPO NOVA SENHA
                        setNewPwd(true) ;
                        let timeatual = new Date();
                        timeatual.setHours(timeatual.getHours() + 4);

                        
                        //ARMAZENAR DADOS DE LOGIN
                        let data = {
                          codcgo: responseJson.dados[0].CODCFO,
                          nomefantasia: responseJson.dados[0].NOMEFANTASIA,
                          nome: responseJson.dados[0].NOME,
                          cgccfo: responseJson.dados[0].CGCCFO,
                          expired: timeatual,
                          token: responseJson.token,
                          admin: responseJson.admin==0 ? false : true,
                          adminti: responseJson.adminti==0 ? false : true,
                          aceitetermo: responseJson.aceitetermo?? false,
                          dataaceitetermo: responseJson.dataaceitetermo?? false          
                      }
              
                      AsyncStorage.setItem('bddadoslogin',JSON.stringify(data)) 
                                                  
                        //EMITIR O AVISO DE NECESSIDADE DE CADASTRAR NOVA SENHA
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                          '## LOGIN ##', 
                          `Identificamos que é seu primeiro acesso, cadastre sua nova senha.`,
                          {
                              textConfirm: 'OK',      // Label of button confirm
                              //textCancel: '',     // Label of button cancel
                              onCancel: () => {{}}, // Call your cancel function 
                              onConfirm: () => {{}} // Call your confirm function 
                          }
                        )
                        return 
                    }                    

                    //VERIFICA SE VALIDAÇÃO DA SENHA
                    if (!responseJson.dados.message){
                      //AVISO DE SENHA INVÁLIDA
                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## LOGIN ##', `Senha Inválido!`,
                        {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => {{}}, // Call your cancel function 
                        onConfirm: () => {{}} // Call your confirm function 
                        }
                      )
                      //PARAR ROTINA
                      return
                    }

                      
                    //ARMAZENAR DADOS DE LOGIN
                    signUp(
                      responseJson.dados[0].CODCFO,
                      responseJson.dados[0].NOMEFANTASIA?? null,
                      responseJson.dados[0].NOME?? null,
                      responseJson.dados[0].CGCCFO,
                      timeatual,
                      responseJson.token,
                      responseJson.admin==0 ? false : true,
                      responseJson.adminti==0 ? false : true,
                      responseJson.aceitetermo?? false,
                      responseJson.dataaceitetermo?? false
                    );
                    
                    //ATIVAR CAMPO NOVA SENHA
                    setNewPwd(true) ;
                    //EMITIR O AVISO DE NECESSIDADE DE CADASTRAR NOVA SENHA
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                      '## LOGIN ##', 
                      `Identificamos que é seu primeiro acesso, cadastre sua nova senha.`,
                      {
                          textConfirm: 'OK',      // Label of button confirm
                          //textCancel: '',     // Label of button cancel
                          onCancel: () => {{}}, // Call your cancel function 
                          onConfirm: () => {{}} // Call your confirm function 
                      }
                    )

                  }               
                
                //ACESSO ADMIN
                }else{
                    //NÃO É PRIMEIRO ACESSO
                    if(responseJson.novoacesso === 0){

                      //console.log('Dados1:', responseJson);

                      //VERIFICA SE VALIDAÇÃO DA SENHA
                      if (!responseJson.dados.message){
                        //AVISO DE SENHA INVÁLIDA
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                          '## LOGIN ##', `Senha Inválido!`,
                          {
                            textConfirm: 'OK',      // Label of button confirm
                            //textCancel: '',     // Label of button cancel
                            onCancel: () => {{}}, // Call your cancel function 
                            onConfirm: () => {{}} // Call your confirm function 
                          }
                        )
                        //PARAR ROTINA
                        return
                      }

                      //console.log(`0,'ADMINISTRADOR',${login},0,${timeatual},${responseJson.token}, true, ${responseJson.aceitetermo?? false}, ${responseJson.dataaceitetermo?? false}`)
                      signUp(0,'ADMINISTRADOR',login,0,timeatual,responseJson.token, responseJson.admin?? false, responseJson.adminti?? false,responseJson.aceitetermo?? false, responseJson.dataaceitetermo?? false); 
                    //signUp(codcgo, nomefantasia, nome, cgccfo, expired, token, admin, adminti, aceitetermo, dataaceitetermo)
                    //PRIMEIRO ACESSO
                    }else{

                      //VERIFICA SE VALIDAÇÃO DA SENHA
                      if (!responseJson.dados.message){
                        //AVISO DE SENHA INVÁLIDA
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                          '## LOGIN ##', `Senha Inválido!`,
                          {
                          textConfirm: 'OK',      // Label of button confirm
                          //textCancel: '',     // Label of button cancel
                          onCancel: () => {{}}, // Call your cancel function 
                          onConfirm: () => {{}} // Call your confirm function 
                          }
                        )
                        //PARAR ROTINA
                        return
                      }
                      //ATIVAR CAMPO NOVA SENHA
                      setNewPwd(true) ;
                      let timeatual = new Date();
                      timeatual.setHours(timeatual.getHours() + 4);

                      //console.log(`0,'ADMINISTRADOR',${login},0,${timeatual},${responseJson.token}, true, ${responseJson.aceitetermo?? false}, ${responseJson.dataaceitetermo?? false}`)
                        
                      //ARMAZENAR DADOS DE LOGIN
                      let data = {
                        codcgo: 0,
                        nomefantasia: `ADMINISTRADOR`,
                        nome: login,
                        cgccfo: 0,
                        expired: timeatual,
                        token: responseJson.token,
                        admin: responseJson.admin==0 ? false : true, 
                        adminti: responseJson.adminti==0 ? false : true, 
                        aceitetermo: responseJson.aceitetermo?? false,
                        dataaceitetermo:responseJson.dataaceitetermo?? false            
                      }              
                      AsyncStorage.setItem('bddadoslogin',JSON.stringify(data)) 
                      
                      //EMITIR O AVISO DE NECESSIDADE DE CADASTRAR NOVA SENHA
                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## LOGIN ##', 
                        `Identificamos que é seu primeiro acesso, cadastre sua nova senha.`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            //textCancel: '',     // Label of button cancel
                            onCancel: () => {{}}, // Call your cancel function 
                            onConfirm: () => {{}} // Call your confirm function 
                        }
                      )
                    }
                }                
                
         }})
          .catch((error) => {
              setLoadingLogin(false);
              DetalheErro(error);
        });
    }    
  }  

  return (
    //Exportar funcão para os filhos
    <UtilContext.Provider value={{ currencyFormatter, currencyFormatterSRS, base64_encode, 
                                   base64_decode, ValidarLogin, Aviso, stsmenu, AtivaMenu, 
                                   loadinglogin, setLoadingLogin, newpwd, setNewPwd,
                                   modalVisible, setModalVisible, modalVisibletermo,
                                   setModalVisibleTermo, ValidarCNPJ,
                                   loadingalunos, setLoadingAlunos, alunos, 
                                   setAlunos, tipovalue, setTipoValue, totalpag, 
                                   setTotalPag, pagnumber, setPagNumber, alunosativos, 
                                   setAlunosAtivo, nometipofiltro, setNomeTipoFiltro,
                                   RowspPage, lstalunos, setLstAlunos, BuscarAlunos,CleanCampoAlunos,
                                   dadoscontrato, isaluno, setIsAluno, userlogin, setUserLogin,
                                   userpwd, setUserPwd, userpwdnew, setUserPwdNew,nomealuno, setNomeAluno,
                                   iscontrato, setIsContrato, islogin, setIsLogin, visiblesalvar, setVisibleSalvar,
                                   raalunoreturn, setRaAlunoReturn, accessdev, setAccessDev }}>
      {children}
    </UtilContext.Provider>
  )
}

export default UtilProvider