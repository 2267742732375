import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Linking, TextInput, Modal } from 'react-native'
import { CommonActions, useNavigation,useIsFocused  } from '@react-navigation/native';
//import {Card, TextInput} from 'react-native-paper'
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card} from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Ajuda from '../ajuda';
import Termo from '../termo';
import { positionStyle } from 'react-native-flash-message';
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');


export default function App() {  
  const { Aviso, modalVisible, setModalVisible, modalVisibletermo, 
           setModalVisibleTermo, ValidarCNPJ, alunosativos,
           nometipofiltro, BuscarAlunos, CleanCampoAlunos, lstalunos, 
           setAlunos, setTotalPag, setPagNumber,accessdev} = useContext(UtilContext);
  const [alunoscount, setAlunosCount]=useState(0);
  const [aprendizcount, setAprendizCount]=useState(0);
  const [estagiocount, setEstagioCount]=useState(0);
  const [profissionalizantecount, setProfissionalizanteCount]=useState(0);
  const [boletoscount, setBoletosCount]=useState(0);
  const { user, signOut, signUp } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [ativacache, setAtivaCache] = useState(false);
  
  const [cnpj, setCnpj] = useState('');

  const [token, setToken] = useState();
  const [admin, setAdmin] = useState();
  const [adminti, setAdminTi] = useState();

  const fetchData = async (pgselect) => {
    try {
      setToken(user.token);
      setAdmin(user.admin);
      setAdminTi(user.adminti);
      setLoading(true); 
      
      
      var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/dashboard/${pgselect?? user.codcgo}`;
      //console.log(url);
       
       await fetch(url, {
                timeout: 10000,
                headers: {
                  "x-access-token": user.token,
                },
              })
            .then((response) => response.json())
            .then((responseJson) => {
                //LISTA DE FORNECEDORES
                //console.log(responseJson)
                if (responseJson.validtoken==false){
                  alert(responseJson.message); 
                  signOut();
                  setLoading(false); 
                  return
                }

                //console.log(responseJson);
                
                //CleanCampoAlunos();      
                //Aviso('## Carregando ##', 'Aguarde carregando dados dos alunos....', 'success', 2000);          
                setAlunosCount(responseJson.data?.TOTALALUNOS);
                setAprendizCount(responseJson.data?.TOTALAPRENDIZ?? 0);
                setEstagioCount(responseJson.data?.TOTALESTAGIO?? 0);
                setProfissionalizanteCount(responseJson.data?.TOTALPROFISSIONALIZANTE?? 0);
                setBoletosCount(responseJson.data?.QTBOLETOS?? 0);

                setLoading(false);  
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);  
                setLoading(false);                       
            })
            .finally(function () {    
              setLoading(false);                     
            });  
    } catch (err) {
        Aviso("Error:", err.message, "danger", 3000);
        setLoading(false); 
    } finally {
        {
          setLoading(false); 
        }
    }
}
  const fetchDataAdm = async (pgselect) => {
    try {

      /* if (!ValidarCNPJ(pgselect)){
        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
          '## CNPJ Inválido! ##', // This is a title
          `Numeração não está no padrão exigido pelo orgão emissor. Verifique os números digitado.\n${pgselect.replace(/\D/g, '').replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")}`, // This is a alert message
          {
              textConfirm: 'OK',      // Label of button confirm
              //textCancel: '',     // Label of button cancel
              onCancel: () => {{}}, // Call your cancel function 
              onConfirm: () => {{}} // Call your confirm function 
          }
        )
      } */

      fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/admincnpj/${pgselect?? user.codcgo}`) //, {method: 'GET'}   
        .then((response) => response.json())
        .then((responseJson) => {
          //console.log('RETORNO: ',responseJson)

            if(responseJson.sucesso && responseJson.total >0){
              //setLoadingLogin(false);
              let timeatual = new Date();
              timeatual.setHours(timeatual.getHours() + 4);    
              
              signUp(
                responseJson.dados[0].CODCFO,
                responseJson.dados[0].NOMEFANTASIA?? null,
                responseJson.dados[0].NOME?? null,
                responseJson.dados[0].CGCCFO,
                timeatual,
                token,
                admin,
                adminti,
                null,
                null
              ); 
              fetchData(responseJson.dados[0].CODCFO);
            }else if(responseJson.total <= 0){
                //Aviso('Erro', `Login: ${login} não identificado! Verifique as informsções e teste novamente.`, 'danger', 4000);
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                  '## PESQUISA ##', // This is a title
                  `CNPJ Não encontrado!`, // This is a alert message
                  {
                      textConfirm: 'OK',      // Label of button confirm
                      //textCancel: '',     // Label of button cancel
                      onCancel: () => {{}}, // Call your cancel function 
                      onConfirm: () => {{}} // Call your confirm function 
                  }
                )
              return
            }     
              
      })
        .catch((error) => {
            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                '## ERROR ##', // This is a title
                `${error}`,
                {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: '',     // Label of button cancel
                    onCancel: () => { { } }, // Call your cancel function 
                    onConfirm: () => { { } } // Call your confirm function 
                }
            )
            return
      });
      
    } catch (err) {
        Aviso("Error:", err.message, "danger", 3000);
        //setLoading(false); 
    } finally {
        {
          //setLoading(false); 
        }
    }
}

  const loadcache = async () => {
      let bdintlogin = await AsyncStorage.getItem('bddadoslogin');  
      let parsedlogin = JSON.parse(bdintlogin);
      //console.log('PARSED-HOME', parsedlogin);
  }

  const cleancnpj = async () => {
    setCnpj('');
    setAlunos([]);
    setTotalPag(0);
    setPagNumber(0);
    let bdintlogin = await AsyncStorage.getItem('bddadoslogin');  
    let parsedlogin = JSON.parse(bdintlogin); 
    //console.log('LOAD-DATA: ',parsedlogin);
    if (parsedlogin){
      await signUp(parsedlogin.codcgo,parsedlogin.nomefantasia,parsedlogin.nome,parsedlogin.codcgo,parsedlogin.expired,token,admin,parsedlogin.adminti,null,null);
      setAtivaCache(false);
      //AsyncStorage.removeItem('bddadoslogin'); 
      fetchData(0);

    }
    
  }

  const searchcnpj = async () =>{
    await cleancnpj();
    if(!ativacache){
        let data = {
            codcgo: user.codcgo,
            nomefantasia: user.nomefantasia,
            nome: user.nome,
            cgccfo: user.codcgo,
            expired: user.expired,
            token: token,
            admin: admin,
            adminti: adminti,
            aceitetermo: null,
            dataaceitetermo: null      
        }              
        //console.log('DATA: ',data);
        await AsyncStorage.setItem('bddadoslogin',JSON.stringify(data)) ; 
    }
    setAtivaCache(true);    
    fetchDataAdm(cnpj);     
    
  }
  
  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        
        /* Linking.getInitialURL().then((url) => {
          //console.log(url);
          const strUrl = url.split("?")
          alert(`Toral: ${strUrl.length} - ${strUrl[1]}`);
        }); */
        
        
        //if (modalVisibletermo !== false){
        setCnpj(cnpj !== '' ? cnpj : user.cgccfo==0 ? '' : user.cgccfo.replaceAll(".","").replaceAll("/","").replaceAll("-",""));
        
        //if(lstalunos !== '')
        //BuscarAlunos();
        
        fetchData();
        //loadcache();    
        //}else{
          //setModalVisibleTermo(true);
        //}    
      }
  }, [focus]);


  return (
    <ImageBackground
      /* source={require(width > 500 ? '../../img/background5.png' : '../../img/background5.1.png')}
      style={[styles.imagebg, { width: width, height: '100%', paddingTop: 10 }]} */    
       
    >
      
      <View style={{flex:1, flexDirection: 'row', width: width , backgroundColor: '#ddd',}}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={[styles.card, {flex: 4, alignSelf: 'flex-start',top: 0,}]}>
                  <View style={[styles.card2, {left: Device.osName=='Windows' ? '' : -70, }]}>
                    <Text style={{ fontWeight: 'bold',fontSize: Device.osName=='Windows'? 15 : 11, top: Device.osName=='Windows' ? 10 : -20, position: 'relative'}}>{`OLÁ ${user?.nomefantasia ? user?.nomefantasia : user?.nome }, SEJA BEM VINDO(A)!`}</Text>
                    {user.admin ? (
                    <View style={{flexDirection: 'row', top: Device.osName=='Windows' ? 15 : -10,/*  alignItems: 'center', justifyContent: 'center', */ /* margin: 10 */}}>
                      <TextInput
                          placeholder='INFORME O CNPJ'
                          style={[styles.textinput,{width:Device.osName=='Windows' ? null : 170,height: 30,borderBottomStartRadius: 5, borderTopStartRadius: 5, }]}
                          editable={true}
                          autoCapitalize="none"
                          autoCorrect={false}
                          keyboardType='default'
                          //maxLength={18}                
                          value={cnpj}
                          onChangeText={(item) => {setCnpj(item.replaceAll(".","").replaceAll("/","").replaceAll("-",""))}}
                          onSubmitEditing={() => {
                            if(cnpj !== ''){
                              searchcnpj();
                            }else{
                              alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                  '## PESQUISA ##', // This is a title
                                  `Informe o CNPJ`,
                                  {
                                      textConfirm: 'OK',      // Label of button confirm
                                      //textCancel: '',     // Label of button cancel
                                      onCancel: () => { { } }, // Call your cancel function 
                                      onConfirm: () => { { } } // Call your confirm function 
                                  }
                              )

                            }
                          }}                
                      />
                            <View style={{ margin: 10, top: -10 }}>
                                <TouchableOpacity
                                  style={{borderTopEndRadius:5, borderBottomEndRadius:5, left: -9, backgroundColor: '#004b9e', height: 30, width: 30, alignItems: 'center', justifyContent: 'center' }}
                                  onPress={() => { 
                                    if(cnpj !== ''){
                                      searchcnpj();
                                    }else{
                                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                          '## PESQUISA ##', // This is a title
                                          `Informe o CNPJ.`,
                                          {
                                              textConfirm: 'OK',      // Label of button confirm
                                              //textCancel: '',     // Label of button cancel
                                              onCancel: () => { { } }, // Call your cancel function 
                                              onConfirm: () => { { } } // Call your confirm function 
                                          }
                                      )
                                    }                          
                                  }}
                                >
                                  <View style={{ width: '100%', flexDirection: 'row', }}>
                                    <Icon name="search" size={15} color="#eee" style={{ padding:7,}} />
                                    
                                  </View>
                                </TouchableOpacity>
                          </View>
                          <View style={{ margin: 10, top: -10 }}>
                              <TouchableOpacity
                                style={{ borderRadius: 5, backgroundColor: 'red', height: 30, width: 30, alignItems: 'center', justifyContent: 'center' }}
                                onPress={() => { 
                                  cleancnpj();                          
                                }}
                              >
                                <View style={{ width: '100%', flexDirection: 'row', }}>
                                  <Icon name="compress-arrows-alt" size={15} color="#eee" style={{ padding:7, }} />
                                  
                                </View>
                              </TouchableOpacity>
                        </View>
                    </View>):null} 
                  </View> 
            <View style={{flexDirection: 'row', left: Device.osName=='Windows'? 10 : -70, flexWrap: 'wrap', width: width * 0.72}}>
                  <Card style={[styles.cardDashBoard,{marginRight: Device.osName=='Windows'? 20 : 10}]} elevation={5} mode={'elevated'}>
                      <View style={{flexDirection: 'column',alignItems: 'center',padding: Device.osName=='Windows'? 10:null}}>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}>{`:: APRENDIZ ::`}</Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}><></></Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}><></></Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}>:: MATRICULADO ::</Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 50:20, fontWeight: 'bold', color:'#004b9e', marginTop: Device.osName=='Windows'? 20: 10, }}>{
                            loading ? (<ActivityIndicator size={Device.osName=='Windows'? 50:20} color='#004b9e' />) : (aprendizcount)
                          }</Text>
                      </View>       
                  </Card>
                  <Card style={[styles.cardDashBoard,{marginRight: Device.osName=='Windows'? 20 : 10}]} elevation={5} mode={'elevated'}>
                      <View style={{flexDirection: 'column',alignItems: 'center',padding: Device.osName=='Windows'? 10:1}}>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}>{`:: ESTÁGIO ::`}</Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}>:: MATRICULADO ::</Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 50:20, fontWeight: 'bold', color:'#004b9e', marginTop: Device.osName=='Windows'? 20: 10}}>{
                            loading ? (<ActivityIndicator size={Device.osName=='Windows'? 50:20} color='#004b9e' />) : (estagiocount)
                          }</Text>
                      </View>       
                  </Card>
                  <Card style={[styles.cardDashBoard,{marginRight: Device.osName=='Windows'? 20 : 10}]} elevation={5} mode={'elevated'}>   
                      <View style={{flexDirection: 'column',alignItems: 'center',padding: Device.osName=='Windows'? 10:1}}>
                          <Text style={{fontSize: Device.osName=='Windows'? 15:7, fontWeight: 'bold', color:'#808080',}}>:: BOLETOS ::</Text>
                          {/* <Text style={{fontSize: 13, fontWeight: 'bold', color:'#808080',}}>:: STATUS ::</Text> */}
                          <Text style={{fontSize: Device.osName=='Windows'? 13:7, fontWeight: 'bold', color:'#808080',}}>:: ABERTO(S) ::</Text>
                          <Text style={{fontSize: Device.osName=='Windows'? 50:20, fontWeight: 'bold', color:'#FF0000', marginTop: 15}}>{
                          loading ? (<ActivityIndicator size={Device.osName=='Windows'? 50:20} color='#004b9e' />) : (boletoscount)
                          }</Text>
                      </View>            
                  </Card>
            </View>
        </View>
        {/*  QUADRO DE AVISO */}
        {/* <View style={[styles.card, {flex: 1, justifyContent: 'flex-end', right: 30, height: height * 0.8}]}>            
            <Card style={[styles.cardDashBoard,{marginTop: 100, height: '100%',  maxHeight:  height * 0.5,width: 200}]} elevation={5} mode={'elevated'}>       
                <View style={{flexDirection: 'column',alignItems: 'center',padding: 10, height: '100%'}}>
                    <Text style={{fontSize: 15, fontWeight: 'bold', color:'#808080',}}>:: AVISO ::</Text>
                    <Text style={{fontSize: 12, fontWeight: 'bold', color:'#FF0000', marginTop: 20}}>{
                    loading ? (<ActivityIndicator size={50} color='#0000CD' />) : 'Aviso alguma coisa que será colocado nesse quadrados.'
                    }</Text>
                </View>       
            </Card>            
        </View> */}

        <View style={{alignSelf: 'flex-end', bottom: 20, right: Device.osName=='Windows'? 50: 100,}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
        </View>
      </View>

      <Modal animationType='slide' transparent={true} visible={modalVisible}>
                <ScrollView>
                    <View style={styles.viewmodal}>
                        <Ajuda />                       
                    </View>
                </ScrollView>
      </Modal>
      <Modal animationType='slide' transparent={false} visible={modalVisibletermo}>
                <ScrollView>
                    <View style={styles.viewmodal}>
                        <Termo />                       
                    </View>
                </ScrollView>
      </Modal>
      
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  image: {
    //flex: 1,
    resizeMode: 'contain', width: 200, height: 200,
    //top: 5,
    //backgroundColor: '#0553',
    //alignSelf: 'center',
    zIndex: -10
  },
  viewmodal: {
    backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.99,
  },
  textinput:{
    width: 250,
    padding: 10,
    borderColor: '#000',
    borderWidth:1,
    backgroundColor: '#eee',
    borderColor: '#999'
  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    flex: 1, 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    width: '90%',
    margin: 4,
    //height: height * 0.6,
    /* width: width*0.85,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    marginTop: 10, */
        
  },
  card2: {
    //flex: 1,
    width: Device.osName=='Windows'? width*0.86 : width*0.7,//400,
    height: '60%',
    paddingLeft: Device.osName=='Windows'? 20 : -70,
    paddingTop: Device.osName=='Windows'? 10 : 38,
    position: 'relative'

    //paddingTop: Device.osName=='Windows'? 38 : 38, paddingLeft:Device.osName=='Windows'? 25 : -70
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },
  cardDashBoard: {
    width: Device.osName=='Windows'? 170 : 85,//400,
    height: Device.osName=='Windows'? 180 : 80,
    marginTop: Device.osName=='Windows'? 55: 10,
    marginLeft: Device.osName=='Windows'? 10:'',  
    position: 'relative',  
    paddingTop: 10
  },

});
