import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Linking,  } from 'react-native'
import { useNavigation, useIsFocused  } from '@react-navigation/native';
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, Checkbox , TextInput } from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import { PDFReader, MobilePDFReader } from 'react-read-pdf';
import axios from 'axios';
import Tooltip from "react-native-walkthrough-tooltip";
import Base64Url from 'base64-url';
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}) {

  
  const { Aviso, dadoscontrato, isaluno, setIsAluno, iscontrato, setIsContrato, visiblesalvar, setVisibleSalvar,
          setRaAlunoReturn, setUserPwd,setUserPwdNew,setNomeAluno,accessdev } = useContext(UtilContext);
          
  const navigation = useNavigation();
  const { user, signOut } = useContext(AuthContext);
  const [guid, setGuid] = useState('');

  const [filepdf, setFilePdf] = useState('');
  const [raalunoupd, setRaAlunoUpd] = useState('');
  const [idperletupd, setIdPerletUpd] = useState('');
  const [codcontratoupd, setCodContratoUpd] = useState('');
  const [enabledsave ,setEnabledSave] = useState(false);
        
  const [loadingcontrato, setLoadingContrato] = useState(false);
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [checked, setChecked] = useState(false);
  const [pageatual, setPageAtual] = useState(1);
  const [pagetotal, setPageTotal] = useState(2);
  const [proxpage, setProxPage] = useState(true);
  const [antpage, setAntPage] = useState(false);
  const [showTip, setTip] = useState(false);

  //const [ra, setRa] = useState('2307113');
  //const [idperlet, setIdPerlet] = useState('88');
  //const [codcontrato, setCodContrato] = useState('197717');
  
  const source = "data:application/pdf;base64,"  +  filepdf;

        
  const fetchData = async (codplanopgto, ra_aluno, idperlet_aluno, codcontrato_aluno,codcurso,codgrade,codturno, assinado, coligada, contrato) => {
    try {
         setLoadingContrato(true);  
         setFilePdf('');
         setRaAlunoUpd('');
         setIdPerletUpd('');
         setCodContratoUpd('');
         var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/contratocel`;        
         await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({
                      codplanopgto: codplanopgto,
                      raaluno: ra_aluno,
                      idperlet: idperlet_aluno,
                      codcontrato: codcontrato_aluno,
                      codcurso: codcurso,
                      codgrade: codgrade,
                      codturno: codturno,
                      codcoligada: coligada,
                      tbccontrato: contrato
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      //"x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {                  
                  if (responseJson.sucesso){
                    if(iscontrato)
                    if(assinado == 'N' && Device.osName=='Windows'){
                      //alert(`É de supra importância que o Aluno leia o contrato!\nAo paginar o contrato até a última página será habilitado a opção\n"CONCORDO COM O CONTRATO E ACEITO OS TEMOS" e\nbotão "SALVAR".`);
                      alert(`É de suma importância que o Aluno leia o contrato com atenção!\nO Botão Concordo com o Contrato e Aceito os termos será liberado ao chegar na ultima página.`);
                    }else if(assinado == 'S' ){                     
                      alert(`Contrato já foi assinado!\n Gerado 2°Via.`);
                    }
                    setFilePdf(responseJson.file);
                    setRaAlunoUpd(responseJson.raaluno);
                    setIdPerletUpd(responseJson.idperlet);
                    setCodContratoUpd(responseJson.codcontrato);
                  }else{
                    alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente.\n\n`+responseJson.message);
                  }

                  setLoadingContrato(false); 
                  if(!(Device.osName=='Windows'))
                  setTip(true) 

              })
              .catch((error) => {
                  //Aviso("Error1:", error.message, "danger", 2500);  
                  alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente. Necessário efetuar o login novamente.`);
                  setLoadingContrato(false);                       
              })
              .finally(function () {    
                setLoadingContrato(false);                     
              });  
            
      } catch (err) {
          Aviso("Error2:", err.message, "danger", 2500);
          setLoadingContrato(false); 
      } finally {
          {
            setLoadingContrato(false); 
          }
      }    
  }

  const buscadadostaxa = async (value_ra, value_codcontrato) => {  
    try {        
          //setIsLoadingTaxa(true);
          var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/taxamatricula`;
          
          await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({
                      raaluno: value_ra,
                      codcontrato: value_codcontrato
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      //"x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {                
                //console.log('Reteurn: '+JSON.stringify(responseJson));
                if(responseJson.sucesso && responseJson.qttaxa>0 ){
                  //.log(responseJson.dados);
                  //alert(JSON.stringify(responseJson.dados[0].DATAVENCIMENTO))
                  let valuehistorico = (responseJson.dados[0].HISTORICO.length >200) ? responseJson.dados[0].HISTORICO.slice(0,200)+", ..." : responseJson.dados[0].HISTORICO;
                  let dadosboleto = responseJson.dados[0].DATAVENCIMENTO+";"+responseJson.dados[0].DATAEMISSAO+";"+responseJson.dados[0].DATACRIACAO+";"+
                                    responseJson.dados[0].VALORBOLETO+";"+responseJson.dados[0].NOSSONUMERO+";"+responseJson.dados[0].NOME+";"+
                                    responseJson.dados[0].IDBOLETO+";"+responseJson.dados[0].CODIGOBARRA+";"+responseJson.dados[0].CODIGOBARRADIG+";"+                                 
                                    responseJson.dados[0].LOGRADOURO+";"+responseJson.dados[0].CIDADE+";"+responseJson.dados[0].UF+";"+
                                    responseJson.dados[0].CEP+";"+responseJson.dados[0].BAIRRO+";"+responseJson.dados[0].CPF+";"+valuehistorico;
                                    
                 
                   //alert(`'${(responseJson.dados[0].CODIGOBARRADIG).slice(0,3)}'`);
                   
                   const banco = (responseJson.dados[0].CODIGOBARRADIG.toString()).slice(0,3)=="237" ? "bradesco" : (responseJson.dados[0].CODIGOBARRADIG.toString()).slice(0,3)=="033" ? "santander" : ""
                   const nomebanco = banco?? null;

                   if(nomebanco==null || nomebanco ==""){
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                      '## Boleto ##', // This is a title
                      `Boleto Desconhecido, informe o suporte!`, // This is a alert message
                      {
                      textConfirm: 'OK',      // Label of button confirm
                      //textCancel: '',     // Label of button cancel
                      onCancel: () => {{}}, // Call your cancel function 
                      onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                      }
                    )
                    return false;
                   }

                  Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/${nomebanco}.php?token=${Base64Url.encode(dadosboleto) }`); 
                  setIsContrato(false);
                  navigation.navigate('LstContratos', {raaluno: route.params.ra});

                 // setTaxa('');
                  //setIsLoadingTaxa(false);
                }else{
                  alert("ErroTaxa: " + responseJson.message);
                  //setIsLoadingTaxa(false);
                  //setTaxa('');
                }            
              })
              .catch((error) => {
                //setIsLoadingTaxa(false);
                //setTaxa('');
                if(error.message === 'Failed to fetch'){
                    alert("Erro na Consulta com Servidor");                      
                  }else{
                    alert("ErroTaxa2: " + error.message);
                  }
                                                 
              })
              .finally(()=>{
                //setIsLoadingTaxa(false);
                //setTaxa('');
              })
        
    } catch (err) {
        alert(err.message);
    } finally {{}}
}

  const UpdContrato = async (listaDados) => {
    if(raalunoupd == '' || idperletupd == '' || codcontratoupd ==''){
      alert(`Ocorreu um erro e não é possível atualizar o contato neste momento.`);
      return false;
    }

    try {
      setLoadingContrato(true);  
      var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/updcontrato`;  
      
      //TESTE REMOVER
      //console.log("token: "+ JSON.stringify(user));
      
      /* if(user.token == '' || user.token == undefined || user.toker == null){
        alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
        signOut();
        navigation.navigate('Initial');
        return false;
      } */
      
      await fetch(url, {
               method: 'POST',
               body: JSON.stringify({
                   ra: raalunoupd?? -1,
                   idperlet: idperletupd?? -1,
                   codcontrato: codcontratoupd?? -1
               }),
               timeout: 5000,
               headers: {
                   "Content-type": "application/json; charset=UTF-8",
                   //"x-access-token": user.token,
               },
             })
           .then((response) => response.json())
           .then((responseJson) => {               
               if (responseJson.sucesso){
                 setVisibleSalvar(false);
                 route.params.assinado = 'S';
                 //setFilePdf('');
                 DownloadFile(source, `Contrato_${route.params.ra}_${route.params.codcontrato}`);

                  if(!route.params.txmat){ 
                      alert(responseJson.message);
                      setChecked(false);
                  }else{
                      setChecked(false);
                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                          '## Contrato ##', // This is a title
                          `${responseJson.message}\n\n Deseja Baixar Boleto Taxa Matrícula?`, // This is a alert message
                          {
                          textConfirm: 'SIM',      // Label of button confirm
                          textCancel: 'NÃO',     // Label of button cancel
                          onCancel: () => {{
                                  setIsContrato(false);
                                  navigation.navigate('LstContratos', {raaluno: route.params.ra}); 
                          }}, // Call your cancel function 
                          onConfirm: () => {{
                                  buscadadostaxa(route.params.ra,route.params.codcontrato);
                            }} // Call your confirm function 
                          }
                        )
                        return false;
                }
                 //if(listaDados !== '')
                 //navigation.navigate('LstContratos', {contratos: listaDados});
               }else{
                //setVisibleSalvar(false);                              
                    setChecked(false);
                    alert(responseJson.message);                
               }

               setLoadingContrato(false);  

           })
           .catch((error) => {
               //Aviso("Error1:", error.message, "danger", 2500);  
               alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente. Necessário efetuar o login novamente.`);
               setLoadingContrato(false);                       
           })
           .finally(function () {    
             setLoadingContrato(false);                     
           });  
         
   } catch (err) {
       Aviso("Error2:", err.message, "danger", 2500);
       setLoadingContrato(false); 
   } finally {
       {
         setLoadingContrato(false); 
       }
   } 
  }
  
  const DownloadFile = (data, name) => {
    //const linkSource = `data:application/pdf;base64,${data}`;
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;    
    downloadLink.click();
  };

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
          //fetchData();
          //DownloadFile(filepdfb64);
          //console.log(JSON.stringify(route.params));
          if(parseInt(route.params.count) == 1 || parseInt(route.params.count) > 1)
          if(route.params.ra != '' && route.params.idperlet != '' && route.params.codcontrato != ''){  
              //setVisibleSalvar(route.visiblesalvar);
              console.log(route) 
              setRaAlunoReturn(route.params.ra);
              if(route.params.assinado == 'S'){
                  setVisibleSalvar(false);
                  //setEnabledSave(false);
              }else{
                  setVisibleSalvar(true);
                  //setEnabledSave(true);
              }
              fetchData(
                      route.params.codplanopgto,
                      route.params.ra,
                      route.params.idperlet, 
                      route.params.codcontrato,
                      route.params.codcurso,
                      route.params.codgrade,
                      route.params.codturno,
                      route.params.assinado,
                      route.params.codcoligada,
                      route.params.tbccontrato                                    
                      );
          }else{
              alert('Não identificamos contrato pendentes!');
          }

      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if(!loadingcontrato)
    setTimeOutLoading(0);

    if(loadingcontrato)
    setTimeout(() => {
     setTimeOutLoading((parseInt(timeoutloading)+1))
   }, 1000);
 }, [loadingcontrato, timeoutloading]);

 useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
  if (pageatual == 1){
      setAntPage(false);
      setProxPage(true);
  }else{
    setAntPage(true);
  }

  if (parseInt(pageatual) < parseInt(pagetotal)){
    setProxPage(true);
    setEnabledSave(false);
    setVisibleSalvar(false)  
  }else{
    setProxPage(false); 
    setEnabledSave(true); 
    
    if(route.params.assinado == 'N')  
    setVisibleSalvar(true)// visiblesalvar    
  }

}, [pageatual]);

const Mountpdf = () => {
  return (
    <>           
          {filepdf != '' ? (
          <PDFReader 
            onDocumentComplete={(numPages) => {
              //console.log(numPages);
              setPageTotal(numPages);
            }}
            scale={0.9}
            showAllPage={false} page={pageatual} url={"data:application/pdf;base64,"  +  filepdf} />
         ) : null}      
    </>
  )
}


  return (
    <ImageBackground style={{backgroundColor: '#ddd'}}>      
      <View style={{ flex:1, position:'absolute',flexDirection: 'row', height: height*0.98,width: width, /* backgroundColor: '#000', borderColor:'blue', borderWidth:1 */ }}>
               
        <View style={{flex:1,position:'relative', flexDirection:'column', top:10}}> 
        <Card elevation={5} mode={'elevated'}>       
              <View style={{height: 110, flexDirection:'column', borderBottomColor:'#ddd', borderBottomWidth:3, marginBottom: 10}}>
                  <View style={{flexDirection:'row', marginLeft:5}}>
                      <View style={{ position: 'relative', width: 30,margin:10, alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
                        <Image style={styles.logo} source={require('../../img/literatus2.png')} />
                      </View>
                      <View style={{position:'relative', flexDirection:'column', width:width*0.8,}}>
                          <View style={{position:'relative', margin: 10, flexWrap:'wrap'}}>
                              <Text style={{
                                  color: '#4F4F4F',
                                  fontSize: 11,
                                  fontFamily: 'Time New Roman',
                                  fontWeight: 'bold',
                                  //marginBottom: 10,
                                  //alignSelf: 'center',
                                  marginLeft: 5

                                }}>{`:: CONTRATO ${route.params.codcontrato !='' ? ' N° '+route.params.codcontrato : ''} DE ${route.params.aluno}  ::`}
                                </Text>
                          </View>
                          <View style={{ /* backgroundColor:'#000', */position:'relative', alignItems:'center', justifyContent:'space-between', flexDirection:'row', flexWrap:'wrap',width:width*0.8}}>
                                <View style={{position:'relative', margin: 5}}>
                                    <TouchableOpacity
                                      style={{ position: 'relative', borderRadius: 5, backgroundColor: '#004b9e', height: 25, width: 75, alignItems: 'center', justifyContent: 'center' }}
                                      onPress={() => { 
                                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                            '## SAIR ##', // This is a title
                                            `Deseja realmente sair?`,
                                            {
                                                textConfirm: 'SIM',      // Label of button confirm
                                                textCancel: 'NÃO',     // Label of button cancel
                                                onCancel: () => { { } }, // Call your cancel function 
                                                onConfirm: () => { {
                                                  //setUserLogin('');
                                                  window.location.reload(false); 
                                                  setUserPwd('');
                                                  setUserPwdNew('');
                                                  setNomeAluno('');
                                                  setIsAluno(false);
                                                  signOut() 
                                                  AtivaMenu('INICIO');
                                                  navigation.navigate('Initial');
                                                } } // Call your confirm function 
                                            }
                                        )                     
                                      }}
                                    >
                                      <View style={{width: 75, height:25,flexDirection: 'row', }}>
                                        <Icon name="door-open" size={15} color="#FFD700" style={{ padding: 3,paddingLeft: 5}} />
                                        <Text style={{ padding: 3, fontSize:  9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                          SAIR
                                        </Text>
                                      </View>
                                    </TouchableOpacity>      
                                </View>
                                <View style={{position:'relative', margin: 5}}>
                                      <TouchableOpacity
                                        style={{ position: 'relative', borderRadius: 5, backgroundColor: '#004b9e', height: 25, width: 75, alignItems: 'center', justifyContent: 'center' }}
                                        onPress={() => { 
                                          setIsContrato(false);
                                          navigation.navigate('LstContratos', {raaluno: route.params.ra});                    
                                        }}
                                      >
                                        <View style={{width: 75, height:25,flexDirection: 'row', }}>
                                          <Icon name="reply-all" size={15} color="#FFD700" style={{ padding: 3,paddingLeft: 5}} />
                                          <Text style={{ padding: 3, fontSize: 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                            Voltar
                                          </Text>
                                        </View>
                                      </TouchableOpacity>
                                </View>
                                {filepdf != '' ? (
                                <>
                                <View style={{position:'relative', margin: 5}}>
                                      <TouchableOpacity
                                          onPress={() => {
                                            //alert('Teste Dowaload');
                                            if(route.params.assinado == 'S'){
                                              DownloadFile(source, `Contrato_${route.params.ra}_${route.params.codcontrato}`);
                                            }else{
                                              alert(`Download indisponível devido o contrato ${route.params.codcontrato} ainda não está assinado!`);
                                            }
                                          }}
                                          //style={{position: 'relative',width: 70,left: 25, flexDirection:'row', backgroundColor: '#eee' }}
                                          style={{ position: 'relative', borderRadius: 5, backgroundColor: '#004b9e', width:75, height: 25, alignItems: 'center', justifyContent: 'center' }}
                                          >
                                        <View style={{width: 75, height:25,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: route.params.assinado == 'S' ? '#004b9e' : '#A9A9A9'}}>
                                          <Icon name="file-download" size={15} color='#FFD700' style={{ padding: 3 }} />
                                          <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 11}}>Download</Text>
                                        </View>
                                      </TouchableOpacity>
                                </View>
                                
                                <View style={{position:'relative', margin: 5}}>
                                      <TouchableOpacity
                                          onPress={() => { 
                                            setChecked(false);
                                            setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);                           
                                          }}

                                          style={{ /*flex: 1, */  position: 'relative',width: 75, height:25, /* right: 60, */ flexDirection:'row' }}>
                                        <View style={{width: 75, height:25,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: antpage ? '#004b9e' : '#C0C0C0' }}>
                                          {/* <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Ant.'}</Text> */}
                                          <Icon name="angle-double-left" size={15} color='#FFD700' style={{ padding: 10 }} />
                                        </View>
                                      </TouchableOpacity>      
                                </View>
                                <View style={{position:'relative', margin: 5}}>
                                      <Text style={{fontWeight: 'bold', fontSize: 15, color: '#004b9e',position:'relative', alignSelf:'center'}}>{pageatual}/{pagetotal}</Text>
                                </View>                                
                                <View style={{position:'relative', margin: 5}}>
                                      <TouchableOpacity
                                          onPress={() => { 
                                              setPageAtual((parseInt(pageatual) < parseInt(pagetotal)) ? (parseInt(pageatual) + 1) : pagetotal );                            
                                          }}

                                          style={{position: 'relative',width: 75, height:25,flexDirection:'row'}}>
                                        <View style={{width: 75, height:25,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: proxpage ? '#004b9e' : '#C0C0C0' }}>
                                          {/* <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Prox.'}</Text> */}
                                          <Icon name="angle-double-right" size={15} color='#FFD700' style={{ padding: 10 }} />
                                        </View>
                                      </TouchableOpacity>
                                </View>
                                </>
                                ):null}
                          </View>
                      </View>
                  </View>                  
                  
              </View>
              <View style={{/* width: width*0.85,  */height: height*0.6,/* backgroundColor:'red', */ overflow: filepdf!='' ? 'scroll' : null}}>
                  {(loadingcontrato && filepdf == '') ? (
                      <View style={{paddingTop: 20}}>
                        <ActivityIndicator size={50} color='#004b9e' />
                        <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15, paddingTop: 10}}>{`Pesquisando/Montando Contrato Aguarde...\nTempo: ${timeoutloading}s`}</Text>
                      </View>   
                          
                  ):(                    
                      <Mountpdf />
                  )}
              </View>
              <View style={{height:80, flexDirection:'row', padding:5, alignItems:'center',justifyContent:'space-between'}}>
                    <View style={{position:'relative', margin: 5, flexDirection:'row', alignItems:'center',}}>
                        <Text style={{fontSize: 9, fontWeight: enabledsave ? 'bold' : "normal",  position: 'relative', color: enabledsave ? "#000" : "#808080"}}>{'CONCORDO COM O CONTRATO E ACEITO OS TERMOS'}</Text>
                        <Checkbox
                          style={{position:'relative'}}
                          status={checked ? 'checked' : 'unchecked'}
                          disabled={!enabledsave}
                          onPress={() => {
                            setChecked(!checked);
                          }}
                        />
                    </View>
                    <View style={{position:'relative', margin: 5, flexDirection:'row'}}>
                          <TouchableOpacity
                                onPress={() => {
                                  if(checked && enabledsave && visiblesalvar ){
                                    //alert('Botão Liberado!\n Para Salvar no BD')
                                    //dadoscontrato.splice(0);
                                    if(parseInt(route.params.count) > 1){
                                        const dadoscontrato2 = [];
                                        const removeitem = route.params.dados.map(function(item){  
                                          if (item.ITEM !== route.params.item)                                            
                                          dadoscontrato2.push({
                                                ITEM: item.ITEM,
                                                COUNT: item.COUNT, 
                                                RA: item.RA,
                                                IDPERLET: item.IDPERLET,
                                                CODCONTRATO: item.CODCONTRATO,
                                                ALUNO: item.ALUNO,
                                                ASSINADO: item.ASSINADO,
                                                CURSO: item.CURSO,
                                                TURNO: item.TURNO,
                                                TURMA: item.TURMA,
                                                ANOLETIVO: item.ANOLETIVO                           
                                          });
                                        }); 
                                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                          '## Assinatura ##', // This is a title
                                          `Você está de acordo com o contrato?`, // This is a alert message
                                          {
                                              textConfirm: 'SIM',      // Label of button confirm
                                              textCancel: 'NÃO',     // Label of button cancel
                                              onCancel: () => {{}}, // Call your cancel function 
                                              onConfirm: () => {{
                                                //console.log(JSON.stringify(dadoscontrato));                                              
                                                setIsContrato(false);
                                                //Aviso('Contrato', `Contrato n° ${route.params.codcontrato} assinado!`, 'success', 3000);
                                                UpdContrato(dadoscontrato2);
                                                //navigation.navigate('LstContratos', {contratos: dadoscontrato2});
                                              }} // Call your confirm function 
                                          }
                                        )
                                        

                                  }else{
                                    //Aviso('Contrato', `N° ${route.params.codcontrato} assinado!`, 'success', 3000);
                                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                          '## Assinatura ##', // This is a title
                                          `Você está de acordo com o contrato?`, // This is a alert message
                                          {
                                              textConfirm: 'SIM',      // Label of button confirm
                                              textCancel: 'NÃO',     // Label of button cancel
                                              onCancel: () => {{}}, // Call your cancel function 
                                              onConfirm: () => {{
                                                UpdContrato();
                                              }} // Call your confirm function 
                                          }
                                        )    
                                    
                                  }
                                  }else{
                                    if(route.params.assinado=='S'){
                                        alert('Contrato já está assinado.');
                                    }else{
                                      if (filepdf == ''){
                                        alert('Contrato ainda não foi carregado, por favor aguarde.')
                                      }else{
                                        alert('Só podemos continuar se estiver de acordo com o contrato e paginar até última página.')
                                      }
                                    }
                                  }
                                }}
                              >
                                <View style={{flexDirection: 'row', justifyContent: 'space-between',}}>
                                    <View style={{flexDirection: 'row', width: 70,height: 25, alignItems: 'center', justifyContent: 'center', backgroundColor: !checked ? '#A9A9A9' : '#004b9e', borderRadius: 5}}>
                                      <Icon name="save" size={13} color={"#FFD700"} style={{ padding: 3}} />
                                      <Text style={{fontWeight:'bold', color: 'black', fontSize: 12, color: '#FFF'}}>Salvar</Text>
                                    </View>
                                </View>
                        </TouchableOpacity>
                    </View>
              </View>
              <View style={{/* width: width*0.85,  */height: 30, right:10/*, backgroundColor:'orange' */}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
              </View>
        </Card>
        </View>
      </View>
      <Tooltip
              isVisible={showTip}
              content={
                <View style={{}}>                    
                      <Image style={styles.orienta} source={require('../../img/movededo3.png')} />      
                </View>
              }
              placement="center"
              onClose={() => {
                setTip(false);
                alert(`É de supra importância que o Aluno leia o contrato!\nAo paginar o contrato até a última página será habilitado a opção\n"CONCORDO COM O CONTRATO E ACEITO OS TEMOS" e\nbotão "SALVAR".`);
              }}
              useInteractionManager={true} 
              topAdjustment={0}
      />
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },/* 
  pdf: {
    flex:1,
    width:Dimensions.get('window').width,
    height:Dimensions.get('window').height,
  }, */
  /* logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  }, */
  card: {
    width:Device.osName=='Windows' ? width*0.86 : width*0.95,//400,
    height: Device.osName=='Windows' ? 60 : height,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
    //alignSelf: 'center'
        
  },
  logo: {
    resizeMode: 'stretch',
    width: 50,
    height:50,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  }, 
  orienta: {
    resizeMode: 'stretch',
    width: 150,
    height: 150,
    //opacity: 0.2,
    overflow: 'hidden',
    display: 'block',
    //marginLeft: 30,
    //borderRadius: 5
  }, 

});
