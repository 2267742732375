import React, { useEffect, useState, useContext, useRef } from 'react';
import {Dimensions, ImageBackground, TouchableOpacity, Pressable, ActivityIndicator, Image, Linking} from 'react-native'
import {Card, TextInput} from 'react-native-paper'
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import * as Animatable from 'react-native-animatable';
//import { TextInputMask } from 'react-native-masked-text';
import { UtilContext } from '../contexts/utilidade';
import { useIsFocused  } from '@react-navigation/native';

import * as Device from 'expo-device';//Device.osName
import { KeyboardAvoidingView } from 'react-native-web';

const {width, height} = Dimensions.get('window');
const dotenv = require('../app.config');

export default function App() {
  const { Aviso, ValidarLogin, loadinglogin, setLoadingLogin, newpwd, 
          setNewPwd, dadoslogin, setDadosLogin, userlogin, setUserLogin, userpwd, setUserPwd,
          userpwdnew, setUserPwdNew, isaluno, setIsAluno, setIsLogin, setAccessDev} = useContext(UtilContext);
  
  const [showpwd, setShowPwd] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [showpwd2, setShowPwd2] = useState(true);
  const [rightIcon2, setRightIcon2] = useState('eye');

    const loginRef = useRef(null);
    const senhaRef = useRef(null);

    const [textlayout, setTextLayout] = useState('');
    const [isurl, setIsUrl] = useState('');
    
    Linking.getInitialURL().then((url) => {
      setIsUrl(url);
    });

  //const [newpwd, setNewPwd] = useState(false);
  //const [loadinglogin, setLoadingLogin] = useState(false);
  
  //const showanima = ['flipInX', 'flipInY', 'lightSpeedIn'];
 // const nameshow = showanima.map((number) => number * 2);
  const arr = ['flipInX', 'flipInY', 'lightSpeedIn','bounceInDown', 'bounceInUp','fadeInDown', 'fadeInDownBig',
                  'fadeInUp', 'fadeInUpBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight',
                  'fadeInRightBig'];

  const randomElement = arr[Math.floor(Math.random() * arr.length)];  
   
  function formatcnpj(cnpj){
      //XX.XXX.XXX/0001-XX
      //12.345.678/9099-99
      //const cnpjformat = cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
      if(cnpj.length === 18){
        const cnpjformat = cnpj.replace(/\D/g, '').replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
      }else{
        const cnpjformat = cnpj;
      }
      setUserLogin(cnpjformat);
  }

  function useTogglePasswordVisibility() {
    if (rightIcon === 'eye') {
        setRightIcon('eye-slash');
        setShowPwd(!showpwd);
    } else if (rightIcon === 'eye-slash') {
        setRightIcon('eye');
        setShowPwd(!showpwd);
    }
  };

  function useTogglePasswordVisibility2() {
    if (rightIcon2 === 'eye') {
        setRightIcon2('eye-slash');
        setShowPwd2(!showpwd2);
    } else if (rightIcon2 === 'eye-slash') {
        setRightIcon2('eye');
        setShowPwd2(!showpwd2);
    }
  };

  async function acessarlogin (){
     
        if(userlogin=='' || userpwd==''){
          loginRef.current.focus();
          Aviso('Error', `Campos Obrigatório Pendente!!!`, 'danger', 3000);
          return
        }
        if(newpwd && userpwdnew == ''){
          alert('Informe a Nova senha para continuar!');
          return
        }

        /* let equipamento = {
          ModeloDispositivo: Device.modelName?? 'Computador',
          SO: Device.osName,
          AreaUtil: `W:${width}xH:${height}`

        } */
        
        if(!newpwd){
          //console.log(JSON.stringify(equipamento));
          ValidarLogin(userlogin, userpwd, null, isurl, Device.modelName?? 'Computador', Device.osName, width, height);
        }
        
        if(newpwd){
          if(newpwd == ''){
            alert('Informe a Nova Senha!');
            return
          }
          //console.log(JSON.stringify(equipamento));
          ValidarLogin(userlogin, userpwd, userpwdnew, isurl, Device.modelName?? 'Computador', Device.osName, width, height);
        } 
    
  }


  useEffect(() => {
    setIsLogin(false);
   }, []);

   const focus = useIsFocused();  // useIsFocused as shown 
   useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
       if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        setIsLogin(false);
        Linking.getInitialURL().then((url) => {
          const newUrl = url.split(".br/");
          if(newUrl[0] === 'https://empresas.literatus.edu' ){
            setAccessDev(false);
            setIsAluno(false);
            setTextLayout('LITERATUS EMPRESAS');
          /* TESTAR CONEXÃO CONTRATOS - COMENTAR http://localhost:19006/ */
          }else if(  url === 'http://localhost:19006/' || url === 'http://172.16.100.116:7022/' || url === 'http://10.98.1.19:19006/'   ||  newUrl[0] ==='https://developer.literatus.edu' ){
            setAccessDev(true);
            setIsAluno(false);
            setTextLayout('LITERATUS EMPRESAS - DEV');
          }else{
            setAccessDev(false);
            setIsAluno(true);
            setTextLayout('LITERATUS CONTRATOS');
          }
        });        
            //loginRef.current.focus();
       }
   }, [focus]);

  useEffect(() => {
    function handleResize() {
      //alert('resized to: ', window.innerWidth, 'x', window.innerHeight)             
          //window.location.reload(false);          
    }
    window.addEventListener('resize', handleResize)    
  });


  return (
    <ImageBackground
            //source={require(Device.osName=='Windows' ? '../src/img/background6.png' : '../src/img/background6.1.png')}
            source={require('../src/img/BG---1980-x-1080.png')}
            style={[styles.imagebg, {width: width,}]}
    >
      
    
    <Animatable.View 
        style={{backgroundColor:'#ddd', width: Device.osName=='Windows'? 200 : 180, height: Device.osName=='Windows'? 150 : 65, position: 'absolute', right: Device.osName=='Windows'? 20: 200, bottom: Device.osName=='Windows'? 100 : 10, borderRadius: 10}}
        animation={'fadeInRight'}
        delay={1000}
    >
          <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows'? 13 : '9px', textAlign: 'center', padding: Device.osName=='Windows'? 10: 1}}>{`Estamos a disposição para lhe ouvir!\nPode ser Suporte, Elogios ou Reclamações`}</Text>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <Icon name="whatsapp" size={Device.osName=='Windows'? 20 : '15px'} color="#008000" style={{padding: Device.osName=='Windows'? 10 :0, height: Device.osName=='Windows'? 41: 10}}/>
           <TouchableOpacity onPress={() => {
                //let url = "whatsapp://send?text='Olá. vi seu site e gostaria de mais informaçõe'&phone=559235841925";
                let url = "https://api.whatsapp.com/send?phone=559291361041&text=''";
                
              Linking.openURL(url)
                .then(data => {
                  console.log("WhatsApp Opened successfully " + data);  //<---Success
                })
                .catch(() => {
                  alert("Make sure WhatsApp installed on your device");  //<---Error
                });
           }}>
            <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows'? 12: '9px', textAlign: 'center', padding: Device.osName=='Windows'? 10: 1, textDecorationLine: 'underline',}}>(92) 9136-1041</Text>
           </TouchableOpacity>
          </View>
    </Animatable.View>
    
    <Animatable.View 
      style={{position: 'absolute', top:20}}
      animation={'fadeInDown'}
      delay={1000}
    >
      <Text style={{flex:1, color:'#FFF', fontWeight: 'bold', fontSize: Device.osName=='Windows'? '30px' : '15px', textAlign:'center', padding: 5, width: width*0.9}}>BEM-VINDO AO PORTAL</Text>
      <Text style={{flex:1, color:'#FFF', fontWeight: 'bold', fontSize: Device.osName=='Windows'? '35px' : '18px', textAlign:'center', padding: 5, width: width*0.9}}>{textlayout}</Text>
      <Text style={{flex:1, color:'#FFF', fontWeight: 'bold', fontSize: Device.osName=='Windows'? '25px' : '15px', textAlign:'center', padding: 5, width: width*0.9}}>É um enorme prazer tê-lo conosco!</Text>
    </Animatable.View>
    
    <Animatable.View 
                    //style={{width: '100%', height: '100%',}}
                    style={[styles.card,{width: '100%',}]}
                    //fadeInLeft | fadeInLeft | fadeInDown 
                    /* 
                      'flash' | 'jello' | 'pulse' | 'rotate' | 'rubberBand' | 'shake' |
                      'swing' | 'tada' | 'wobble' | 'bounceIn' | 'bounceInDown' | 'bounceInUp' |
                      'bounceInLeft' | 'bounceInRight' | 'bounceOut' | 'bounceOutDown' | 'bounceOutUp' |
                      'bounceOutLeft' | 'bounceOutRight' | 'fadeIn' | 'fadeInDown' | 'fadeInDownBig' |
                      'fadeInUp' | 'fadeInUpBig' | 'fadeInLeft' | 'fadeInLeftBig' | 'fadeInRight' |
                      'fadeInRightBig' | 'fadeOut' | 'fadeOutDown' | 'fadeOutDownBig' | 'fadeOutUp' |
                      'fadeOutUpBig' | 'fadeOutLeft' | 'fadeOutLeftBig' | 'fadeOutRight' | 'fadeOutRightBig' |
                      'flipInX' | 'flipInY' | 'flipOutX' | 'flipOutY' | 'lightSpeedIn' | 'lightSpeedOut' |
                      'slideInDown' | 'slideInUp' | 'slideInLeft' | 'slideInRight' | 'slideOutDown' |
                      'slideOutUp' | 'slideOutLeft' | 'slideOutRight' | 'zoomIn' | 'zoomInDown' |
                      'zoomInUp' | 'zoomInLeft' | 'zoomInRight' | 'zoomOut' | 'zoomOutDown' |'zoomOutUp' |
                      'zoomOutLeft' |'zoomOutRight';
                    */                   
                    //animation="lightSpeedIn"
                    animation={'flipInX'}
                    delay={500}
                >  
    <Card style={[styles.card,{width:Device.osName=='Windows' ? width*0.3 : width*0.7, height:height*0.3, backgroundColor:'#ddd'}]} elevation={5} mode={'elevated'}>
      {/* <View style={{width: '100%', height: '100%',}}> */}
        <KeyboardAvoidingView enabled>
        {/* <Text>{`SO: ${Device.osName}`}</Text> */}
        <View style={{position: 'relative',flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, width: '95%'}}>
            <Icon name={!isaluno ? "user-alt" : "user-graduate"} size={20} color="#eee" style={{position:'relative',padding: 10, left:7,backgroundColor:'#004b9e', borderTopStartRadius: 5, borderBottomStartRadius: 5, height: 41}}/>
            <TextInput
                placeholder={!isaluno ? 'CNPJ OU ADMIN' : 'N° RA'}
                //style={[styles.textinput,{borderBottomEndRadius: 5, borderTopEndRadius: 5, height: 20}]}
                style={[styles.textinput,{position:'relative',  width: Device.osName=='Windows' ? '105%': '65%', left: Device.osName=='Windows' ? 7: 7, fontSize: '12px',height: 20,borderBottomEndRadius: 5, borderTopEndRadius: 5, borderBottomColor: '#808080', borderBottomWidth:2}]}
                editable={true}
                ref={loginRef}
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType='default'
                maxLength={18}                
                value={userlogin}
                onChangeText={(item) => setUserLogin(item)}
                onSubmitEditing={() => senhaRef.current.focus()}                
            />
        </View>
        <View style={{position: 'relative',flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, width: '100%',}}>
            <Icon name="key" size={20} color="#eee" style={{padding: 10, backgroundColor:'#004b9e', borderTopStartRadius: 5, borderBottomStartRadius: 5, height: 41}}/>            
            <TextInput
                placeholder="SENHA"
                style={[styles.textinput,{position:'relative', justifyContent:'flex-end',width: Device.osName=='Windows' ? '100%' : '60%', fontSize: '12px',borderBottomEndRadius: 5, borderTopEndRadius: 5, height: 20}]}
                editable={true}
                ref={senhaRef}
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType='default'
                value={userpwd}
                onChangeText={(item) => setUserPwd(item)}
                secureTextEntry={showpwd}  
                onSubmitEditing={() => acessarlogin()}   
                           
            />
            <Pressable
                style={{ /* position: 'absolute', right: 10,  */}}
                onPress={() => useTogglePasswordVisibility()}>
                <Icon name={rightIcon} size={22} color="#232323" style={{left: -30,position: 'absolute', top: -11}} />
            </Pressable>
        </View>
        {newpwd ? (
              <View style={{position: 'relative',flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, width: '100%'}}>
                  <Icon name="key" size={20} color="#eee" style={{padding: 10, backgroundColor:'#004b9e', borderTopStartRadius: 5, borderBottomStartRadius: 5, height: 41}}/>
                  <TextInput
                      placeholder="NOVA SENHA"
                      style={[styles.textinput,{position:'relative',justifyContent:'flex-end',width: Device.osName=='Windows' ? '100%' : '60%', fontSize: '12px',borderBottomEndRadius: 5, borderTopEndRadius: 5, height: 20}]}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='default'
                      value={userpwdnew}
                      onChangeText={(item) => setUserPwdNew(item)}
                      secureTextEntry={showpwd2}
                      onSubmitEditing={() => acessarlogin()}                               
                  />
                  <Pressable
                      style={{ /* position: 'absolute', right: 10,  */}}
                      onPress={() => useTogglePasswordVisibility2()}>
                      <Icon name={rightIcon2} size={22} color="#232323"  style={{left: -30,position: 'absolute', top: -11}}/>
                  </Pressable>
              </View>
        ) : null}
        
        <TouchableOpacity
            style={{borderRadius: 5, backgroundColor: '#004b9e', padding: 10, height: 35, width: Device.osName=='Windows' ? 200 : 150, borderColor:'#ddd', borderWidth:1, alignSelf: 'center', top: 10 }}
            onPress={() => { acessarlogin()}}
              
            >               
           <Text
                  style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}
              >
                  {loadinglogin ? (
                    <View style={{top: -5}}>
                      <ActivityIndicator size={25} color='#FFF' />
                    </View>
                  ):(
                    !newpwd ? 'ENTRAR' : 'SALVAR'
                  )}
            </Text>
                     
        </TouchableOpacity>
      {/* </View> */}
      <View style={{position: 'relative', top: 5, bottom: 1, alignItems:'center',justifyContent:'center', margin:10}}>
        <Text style={{position:'relative',textAlign:'center',fontSize: 10}}>{`Dispositivo: ${Device.osName}-${width}x${height}`}</Text>
      </View>
      </KeyboardAvoidingView>
      <StatusBar style="auto" />
    </Card></Animatable.View> 
    <Animatable.View 
        /* 
          'flash' | 'jello' | 'pulse' | 'rotate' | 'rubberBand' | 'shake' |
          'swing' | 'tada' | 'wobble' | 'bounceIn' | 'bounceInDown' | 'bounceInUp' |
          'bounceInLeft' | 'bounceInRight' | 'bounceOut' | 'bounceOutDown' | 'bounceOutUp' |
          'bounceOutLeft' | 'bounceOutRight' | 'fadeIn' | 'fadeInDown' | 'fadeInDownBig' |
          'fadeInUp' | 'fadeInUpBig' | 'fadeInLeft' | 'fadeInLeftBig' | 'fadeInRight' |
          'fadeInRightBig' | 'fadeOut' | 'fadeOutDown' | 'fadeOutDownBig' | 'fadeOutUp' |
          'fadeOutUpBig' | 'fadeOutLeft' | 'fadeOutLeftBig' | 'fadeOutRight' | 'fadeOutRightBig' |
          'flipInX' | 'flipInY' | 'flipOutX' | 'flipOutY' | 'lightSpeedIn' | 'lightSpeedOut' |
          'slideInDown' | 'slideInUp' | 'slideInLeft' | 'slideInRight' | 'slideOutDown' |
          'slideOutUp' | 'slideOutLeft' | 'slideOutRight' | 'zoomIn' | 'zoomInDown' |
          'zoomInUp' | 'zoomInLeft' | 'zoomInRight' | 'zoomOut' | 'zoomOutDown' |'zoomOutUp' |
          'zoomOutLeft' |'zoomOutRight';
        */ 
        style={{position: 'absolute', alignSelf: 'flex-end', bottom: 10, right: 50,justifyContent: 'center', alignItems:'center'}}
        animation={'zoomIn'}
        delay={1000}
        >
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
    </Animatable.View>
    
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  image: {
    flex: 1,
    //alignItems: 'center',
    //justifyContent: 'center',
    width: width,
    height: height,

  },
  card: {
    width: Device.osName=='Windows' ? 400 : width * 0.9,//400,
    height: 250,
    alignItems: 'center',
    justifyContent: 'center',
    //top: Device.osName=='Windows' ? '' : '50%'//-50
        
  },
  textinput:{
    width: 250,
    padding: 10,
    borderColor: '#000',
    borderWidth:1,
    backgroundColor: '#eee',
    borderColor: '#999'
  },
  /* textinput:{
    width: 230,
    padding: 10,
    borderColor: '#000',
    borderWidth:1,
    backgroundColor: '#eee',
    borderColor: '#999'
  }, */  
});
