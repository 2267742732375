import React, {useState, useContext, useEffect} from 'react';
import {Dimensions, View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, ActivityIndicator} from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { UtilContext } from '../../../contexts/utilidade';
import { AuthContext } from '../../../contexts/auth';
import { useIsFocused  } from '@react-navigation/native';

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}){
    const [loading, setLoading] =useState(false);
    const { Aviso, accessdev } = useContext(UtilContext);
    const { user} = useContext(AuthContext);
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const  [idcontrato, setIdContratos] = useState('');
    const  [condicao, setCondicao] = useState('');
    const  [condatual, setCondAtual] = useState('');
    const [listcondicao, setListCondicao]=useState([]);
    
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading)
        setTimeOutLoading(0);
   
        if(loading)
        setTimeout(() => {
         setTimeOutLoading((parseInt(timeoutloading)+1))
       }, 1000);
     }, [loading, timeoutloading]);

     const focus = useIsFocused();  // useIsFocused as shown 
        useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
            if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                buscacondicoes();
            }
        }, [focus]);

        useEffect(() => {   // whenever you are in the current screen, it will be true vice versa  
        
        }, []);


     async function buscacondicoes(){
        try {
            setLoading(true);
            setCondAtual('');
            setListCondicao([]);

            await fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/contratoscondicoes`, {
                    method: 'POST',
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    /* if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    } */
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                        setCondAtual('CASE'+responseJson.dados.replaceAll('WHEN', `\n   WHEN`)+'\n   ELSE NULL\nEND TBCCONTRATO');
                        setListCondicao(responseJson.listadados);
                      }
                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}

    }
    
    function deletecontrato(id, idcont){
        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## Deletar Contrato ##', // This is a title
                    `Deseja Realmente deletar o contrato ${idcont}?`, // This is a alert message
                    {
                    textConfirm: 'SIM',      // Label of button confirm
                    textCancel: 'NÃO',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{
                        try {
                            setLoading(true);
                            setCondAtual('');
                            setListCondicao([]);
                            fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/delcontratoscondicoes`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        id: id
                                    }),
                                    timeout: 5000,
                                    headers: {
                                      "Content-type": "application/json; charset=UTF-8",
                                      "x-access-token": user.token,
                                    },
                                  })
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    /* if (responseJson.validtoken==false){
                                        alert(responseJson.message); 
                                        setLoading(false);
                                        return
                                    } */
                                      
                                      if(!responseJson.sucesso){
                                        Aviso("Error:", responseJson.message, "danger", 3000); 
                                      }
                                      else{
                                        buscacondicoes();
                                      }
                                      setLoading(false);
                                })
                                .catch((error) => {
                                    Aviso("Error:", error.message, "danger", 3000);  
                                    setLoading(false);
                                })
                                .finally(function () {
                                    setLoading(false);
                                });  
                        } catch (err) {
                            Aviso("Error:", err.message, "danger", 3000); 
                            setLoading(false);
                        } finally {{
                            setLoading(false);
                        }}

                    }} // Call your confirm function 
                    }
                  )
    }
    
    function desativacontrato(id, idcont, status){
        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    `## ${status==1 ? 'Desativar' : 'Ativar'} Contrato ##`, // This is a title
                    `Deseja Realmente ${status==1 ? 'desativar' : 'ativar'} o contrato ${idcont}?`, // This is a alert message
                    {
                    textConfirm: 'SIM',      // Label of button confirm
                    textCancel: 'NÃO',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{

                        try {
                            setLoading(true);
                            setCondAtual('');
                            setListCondicao([]);
                            fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/descontratoscondicoes`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        id: id,
                                        status: !status
                                    }),
                                    timeout: 5000,
                                    headers: {
                                      "Content-type": "application/json; charset=UTF-8",
                                      "x-access-token": user.token,
                                    },
                                  })
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    /* if (responseJson.validtoken==false){
                                        alert(responseJson.message); 
                                        setLoading(false);
                                        return
                                    } */
                                      
                                      if(!responseJson.sucesso){
                                        Aviso("Error:", responseJson.message, "danger", 3000); 
                                      }
                                      else{
                                        buscacondicoes();
                                      }
                                      setLoading(false);
                                })
                                .catch((error) => {
                                    Aviso("Error:", error.message, "danger", 3000);  
                                    setLoading(false);
                                })
                                .finally(function () {
                                    setLoading(false);
                                });  
                        } catch (err) {
                            Aviso("Error:", err.message, "danger", 3000); 
                            setLoading(false);
                        } finally {{
                            setLoading(false);
                        }}

                    }} // Call your confirm function 
                    }
                  )
    }

    function salvarcontrato(){
        try {

            
            if(idcontrato=="" || condicao==""){
                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## CAMPO OBRIGATÓRIO ##', // This is a title
                    `Campo Obrigatório Pendente!`, // This is a alert message
                    {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: 'NÃO',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{
                        return false;
                    }} // Call your confirm function 
                    }
                  )
                  return false;
                
            }else{
            setLoading(true);
            setCondAtual('');
            setListCondicao([]);
            fetch(`${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/cadcontratoscondicoes`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: idcontrato,
                        condicoes: condicao.replaceAll('"', "'")
                    }),
                    timeout: 5000,
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                    },
                  })
                .then((response) => response.json())
                .then((responseJson) => {
                    /* if (responseJson.validtoken==false){
                        alert(responseJson.message); 
                        setLoading(false);
                        return
                    } */
                      
                      if(!responseJson.sucesso){
                        Aviso("Error:", responseJson.message, "danger", 3000); 
                      }
                      else{
                        setIdContratos('');
                        setCondicao('');
                        buscacondicoes();
                      }
                      setLoading(false);
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {
                    setLoading(false);
                });  
            }
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000); 
            setLoading(false);
        } finally {{
            setLoading(false);
        }}
    
    }

    
    function getcontrato({ item: contrato }) {                   
        return (    
            <View style={{ width: width * 0.8, paddingTop: 10, flexDirection: 'row', top: -5, borderBottomColor: '#000', borderBottomWidth: 1.5, }}>
                <View style={{ width: 100 }}><Text style={{}}>{contrato.ID}</Text></View>
                <View style={{ width: 100 }}><Text style={{}}>{contrato.ATIVO==1 ? "SIM" : "NÃO"}</Text></View>
                <View style={{ width: 100}}><Text style={{}}>{contrato.IDCONTRATO}</Text></View>
                <View style={{ width: 600}}><Text style={{}}>{contrato.CONDICAO}</Text></View>

                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => {
                        deletecontrato(contrato.ID, contrato.IDCONTRATO);
                    }}>
                        <View style={{width: 70,flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <Icon name="trash-alt" size={23} color="#FF0000" style={{ padding: 10 }} />              
                        </View>
                    </TouchableOpacity>            
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => {
                        desativacontrato(contrato.ID, contrato.IDCONTRATO, contrato.ATIVO );
                    }}
                    >
                        <View style={{width: 70,flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <Icon name={contrato.ATIVO==0 ? "ban" : "check-circle"} size={23} color={contrato.ATIVO==0 ? "#FF0000": "#00FF00"} style={{ padding: 10 }} />              
                        </View>
                    </TouchableOpacity>            
                </View>
            </View>          
        )
      }


    return(
        <View style={{flexDirection:'column', padding: 10}}>
            <Text><strong>TABELAS CARREGADAS: </strong><span style={{fontSize: 12}}>{`\nSCONTRATO, SMATRICPL, SALUNO, PPESSOA, SHABILITACAOFILIAL, STURNO, SCURSO,SHABILITACAO, SPLETIVO, FCFO, SRESPONSAVELCONTRATO, SBOLSAALUNO, SBOLSA`}</span></Text>                          
            
            <View style={{padding: 5, flexDirection: 'row', alignItems: 'top'}}>
                    <View>
                        <Text style={{fontWeight:'bold', fontSize: 13}}>ID CONTRATO:</Text>
                        <TextInput
                            placeholder={'1234'}
                            style={{width: 100,height: 35,borderBottomStartRadius: 5, borderWidth:3, padding: 5, marginRight: 10,borderColor:'#ddd', borderRadius:5 }}
                            editable={true}
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType='default'
                            value={idcontrato}
                            onChangeText={(item) => {setIdContratos(item)}}
                            /* onSubmitEditing={(item) => {
                                buscacontratoaluno(valuera);                            
                            }}  */               
                        />   
                    </View>
                    <View>
                    <Text style={{fontWeight:'bold', fontSize: 13}}>VALIDAÇÃO PARA ID:</Text>                         
                    <TextInput
                        placeholder={`EXEMPLO:\n(SALUNO.CODCOLIGADA=5 AND ((SCURSO.CODMODALIDADECURSO !='01' AND  SCURSO.CODMODALIDADECURSO != '02')) AND (SBOLSAALUNO.ATIVA='N'  OR SBOLSAALUNO.ATIVA IS NULL OR SBOLSAALUNO.ATIVA=''))`}
                        style={{width: width*0.7,borderBottomStartRadius: 5, borderWidth:3, padding: 5,borderColor:'#ddd', borderRadius:5 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        multiline
                        numberOfLines={3}
                        value={condicao}
                        onChangeText={(item) => {setCondicao(item)}}
                        /* onSubmitEditing={(item) => {
                            buscacontratoaluno(valuera);                            
                        }}  */               
                    /> 
                    </View> 
                    <View style={{position:'relative', flexDirection:'row'}}>
                        <View style={{flex: 1, padding: 10, top: 20}}>
                                <TouchableOpacity
                                    style={{backgroundColor: '#004b9e', height: 50, width: 50, alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}
                                    onPress={() => { 
                                        salvarcontrato();                                                                  
                                    }}
                                    >
                                    <View style={{ width: '100%', flexDirection: 'row', }}>
                                        <Icon name="save" size={15} color="#eee" style={{ padding:20,}} />                                                
                                    </View>
                                </TouchableOpacity>
                        </View>
                    </View>
            </View>
            <Text><strong>Validação Cadastrada:</strong>{`\n`}<span>{condatual}</span></Text>
            <View style={{padding: 5}}>
                {loading ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={50} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                   
                  </View>  
                ) : (
                  <>   
                    <View style={{ width: width * 0.8, paddingTop: 0, flexDirection: 'row', /* height: 40, */ }}>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>#</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>ATIVO</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>IDCONTRATO</Text></View>
                      <View style={{ width: 600 }}><Text style={{fontWeight: 'bold'}}>VALIDAÇÃO</Text></View>                      
                      <View style={{ width: 70 }}><Text style={{fontWeight: 'bold'}}>DELETE</Text></View>                      
                      <View style={{ width: 70 }}><Text style={{fontWeight: 'bold', fontSize: 12}}>ATIVAR / DESATIVAR</Text></View>                      
                    </View>                
                    <FlatList
                      data={listcondicao}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ID.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getcontrato(item)}
                    />  
                  </>
                )} 
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        resizeMode: 'stretch',
        width: width,
        height: height,
      },
  });