import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Alert, Linking, Modal } from 'react-native'
import { CommonActions, useNavigation,useIsFocused  } from '@react-navigation/native';
//import {Card, TextInput} from 'react-native-paper'
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, RadioButton, TextInput} from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import Tooltip from "react-native-walkthrough-tooltip";
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route, navigation}) {
  const { Aviso, loadingalunos, setLoadingAlunos, 
          alunos, setAlunos, tipovalue, setTipoValue, 
          totalpag, setTotalPag, pagnumber, setPagNumber, 
          alunosativos, setAlunosAtivo, nometipofiltro, 
          setNomeTipoFiltro, RowspPage, BuscarAlunos,
          lstalunos, setLstAlunos, CleanCampoAlunos, accessdev } = useContext(UtilContext);
 
  const { user, signOut } = useContext(AuthContext); 
  const [showTip, setTip] = useState(false);
  const [alunosfaltas, setAlunosFaltas] = useState();
  const [nomedoaluno, setNomeAluno] = useState();
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [loading, setLoading] = useState(-1);
  const [valuejust, setValueJust] = useState(false);

  const [mes, setMes]=useState(0);
  const [ano, setAno]=useState(0);
  const [datafilter, setDataFilter] = useState('');
  const [mesanode, setMesAnoDe] = useState('');
  const [mesanoate, setMesAnoAte] = useState('');

  const [modalVisibleFiltro, setModalVisibleFiltro] = useState(false);
  

  //Tipo de Curso
  const [searchQueryTipo, setSearchQueryTipo] = useState('');
  const onChangeSearchTipo = query => setSearchQueryTipo(query);
  const dropdownControllertipo = useRef(null);
  const searchRefTipo = useRef(null);
  const onOpenSuggestionsListTipo = useCallback(isOpened => {}, []);

  function capitalize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
       
        var w = words[a];
        if (
               w.toLowerCase() !== 'de' 
            && w.toLowerCase() !== 'da' 
            && w.toLowerCase() !== 'das' 
            && w.toLowerCase() !== 'do' 
            && w.toLowerCase() !== 'dos' 
            && w.toLowerCase() !== 'em' 
            && w.toLowerCase() !== 'e'  
          ){
          //if(w[0] !== undefined || w[0] !== null)
          words[a] = w[0].toUpperCase() + w.slice(1);
        }else{
          words[a] = w[0] + w.slice(1);
        }
    }
    return words.join(" ");
  }

  function getalunos({ item: alunos }) {
    //console.log(LstTicket);
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    let dataagora = `${month}/${date}/${year}`
    const date2 = new Date(dataagora);
    const date1 = new Date(alunos.DTPREVISTA);
    let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
    let dias = moment.duration(diff).asDays();
    //console.log('Dias:', dias);
      /* if(alunosativos=='S'){
        if(dias < 0) return
      }
      if(alunosativos=='N'){
        if(dias >= 0) return
      } */

    return (
      
      <View style={{overflow:'hidden'}}>
      {Device.osName=='Windows' ? (
        <View style={{ width: width*0.83,  flexDirection: 'row', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
          <View style={{ width: 70 }}><Text style={{}}>{alunos.RA}</Text></View>
          <View style={{ width: 150 }}><Text style={{}}>{alunos.NOME}</Text></View>
          {user.adminti==1 ? (
          <View style={{ width: 150 }}><Text style={{}}>{alunos.RESPFINB2B==null ? '## Indefinido ##' : alunos.RESPFINB2B}</Text></View>
          ):null}
          <View style={{ width: 50 }}><Text style={{}}>{capitalize(alunos.ATIVO)}</Text></View>
          <View style={{ width: 120}}><Text style={{}}>{(alunos.TIPO)}</Text></View>
          <View style={{ width: 130 }}><Text style={{}}>{capitalize(alunos.CURSO)}</Text></View>
          <View style={{ width: 80 }}><Text style={{}}>{moment((alunos.DTINICIO).slice(0,10)).format("DD/MM/YYYY")}</Text></View>
          <View style={{ width: 80 }}><Text style={{color: dias < 15 ? '#FF0000': ''}}>{moment((alunos.DTPREVISTA).slice(0,10)).format("DD/MM/YYYY")}</Text></View>
          <View style={{ width: 90, alignItems: 'center', left: -10 }}><Text style={{color: dias < 15 ? '#FF0000': ''}}>{dias >= 0 ?  `${parseInt(dias)} dias` : ''}</Text></View>
          <TouchableOpacity onPress={()=>{
            //if(alunos.FALTAS === 0){
            if(alunos.FALTASSEMJUST === 0){
              alert('Não existe Falta');
              return
            }
            setLoading(alunos.ITEM);
            fetchDataAlunosFaltas(alunos.RA, alunos.NOME, 'NJUST');
            Aviso('','Pesquisando Faltas, aguarde...', 'success', 2000);                 
          }}>
              <View style={{ width: '10%', alignItems: 'center', left: 23,}}>
                {loading !== alunos.ITEM ? (
                  <Text style={{textDecorationLine: 'underline',color: alunos.FALTASSEMJUST > 0 ?  '#FF0000': '#00008B', fontWeight: alunos.FALTASSEMJUST > 0 ? 'bold': 'normal'}}>{dias >= 0 ? alunos.FALTASSEMJUST : ''}</Text>
                  ) : (
                    <ActivityIndicator size={15} style={{position:'relative', top: 2}} color={alunos.FALTASSEMJUST > 0 ?  '#FF0000': '#004b9e'} />
                  )}
              </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={()=>{
            //if(alunos.FALTAS === 0){
            if(alunos.FALTASCOMJUST === 0){
              alert('Não existe Falta');
              return
            }
            setLoading(alunos.ITEM+'J');
            fetchDataAlunosFaltas(alunos.RA, alunos.NOME, 'JUST');
            Aviso('','Pesquisando Faltas, aguarde...', 'success', 2000);                 
          }}>
              <View style={{ width: '10%', alignItems: 'center', left: 100,}}>
                {loading !== alunos.ITEM+'J' ? (
                  <Text style={{textDecorationLine: 'underline',color: alunos.FALTASCOMJUST > 0 ?  '#004b9e': '#00008B', fontWeight: alunos.FALTASCOMJUST > 0 ? 'bold': 'normal'}}>{dias >= 0 ? alunos.FALTASCOMJUST : ''}</Text>
                  ) : (
                    <ActivityIndicator size={15} style={{position:'relative', top: 2}} color={alunos.FALTASCOMJUST > 0 ?  '#004b9e': '#004b9e'} />
                  )}
              </View>
          </TouchableOpacity>
        </View>
      ):(
        <View style={{  width: width*0.62, overflow:'hidden',  flexDirection: Device.osName=='Windows' ? 'row' : 'column', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
          <ScrollView>
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 23, fontSize: 12}}>RA:</Text><Text style={{fontSize: 12}}>{alunos.RA}</Text></View>
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 5, fontSize: 12}}>Nome:</Text><Text style={{fontSize: 12}}>{alunos.NOME}</Text></View>
          {user.adminti==1 ? (
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 5, fontSize: 12}}>Resp. Financ.:</Text><Text style={{fontSize: 12}}>{alunos.RESPFINB2B==null ? '## Indefinido ##' : alunos.RESPFINB2B}</Text></View>
          ):null}
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 10, fontSize: 12}}>Ativo:</Text><Text style={{}}>{capitalize(alunos.ATIVO)}</Text></View>
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 15, fontSize: 12}}>Tipo:</Text><Text style={{}}>{(alunos.TIPO)}</Text></View>
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{fontWeight:'bold', paddingRight: 8, fontSize: 12}}>Curso:</Text><Text style={{}}>{capitalize(alunos.CURSO)}</Text></View>
          <View style={{flexDirection: 'row', padding: 2}}>
              <Text style={{fontWeight:'bold', paddingRight: 12, fontSize: 12}}>Início:</Text>
              <Text style={{paddingRight: 7}}>{moment((alunos.DTINICIO).slice(0,10)).format("DD/MM/YYYY")}</Text>
              <Text style={{color: dias < 15 ? '#FF0000': '',fontWeight:'bold', paddingRight: 12, fontSize: 12}}>Fim:</Text>
              <Text style={{color: dias < 15 ? '#FF0000': ''}}>{moment((alunos.DTPREVISTA).slice(0,10)).format("DD/MM/YYYY")}</Text>
          </View>
          {/* <View style={{flexDirection: 'row', padding: 2}}><Text style={{color: dias < 15 ? '#FF0000': ''}}>Fim: {moment((alunos.DTPREVISTA).slice(0,10)).format("DD/MM/YYYY")}</Text></View> */}
          <View style={{flexDirection: 'row', padding: 2}}><Text style={{color: dias < 15 ? '#FF0000': ''}}>Restante: {dias >= 0 ?  `${parseInt(dias)} dias` : ''}</Text></View>
          <TouchableOpacity style={{paddingBottom: 5}} onPress={()=>{
            if(alunos.FALTAS === 0){
              alert('Não existe Falta');
              return
            }
            setLoading(alunos.ITEM);
            fetchDataAlunosFaltas(alunos.RA, alunos.NOME, 'NJUST');
            Aviso('','Pesquisando Faltas, aguarde...', 'success', 2000);                 
          }}>
              <View style={{padding: 5, backgroundColor: '#004b9e', borderRadius: 5, width: 80,textAlign: 'center'}}>
                {loading !== alunos.ITEM ? (
                  <Text style={{color: alunos.FALTASSEMJUST > 0 ?  '#FF0000': '#FFF', fontWeight: alunos.FALTASSEMJUST > 0 ? 'bold': 'normal'}}>Falta: {dias >= 0 ? alunos.FALTASSEMJUST : ''}</Text>
                  ) : (
                    <ActivityIndicator size={15} style={{position:'relative', top: 2}} color={alunos.FALTASSEMJUST > 0 ?  '#FF0000': '#FFF'} />
                  )}
              </View>
          </TouchableOpacity>
          <TouchableOpacity style={{paddingBottom: 5}} onPress={()=>{
            if(alunos.FALTAS === 0){
              alert('Não existe Falta');
              return
            }
            setLoading(alunos.ITEM);
            fetchDataAlunosFaltas(alunos.RA, alunos.NOME, 'JUST');
            Aviso('','Pesquisando Faltas, aguarde...', 'success', 2000);                 
          }}>
              <View style={{padding: 5, backgroundColor: '#004b9e', borderRadius: 5, width: 80,textAlign: 'center'}}>
                {loading !== alunos.ITEM ? (
                  <Text style={{color: alunos.FALTASCOMJUST > 0 ?  '#FF0000': '#FFF', fontWeight: alunos.FALTASCOMJUST > 0 ? 'bold': 'normal'}}>Falta: {dias >= 0 ? alunos.FALTASCOMJUST : ''}</Text>
                  ) : (
                    <ActivityIndicator size={15} style={{position:'relative', top: 2}} color={alunos.FALTASCOMJUST > 0 ?  '#FF0000': '#FFF'} />
                  )}
              </View>
          </TouchableOpacity>
          </ScrollView>
        </View>
      )}
      </View>
    )
  }

  function validarfiltro(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilter(v);
  }

  function getalunosfaltas({ item: alunosfaltas }) {
    return (
      <View style={{}}>
        <View style={{ flexDirection: 'row', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
          <View style={{ width: 50, left: 5 }}><Text style={{}}>{alunosfaltas.ITEM}</Text></View>
          <View style={{ width: 100 }}><Text style={{}}>{alunosfaltas.DATA}</Text></View>
          <View style={{ width: 200 }}><Text style={{}}>{alunosfaltas.NOME}</Text></View> 
          {valuejust ? (          
              <View style={{ width: 200 }}><Text style={{}}>{alunosfaltas.JUSTIFICATIVA}</Text></View>
          ) : null}
        </View>
      </View>
    )
  }

  const fetchDataAlunosFaltas = async (ra, nomealuno, justificada) => {
      try {
          setNomeAluno(nomealuno);
          var URLFALTAS;

          let infde = '';
          let infate = '';
          let mesinf = '';
          let anoinf = '';
          let seldata = false;
          if (mesanode!='' && mesanoate!=''){
              infde  = mesanode.split('/');
              infate = mesanoate.split('/'); 
              //mesinf = `${infde[1]},${infate[1]}`; 
              //anoinf = `${infde[2]},${infate[2]}`; 
              mesinf = `${infde[2]}-${infde[1]}-${infde[0]}`; 
              anoinf = `${infate[2]}-${infate[1]}-${infate[0]}`; 
              seldata = true;
          }

          let data = new Date();
          let mesatual = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
          let anoatual = data.getFullYear();

          if(justificada == 'NJUST'){
            URLFALTAS = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/alunosfaltas2/${user.codcgo}/${ra}/${mesinf!='' ? mesinf : mesatual}/${anoinf!='' ? anoinf : anoatual}/${seldata}`;
            //console.log('NJUST: '+URLFALTAS);
          }else{
            URLFALTAS = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/alunosfaltasjustificada2/${user.codcgo}/${ra}/${mesinf!='' ? mesinf : mesatual}/${anoinf!='' ? anoinf : anoatual}/${seldata}`;
            //console.log('JUST: '+URLFALTAS);
          }

          //alert(URLFALTAS);
          //return false;

          await fetch(URLFALTAS, {
                  timeout: 5000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  if (responseJson.validtoken==false){
                      alert(responseJson.message); 
                      setLoading(-1);
                      signOut();
                      return
                  }
                    //alert(JSON.stringify(responseJson.data[0].JUSTIFICATIVA));
                    setValueJust(responseJson.data[0].JUSTIFICATIVA==null ? false : true)
                    setAlunosFaltas(responseJson.data);
                    setLoading(-1);
                    setTip(true);
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000);  
                  setLoading(-1);
              })
              .finally(function () {
                setLoading(-1);
              });  
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);          //setLoadingFaltas(false); 
          setLoading(-1);
      } finally {{
        setLoading(-1);
      }}
  }

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        //fetchData();
        let data = new Date();
        let mesatual = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
        let anoatual = data.getFullYear();
        setMes(mesatual);
        setAno(anoatual);        
        if(alunos == '')
        BuscarAlunos();      
      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
     if(!loadingalunos)
     setTimeOutLoading(0);

     if(loadingalunos)
     setTimeout(() => {
      setTimeOutLoading((parseInt(timeoutloading)+1))
    }, 1000);
  }, [loadingalunos, timeoutloading]);


  //VIEW DO FORM MODAL - TIPO
  const ItemViewTipo = ({ item }) => {
    return (
        <Text style={styles.itemStyle} onPress={() => getItemTipo(item)}>
            {item.name}
        </Text>
    );
  }

  //GET DO FORM MODAL - TIPO
  const getItemTipo = (item) => {
    setNomeTipoFiltro(item.filtro.trim());
  };

  
  function validarfiltrode(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2/$3")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setMesAnoDe(v);
  }
  function validarfiltroate(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2/$3")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setMesAnoAte(v);
  }


  return (
    <ImageBackground>      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={{flexDirection: 'column', left: Device.osName=='Windows' ? null : -70, overflow:'hidden'}}>
            <Card style={[styles.card,{marginTop: 10, height: 100, zIndex: 5}]} elevation={5} mode={'elevated'}>
            <View>
              <Text style={{
                color: '#4F4F4F',
                fontSize: Device.osName=='Windows'? 20 : 11,
                fontFamily: 'Time New Roman',
                fontWeight: 'bold',
              }}>:: ESTAGIARIOS / APRENDIZES ::</Text>
              
            </View>
            <View style={{flexDirection: 'column', position: 'absolute', right: 620}}>
            <View style={{flexDirection: 'row'}}>
                    <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, color: '#004b9e'}}> De:</Text>
                    <TextInput
                      placeholder={`00/00/0000`}
                      style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 80, fontSize: Device.osName=='Windows' ? 15 : 9,}}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='numeric'
                      value={mesanode}
                      maxLength={10}
                      onChangeText={(item) => {validarfiltrode(item)}}
                    />
              </View>
              <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'flex-start'}}>
                    <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, color: '#004b9e'}}>Até:</Text>
                    <TextInput
                      placeholder={`00/00/0000`}
                      style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 80, fontSize: Device.osName=='Windows' ? 15 : 9,}}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='numeric'
                      value={mesanoate}
                      maxLength={10}
                      onChangeText={(item) => {validarfiltroate(item)}}
                    />
              </View>
            </View>
              <View style={{ flex: 1, width: '100%' }}>
                <Text style={{ position: 'relative',top: Device.osName=='Windows' ? 40 : 5, fontWeight: 'bold', alignSelf: 'flex-start', fontSize:Device.osName=='Windows'? 13 : 10 }}>{`EMPRESA: ${user?.nomefantasia ? user?.nomefantasia : user?.nome }`}</Text>
              </View>
              <View style={{ alignSelf:  'flex-end', top: -30, right: 70,  }}>
                {Device.osName=='Windows' ?(
                  <RadioButton.Group onValueChange={newValue => setAlunosAtivo(newValue)} value={alunosativos}>
                        <View style={{ top: Device.osName=='Windows'? 0 : 5,flexDirection: Device.osName=='Windows'? 'row' : 'column', alignItems: Device.osName=='Windows'? 'center' : 'flex-start', justifyContent: 'center', width: Device.osName=='Windows'? 300 : 100,  borderTopEndRadius: 5, right: Device.osName=='Windows'? 200 : -200}}>
                            <View style={{flex:1, flexDirection:'row', alignItems:'center', justifyContent: 'center', top: Device.osName=='Windows'? 0: 2,paddingRight: Device.osName=='Windows'? 100 : 0}}>
                                <RadioButton value="S" />
                                <TouchableOpacity onPress={() => setAlunosAtivo('S')}>
                                    <Text style={{fontSize: Device.osName=='Windows'? 15 : 10}}>Ativo(s)</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{flexDirection:'row', alignItems: 'center',justifyContent: 'center', paddingRight: Device.osName=='Windows'? 50 : 0, top: Device.osName=='Windows'? 0 : -10}}>
                                <RadioButton value="N" />
                                <TouchableOpacity onPress={() => setAlunosAtivo('N')}>
                                  <Text style={{fontSize: Device.osName=='Windows'? 15 : 10}}>Finalizado(s)</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </RadioButton.Group> 
                    ) : null}
                    <View style={{ position: 'relative',flexDirection: 'row', width: Device.osName=='Windows' ? '100%':'70%', left: Device.osName=='Windows' ? -220 : 20, top: Device.osName=='Windows' ? '' : 50, }}>
                      {Device.osName=='Windows' ? (
                      <>                       
                      <AutocompleteDropdown
                              ref={searchRefTipo}
                              controller={controller => {
                                  dropdownControllertipo.current = controller
                              }}
                              onOpenSuggestionsList={onOpenSuggestionsListTipo}
                              ChevronIconComponent={<Feather name="chevron-down" size={Device.osName=='Windows'?20 :10} style={{position: 'relative', right:  Device.osName=='Windows' ? 20: 10, top: Device.osName=='Windows' ? 0: -5, color:'red',}} color="#383b42" />}
                              ClearIconComponent={<Feather name="x-circle" size={Device.osName=='Windows'?20 :10}  style={{position: 'relative', right:  Device.osName=='Windows' ? 30: 20, top:  Device.osName=='Windows' ? 0: -5}} color="#FF0000" />}                                                                                                    
                              clearOnFocus={true}
                              closeOnBlur={false}
                              closeOnSubmit={false}
                              initialValue={{ id: 0}} // or just '2'
                              position='absolute'
                              debounce={600}
                              onSelectItem={item => {
                                  if(item?.name){
                                      getItemTipo(item)
                                  }
                              }}
                              onFocus={() => {                                                            
                                      setNomeTipoFiltro(null)
                              }}
                              onClear={() => {
                                      setNomeTipoFiltro(null)
                              }}
                              suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
                              dataSet={tipovalue}
                              textInputProps={{
                                  placeholder: 'Tipo de Curso',
                                  autoCorrect: false,
                                  autoCapitalize: 'none',
                                  style: {
                                      borderRadius: 5,
                                      color: '#000',                                                          
                                      backgroundColor: '#fff',
                                      fontSize: 10,
                                      height: Device.osName=='Windows' ? 30 : 20,                                                                            
                                  }}}
                                  inputContainerStyle={{
                                      borderRadius: 5,
                                      color:'#000',
                                      width: Device.osName=='Windows' ? 200 : 150,
                                      height: 20,
                                  }}
                                  containerStyle={{ flexGrow: 1, flexShrink: 1, }}
                                  renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, width: '100%'}}>{item.name}</Text>}
                                  inputHeight={30}
                                  EmptyResultComponent={<Text style={{ padding: 10, fontSize: Device.osName=='Windows' ? 15 : 12, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                  useFilter={false}
                                  editable={true}                                  
                          />
                          
                          <View style={{position: 'absolute', right: Device.osName=='Windows'?  -20 : -20, padding: 5, borderRadius:5,width: Device.osName=='Windows'? 100 : 25, height: Device.osName=='Windows'? 30:20, backgroundColor: '#004b9e',top: Device.osName=='Windows'? 3 : 2, alignItems: 'center',justifyContent: 'center'}}>
                              <TouchableOpacity
                                style={{flexDirection: 'row',}}
                                onPress={() => { 
                                  //fetchData();
                                  
                                  let mesanode2 = mesanode?.split('/')?? '';
                                  let mesanoate2 = mesanoate?.split('/')?? '';

                                  if (mesanode2!='' && mesanoate2!='') {
                                      mesanode2 = mesanode2[2]+'-'+mesanode2[1]+'-'+mesanode2[0];
                                      mesanoate2 = mesanoate2[2]+'-'+mesanoate2[1]+'-'+mesanoate2[0];
                                  }
                                  
                                  //alert(`Datas: De:${mesanode2}, ate: ${mesanoate2}`)
                                  BuscarAlunos(1, mesanode2?? '', mesanoate2?? '');
                                }}
                              >
                                <Icon name="search" size={Device.osName=='Windows'? 20 : 10} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 1}}/>
                                <Text style={{fontWeight: 'bold', color: '#FFD700'}}>{Device.osName=='Windows'? `BUSCAR`: ''}</Text>
                              </TouchableOpacity>
                          </View>
                          </>
                          ) : null}
                          <View style={{flexDirection: Device.osName=='Windows' ? 'column' : 'row', position:'relative', right: Device.osName=='Windows'? -200 : -45,top: -30}}>
                              {Device.osName!='Windows' ? (
                                  <View style={{position: 'relative',left: Device.osName=='Windows' ? null : -25, padding: 5, borderRadius:5, width: Device.osName=='Windows'? 120 : 70, height: Device.osName=='Windows'? 30:20, top: Device.osName=='Windows'? -3 : 33, backgroundColor: (alunosativos==='S' && alunos.length > 0) ? '#004b9e' : '#A9A9A9', alignItems: 'center', justifyContent: 'center'}}>
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 
                                            setModalVisibleFiltro(!modalVisibleFiltro);
                                        }}
                                      >                                        
                                            <Icon name="search" size={Device.osName=='Windows'? 15 : 15} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 5}}/>                                      
                                            <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 12 : 10, color: '#FFD700', top: Device.osName=='Windows' ? null : 2, position: 'relative'}}>FILTRO</Text>
                                      </TouchableOpacity>
                                  </View>
                              ) : null}
                              <View style={{position: 'relative',padding: 5, left: Device.osName=='Windows' ? null: -10, borderRadius:5, width: Device.osName=='Windows'? 120 : 80, height: Device.osName=='Windows'? 30:20, top: Device.osName=='Windows'? -3 : 33, backgroundColor: (alunosativos==='S' && alunos.length > 0) ? '#004b9e' : '#A9A9A9', alignItems: 'center', justifyContent: 'center'}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => { 
                                      if(alunosativos==='S' && alunos.length > 0)
                                      navigation.navigate('AlunosFaltas');
                                    }}
                                  >                                    
                                        <Icon name="print" size={Device.osName=='Windows'? 15 : 15} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 5}}/>                                   
                                        <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 12 : 10, color: '#FFD700', top: Device.osName=='Windows' ? null : 2, position: 'relative'}}>{Device.osName=='Windows' ? `FREQUÊNCIA` :`FREQ.`}</Text>
                                  </TouchableOpacity>
                              </View>
                              <View style={{ padding: 5, marginLeft: Device.osName=='Windows' ? null: 8, borderRadius:5,top: 5, width: Device.osName=='Windows'? 120 : 80, height: Device.osName=='Windows'? 30:20, top: Device.osName=='Windows'? 5 : 33, backgroundColor: (alunosativos==='S' && alunos.length > 0) ? '#004b9e' : '#A9A9A9', alignItems: 'center', justifyContent: 'center'}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => {
                                          navigation.navigate('AlunosNotas');
                                    }}
                                  >                                    
                                    <Icon name="print" size={Device.osName=='Windows'? 15 : 15} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 5}}/>                                     
                                    <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 12 : 11,top: Device.osName=='Windows' ? null : 2, position: 'relative', color: '#FFD700'}}>{Device.osName=='Windows' ? `NOTAS/MÉDIA` : `NOT./MÉD.`}</Text>
                                  </TouchableOpacity>
                              </View>
                          </View>
                  </View>  
                </View>
            </Card>
            <Card style={[styles.card,{marginTop: 10, height: /* Device.osName=='Windows' ? 500 : */ height*0.73}]} elevation={5} mode={'elevated'}>
              <ScrollView style={{height: '100%'}}>   
              <View style={{ flex: 1, width: '100%' }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>MATRICULADOS:</Text>
              </View>
                                                                                                     
              <View style={{width: '100%', alignItems: 'flex-start', marginTop: 1 }}>
                {loadingalunos ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={Device.osName=='Windows' ? 50 : 20} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: Device.osName=='Windows' ? 15 : 11}}>{`Buscando as Informações...\nTempo: ${timeoutloading}s`}</Text>
                    {/* <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Tempo: ${timeoutloading}`}</Text> */}
                  </View>  
                ) : (
                  <>
                   {Device.osName=='Windows' ? (
                    <View style={{ width: width * 0.8, paddingTop: 0, flexDirection: 'row', /* height: 40, */ }}>
                      {/* <View style={{ width: 50, left: 5 }}><Text style={{fontWeight: 'bold'}}>#</Text></View> */}
                      <View style={{ width: 70 }}><Text style={{fontWeight: 'bold'}}>RA</Text></View>
                      <View style={{ width: 150 }}><Text style={{fontWeight: 'bold'}}>NOME</Text></View>
                      {user.adminti==1 ? (
                      <View style={{ width: 150 }}><Text style={{fontWeight: 'bold'}}>RESP. FINANC.</Text></View>
                      ):null}
                      <View style={{ width: 50 }}><Text style={{fontWeight: 'bold'}}>ATIVO</Text></View>
                      <View style={{ width: 120 }}><Text style={{fontWeight: 'bold'}}>TIPO</Text></View>
                      <View style={{ width: 130 }}><Text style={{fontWeight: 'bold'}}>CURSO</Text></View>
                      <View style={{ width: 80 }}><Text style={{fontWeight: 'bold'}}>INÍCIO</Text></View>
                      <View style={{ width: 80 }}><Text style={{fontWeight: 'bold'}}>FIM</Text></View>
                      <View style={{ width: 90 }}><Text style={{fontWeight: 'bold'}}>RESTANTE</Text></View>
                      <View style={{ width: 80 }}><Text style={{fontWeight: 'bold', color:'red'}}>{`FALTAS\nNÃO JUST.`}</Text></View>
                      <View style={{ width: 80 }}><Text style={{fontWeight: 'bold', color:'green'}}>{`FALTAS\nJUSTIF.`}</Text></View>
                      
                    </View>    
                    ):null}             
                    <FlatList
                      data={alunos}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getalunos(item)}
                    />  
                  </>
                )}                                
              </View>  
              </ScrollView> 
              {!loadingalunos && (Device.osName=='Windows' && totalpag > 20) ? (
                <View style={{flexDirection: 'row',alignItems: 'center', justifyContent: 'center', height: 50, width: '100%',}}>
                   <TouchableOpacity
                    onPress={() => {
                      
                      //fetchData(1);
                      BuscarAlunos(1);
                    }}>
                    <Icon name="step-backward" size={25} color="#000" style={{padding: 10, marginRight: 50}}/>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      //fetchData((parseInt(pagnumber) - parseInt(RowspPage)) < 1 ? 1 : parseInt(pagnumber) - parseInt(RowspPage));
                      BuscarAlunos((parseInt(pagnumber) - parseInt(RowspPage)) < 1 ? 1 : parseInt(pagnumber) - parseInt(RowspPage));
                    }}>
                    <Icon name="outdent" size={25} color="#000" style={{padding: 10,  marginRight: 50}}/>
                  </TouchableOpacity>
                  
                    <Text style={{padding: 10,  marginRight: 50, fontWeight: 'bold'}}>{pagnumber} a {(parseInt(pagnumber) + (parseInt( RowspPage) -1)) > totalpag ? totalpag : parseInt(pagnumber) + (parseInt( RowspPage)-1)}  de {totalpag}</Text>
                  
                  <TouchableOpacity
                    onPress={() => {                      
                      //fetchData((parseInt(pagnumber) + parseInt(RowspPage))>totalpag ? parseInt(pagnumber) : parseInt(pagnumber) + parseInt(RowspPage));
                      BuscarAlunos((parseInt(pagnumber) + parseInt(RowspPage))>totalpag ? parseInt(pagnumber) : parseInt(pagnumber) + parseInt(RowspPage));
                    }}>
                    <Icon name="indent" size={25} color="#000" style={{padding: 10}}/>
                  </TouchableOpacity>     
                  <TouchableOpacity
                    onPress={() => {                      
                      //fetchData(parseInt(totalpag) - ( parseInt(RowspPage) - 1));
                      BuscarAlunos(parseInt(totalpag) - ( parseInt(RowspPage) - 1));
                    }}>
                    <Icon name="step-forward" size={25} color="#000" style={{padding: 10}}/>
                  </TouchableOpacity> 
              </View>
            ):(<></>)}
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -0,right: Device.osName=='Windows'? '': 10,}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
            </View>
        </View>
        <Modal animationType='slide' transparent={true} visible={modalVisibleFiltro}>
                    <ScrollView>
                    <View style={styles.viewmodal}>
                      <Card style={[styles.card,{width: width *0.9,height: 170}]} elevation={5} mode={'elevated'}>                       
                        <Text style={{fontSize: 15, fontWeight: 'bold'}}>Selecione o Tipo de Curso:</Text>
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 10, width: '90%'}}>
                            <AutocompleteDropdown
                                  ref={searchRefTipo}
                                  controller={controller => {
                                      dropdownControllertipo.current = controller
                                  }}
                                  onOpenSuggestionsList={onOpenSuggestionsListTipo}
                                  ChevronIconComponent={<Feather name="chevron-down" size={Device.osName=='Windows'?20 :20} style={{position: 'relative', right:  Device.osName=='Windows' ? 20: 30, top: Device.osName=='Windows' ? 0: 0, color:'red',}} color="#383b42" />}
                                  ClearIconComponent={<Feather name="x-circle" size={Device.osName=='Windows'?20 :20}  style={{position: 'relative', right:  Device.osName=='Windows' ? 30: 30, top:  Device.osName=='Windows' ? 0: 0}} color="#FF0000" />}                                                                                                    
                                  clearOnFocus={true}
                                  closeOnBlur={false}
                                  closeOnSubmit={false}
                                  initialValue={{ id: 0}} // or just '2'
                                  position='absolute'
                                  debounce={600}
                                  onSelectItem={item => {
                                      if(item?.name){
                                          getItemTipo(item)
                                      }
                                  }}
                                  onFocus={() => {                                                            
                                          setNomeTipoFiltro(null)
                                  }}
                                  onClear={() => {
                                          setNomeTipoFiltro(null)
                                  }}
                                  suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
                                  dataSet={tipovalue}
                                  textInputProps={{
                                      placeholder: 'Tipo de Curso',
                                      autoCorrect: false,
                                      autoCapitalize: 'none',
                                      style: {
                                          borderRadius: 5,
                                          color: '#000',                                                          
                                          backgroundColor: '#fff',
                                          fontSize: 20,
                                          height: Device.osName=='Windows' ? 30 : 30,                                                                            
                                      }}}
                                      inputContainerStyle={{
                                          borderRadius: 5,
                                          color:'#000',
                                          width: Device.osName=='Windows' ? 200 : '100%',
                                          height: 30,
                                      }}
                                      containerStyle={{ flexGrow: 1, flexShrink: 1, }}
                                      renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, width: '100%'}}>{item.name}</Text>}
                                      inputHeight={30}
                                      EmptyResultComponent={<Text style={{ padding: 10, fontSize: Device.osName=='Windows' ? 15 : 12, fontWeight: 'bold', color: '#FF0000'}}>Nada Encontrado!</Text>}
                                      useFilter={false}
                                      editable={true}                                  
                              /> 
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 10,bottom: -10, zIndex: -10}}>
                              <View style={{padding: 5, marginRight: 30, borderRadius:5,width: Device.osName=='Windows'? 100 : 100, height: Device.osName=='Windows'? 30:30, backgroundColor: '#004b9e',/* top: Device.osName=='Windows'? 3 : 2 ,*/ alignItems: 'center',justifyContent: 'center'}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => { 
                                      //fetchData(); 
                                      setModalVisibleFiltro(false);
                                    }}
                                  >
                                    <Icon name="window-close" size={Device.osName=='Windows'? 20 : 15} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 10}}/>
                                    <Text style={{fontWeight: 'bold', color: '#FFD700', position: 'relative', top: 0}}>FECHAR</Text>
                                  </TouchableOpacity>
                              </View> 
                              <View style={{padding: 5, borderRadius:5,width: Device.osName=='Windows'? 100 : 100, height: Device.osName=='Windows'? 30:30, backgroundColor: '#004b9e',/* top: Device.osName=='Windows'? 3 : 2 ,*/ alignItems: 'center',justifyContent: 'center'}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => { 
                                      if(nometipofiltro=='' || nometipofiltro==null){
                                        return false;  
                                      }
                                      BuscarAlunos();
                                      setModalVisibleFiltro(false);
                                    }}
                                  >
                                    <Icon name="search" size={Device.osName=='Windows'? 20 : 15} color="#FFD700" style={{marginRight: Device.osName=='Windows'? 10 : 10}}/>
                                    <Text style={{fontWeight: 'bold', color: '#FFD700', position: 'relative', top: 0}}>BUSCAR</Text>
                                  </TouchableOpacity>
                              </View> 
                        </View>
                      </Card>                                              
                    </View>
                    </ScrollView>
        </Modal>
        <Tooltip
              isVisible={showTip}
              content={
                <View style={{backgroundColor:'#eee', /* alignItems: 'center', */ justifyContent: 'center', width: !valuejust ? 400 : 500, height: '100%', }}>
                    
                          <View>
                            <Text style={{fontWeight: 'bold'}}>{`ALUNO(A): ${nomedoaluno}`}</Text>
                            <Text style={{fontWeight: 'bold'}}>DETALHES FALTA(S):</Text>
                          </View>
                          <View style={{ width: width * 0.83, paddingTop: 0, flexDirection: 'row', /* height: 40, */ }}>
                            <View style={{ width: 50, left: 5 }}><Text style={{fontWeight: 'bold'}}>#</Text></View>
                            <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>DATA</Text></View>
                            <View style={{ width: 200 }}><Text style={{fontWeight: 'bold'}}>DISCIPLINA</Text></View> 
                            {valuejust ? (                        
                                <View style={{ width: 200 }}><Text style={{fontWeight: 'bold'}}>JUSTIFICATIVA</Text></View>                        
                            ) : null}
                          </View>                      
                            <FlatList
                                data={alunosfaltas}
                                style={{ width: '100%', }}
                                keyExtractor={(item) => item.ITEM.toString()}
                                showsVerticalScrollIndicator={true}
                                //showsHorizontalScrollIndicator={true}
                                renderItem={(item) => getalunosfaltas(item)}
                            />
                      
                </View>
              }
              placement="top"
              onClose={() => {
                setTip(false);
                setNomeAluno();
                setAlunosFaltas();
              }}
              useInteractionManager={true} 
              topAdjustment={0}
      />
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: Device.osName=='Windows'? width*0.86 : width*0.7,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //left: Device.osName=='Windows'? 0 : 0
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },
  viewmodal: {
    backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
  },

});
