
/*
    Site Buscar Icons: https://oblador.github.io/react-native-vector-icons/
    Site de Fonts: https://fonts.google.com/
*/

import React, { useContext } from 'react'
import { Dimensions, StatusBar } from 'react-native'
import Home from '../src/screen/home'
import { AuthContext } from '../contexts/auth';

const {width, height} = Dimensions.get('window');

export default function Initial() {
    const { signOut } = useContext(AuthContext);
    return (       
            <>
                <StatusBar backgroundColor="#131313" barStyle="light-content" />            
                <Home />
            </>
    )
}


