
/*
    Site Buscar Icons: https://oblador.github.io/react-native-vector-icons/
    Site de Fonts: https://fonts.google.com/
*/
import React, {useContext, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from '../contexts/auth';
import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';
import moment from 'moment';
import { UtilContext } from '../contexts/utilidade';

function Routes(){
    const { signUp, signOut, signed, SetarDev,SetarInfoManut } = useContext(AuthContext)
    const { newpwd } = useContext(UtilContext);

    useEffect(() => {
        loginIn()        
    }, [])

    async function loginIn(){
        let bdintlogin = await AsyncStorage.getItem('bdlogin');  
        let parsed = JSON.parse(bdintlogin);
        
        if(parsed){
            let date = new Date().getDate();
            let month = new Date().getMonth() + 1;
            let year = new Date().getFullYear();
            let dataagora = `${month}/${date}/${year}`
            //let dataagora = `02/28/2023`
            const date2 = new Date(dataagora);
            const date1 = new Date(parsed.expired);

            let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
            let dias = moment.duration(diff).asDays();
            //console.log('date1:', date1)
            //console.log('date2:', date2)
            //console.log('Dias exp:', dias)
            if(parsed && dias >= 0){
                //SetarDev(parsed.login=='antonio.segundo' ? true : false)
                signUp(parsed.codcgo, parsed.nomefantasia, parsed.nome, parsed.cgccfo, parsed.expired, parsed.token, parsed.admin, parsed.adminti)
                //signUp(codcgo,        nomefantasia,        nome,        cgccfo,        expired,        token,        admin, adminti, aceitetermo, dataaceitetermo)
                                
                /* let StsManut = await AsyncStorage.getItem('stsManut');  
                let parsed_manut = JSON.parse(StsManut);
                
                await SetarInfoManut(parsed_manut.manutencao) */
                
            }else{
                if(dias < 0){
                    alert('Sua Sessão expirou faça login novamente!');
                }
                
                signOut();
            }
        }
        
        
        
    }
    return(
            (signed && !newpwd) ? <AppRoutes/> : <AuthRoutes/>            
        )
}
export default Routes