import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, ImageBackground, TouchableOpacity, FlatList, Linking, ActivityIndicator, Image} from 'react-native'
import { useNavigation, useIsFocused  } from '@react-navigation/native';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card,  } from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
const dotenv = require('../../../app.config');
import Base64Url from 'base64-url';
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');

export default function App({route}) {
  const { user, signOut } = useContext(AuthContext); 
  const { isaluno, setIsAluno, nomealuno, iscontrato, setIsContrato, setRaAlunoReturn,setNomeAluno,
          setUserLogin, setUserPwd, setUserPwdNew, accessdev /*, dadoscontrato */ } = useContext(UtilContext);
  const navigation = useNavigation(); 
  const [dadoscontrato, setDadosContrato] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingtaxa, setIsLoadingTaxa] = useState(false);
  const [taxa, setTaxa] = useState('');
  
  
  /* dadoscontrato.splice(0);
  const contratoalunos = route.params.contratos.map(function(item){    
    dadoscontrato.push({
          ITEM: item.ITEM,
          COUNT: item.COUNT, 
          RA: item.RA,
          IDPERLET: item.IDPERLET,
          CODCONTRATO: item.CODCONTRATO,
          ALUNO: item.ALUNO,
          ASSINADO: item.ASSINADO,
          CURSO: item.CURSO,
          TURNO: item.TURNO,
          TURMA: item.TURMA,
          ANOLETIVO: item.ANOLETIVO,
          CODPLANOPGTO: item.CODPLANOPGTO, 
          CODCURSO: item.CODCURSO, 
          CODGRADE: item.CODGRADE, 
          CODTURNO: item.CODTURNO,
    }) 
  }) */  

  function getcontratos({ item: contratos }) {
    return (
      
      <View style={{ width: width * 0.8, paddingTop: 10, flexDirection: 'row', height: Device.osName=='Windows' ? 40 : null, top: -5, borderBottomColor: '#000', borderBottomWidth: 1.5, }}>
        {Device.osName=='Windows' ? (
        <>
            <View style={{ width: 50 }}><Text style={{}}>{contratos.TBCCONTRATO}</Text></View>
            <View style={{ width: 100 }}><Text style={{}}>{contratos.CODCONTRATO}</Text></View>
            <View style={{ width: 100 }}><Text style={{}}>{contratos.RA}</Text></View>

            {contratos.TBCCONTRATO==null || contratos.CODPLANOPGTO==null? (
              <>
                {contratos.TBCCONTRATO==null || contratos.CODPLANOPGTO==null ? (
                  <View style={{ width: 500 }}><Text style={{color:'#FF0000'}}>CADASTRO INCOMPLETO! PROCURE NOSSO TIME COMERCIAL.</Text></View>
                  ):null}
                {/* {contratos.CODPLANOPGTO==null? (
                <View style={{ width: 300 }}><Text style={{color:'#FF0000'}}>PLANO PGTO: PENDENTE</Text></View>
                ):null} */}

              </>
            ):null}

            {contratos.TBCCONTRATO !=null && contratos.CODPLANOPGTO !=null? (           
            <> 
                <View style={{ width: 75}}><Text style={{}}>{contratos.TURNO}</Text></View>
                <View style={{ width: 90}}><Text style={{}}>{contratos.TURMA}</Text></View>
                <View style={{ width: 80 }}><Text style={{}}>{contratos.ASSINADO=='N' ? 'NÃO': 'SIM'}</Text></View>
                <View style={{ width: 300 }}><Text style={{}}>{contratos.CURSO}</Text></View>
                <View style={{ width: 150, flexDirection: 'row', top: -10, left: 15 }}>
                  <TouchableOpacity onPress={() => {
                    
                    if(contratos.QT1PARC == 0){
                      /* alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## Taxa Matrícula ##', // This is a title
                        `Não foi encontrado Taxa`, // This is a alert message
                        {
                        textConfirm: 'OK',      // Label of button confirm
                        //textCancel: '',     // Label of button cancel
                        onCancel: () => {{}}, // Call your cancel function 
                        onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                        }
                      )  */
                      return false;
                    }else if(contratos.ASSINADO!='S'){
                      //alert('Antes de Imprimir o boleto taxa de matrícula para pagamento se faz necessário assinar o contrato!\nClique em "Abrir o Contrato" leia com atenção e assine concordando, em seguida tente imprimir o boleto taxa de matrícula novamente.');
                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## Taxa Matrícula ##', // This is a title
                        //`Antes de Imprimir o boleto taxa de matrícula para pagamento se faz necessário assinar o contrato!\nClique em "Abrir o Contrato" leia com atenção e assine concordando, em seguida tente imprimir o boleto taxa de matrícula novamente.`, // This is a alert message
                        `O Boleto ficará disponível para pagamento somente após a assinatura do Contrato.\nClique em Abrir Contrato e realize a assinatura.`,
                        {
                        textConfirm: 'OK',                // Label of button confirm
                        //textCancel: '',                 // Label of button cancel
                        onCancel: () => {{}},             // Call your cancel function 
                        onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                        }
                      )
                      return false
                    }
                    setTaxa(contratos.CODCONTRATO);
                    buscadadostaxa(contratos.RA,contratos.CODCONTRATO);
                    //alert(contratos.RA+","+contratos.CODCONTRATO);
                    
                  }}>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      {taxa==contratos.CODCONTRATO ? (
                        <ActivityIndicator size={23} color='#004b9e' style={{ padding: 10 }} />
                      ) : (
                        <Icon name={(contratos.QT1PARC== 0 /* && contratos.ASSINADO=='S' */) ? "check-circle" : (contratos.BOLETOPAGO =='NÃO') ? "file-powerpoint" : (contratos.ASSINADO=='N') ? "file-excel": "file-invoice-dollar" } size={23} color={(contratos.QT1PARC == 0 /* && contratos.BOLETOPAGO =='SIM' */) ? "green" : (contratos.ASSINADO=='S' && (contratos?.BOLETOPAGO =='SIM' || contratos?.BOLETOPAGO ==null)) ? "#004b9e" : "#FF0000"} style={{ padding: 10 }} />                             
                      )}
                    </View>
                  </TouchableOpacity>            
                </View> 
                <View style={{ width: 150, flexDirection: 'row', top: -10, left: 20 }}>
                  <TouchableOpacity onPress={() => {
                    setIsContrato(true);
                    navigation.navigate('ContratosAuto', {
                                            count: contratos.COUNT,
                                            codcoligada: contratos.CODCOLIGADA,
                                            tbccontrato: contratos.TBCCONTRATO, 
                                            item: contratos.ITEM, 
                                            aluno: contratos.ALUNO , 
                                            codplanopgto: contratos.CODPLANOPGTO, 
                                            ra: contratos.RA, 
                                            idperlet: contratos.IDPERLET, 
                                            codcontrato: contratos.CODCONTRATO, 
                                            codcurso: contratos.CODCURSO, 
                                            codgrade: contratos.CODGRADE, 
                                            codturno: contratos.CODTURNO, 
                                            assinado: contratos.ASSINADO,
                                            dados: dadoscontrato, 
                                            visiblesalvar: true,
                                            txmat: contratos.QT1PARC == 0 ? false : true,
                                            cmdvoltar: false  });
                  }}>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <Icon name="file-signature" size={23} color={contratos.ASSINADO=='S' ? "#004b9e" : "#FF0000"} style={{ padding: 10 }} />              
                    </View>
                  </TouchableOpacity>            
                </View>
            </>
            ): null} 
        </>
        ) : (
          <View style={{flex:1,flexDirection:'column'}}>
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 10}}>#:</Text>
              <Text style={{fontSize: 13}}>{contratos.TBCCONTRATO}</Text>
            </View>
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 10}}>Contrato:</Text>
              <Text style={{fontSize: 13}}>{contratos.CODCONTRATO}</Text>
            </View>
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 47}}>RA:</Text>
              <Text style={{fontSize: 13}}>{contratos.RA}</Text>
            </View>
            {contratos.TBCCONTRATO==null || contratos.CODPLANOPGTO==null? (
                <>
                  {contratos.TBCCONTRATO==null? (
                    <View style={{ width: 300 }}><Text style={{color:'#FF0000'}}>MODALIDADE CURSO: PENDENTE</Text></View>
                    ):null}
                  {contratos.CODPLANOPGTO==null? (
                  <View style={{ width: 300 }}><Text style={{color:'#FF0000'}}>PLANO PGTO: PENDENTE</Text></View>
                  ):null}

                </>
              ):null}

            {contratos.TBCCONTRATO !=null || contratos.CODPLANOPGTO !=null? (           
            <> 
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 30}}>Turno:</Text>
              <Text style={{fontSize: 13}}>{contratos.TURNO}</Text>
              </View>
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 27}}>Turma:</Text>
              <Text style={{fontSize: 13}}>{contratos.TURMA}</Text>
            </View>
            <View style={{flexDirection:'row',}}>
              <Text style={{fontWeight: 'bold', marginRight: 10}}>Assinado:</Text>
              <Text style={{fontSize: 13}}>{contratos.ASSINADO=='N' ? 'NÃO': 'SIM'}</Text>
            </View>
            <View style={{flexDirection:'row', }}>
              <Text style={{fontWeight: 'bold', marginRight: 30}}>Curso:</Text>
              <Text style={{fontSize: 13}}>{contratos.CURSO}</Text>
            </View>
            <View style={{flexDirection: 'row', position:'relative', padding: 10, alignItems: 'center', justifyContent: 'space-between', width: '95%'}}>
              <TouchableOpacity onPress={() => {
                setIsContrato(true);
                navigation.navigate('ContratosAuto', {
                                        count: contratos.COUNT,
                                        codcoligada: contratos.CODCOLIGADA,
                                        tbccontrato: contratos.TBCCONTRATO, 
                                        item: contratos.ITEM, 
                                        aluno: contratos.ALUNO , 
                                        codplanopgto: contratos.CODPLANOPGTO, 
                                        ra: contratos.RA, 
                                        idperlet: contratos.IDPERLET, 
                                        codcontrato: contratos.CODCONTRATO, 
                                        codcurso: contratos.CODCURSO, 
                                        codgrade: contratos.CODGRADE, 
                                        codturno: contratos.CODTURNO, 
                                        assinado: contratos.ASSINADO,
                                        dados: dadoscontrato, 
                                        visiblesalvar: true,
                                        cmdvoltar: false  });
              }}>
                {/* <View style={{flexDirection: 'column', alignItems: 'center',}}>
                  <Icon name="file-signature" size={20} color="#004b9e" style={{ padding: 5 }} />
                  <Text style={{fontWeight:'bold',fontSize: 15, color:'#004b9e', }}>Abrir Contrato</Text>
                </View> */}
                <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <Icon name="file-signature" size={23} color={contratos.ASSINADO=='S' ? "#004b9e" : "#FF0000"} style={{ padding: 10 }} /> 
                  <Text style={{fontWeight:'bold',fontSize: 15, color:'#004b9e', }}>Abrir Contrato</Text>
                </View>
              </TouchableOpacity>   
              <TouchableOpacity onPress={() => {
                
                if(contratos.QT1PARC == 0){
                  /* alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## Taxa Matrícula ##', // This is a title
                    `Não foi encontrado Taxa Pendente!`, // This is a alert message
                    {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: '',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                    }
                  ) */
                  return false;
                }else if(contratos.ASSINADO!='S'){
                  //alert('Antes de Imprimir o boleto taxa de matrícula para pagamento se faz necessário assinar o contrato!\nClique em "Abrir o Contrato" leia com atenção e assine concordando, em seguida tente imprimir o boleto taxa de matrícula novamente.');
                  alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                    '## Taxa Matrícula ##', // This is a title
                    `Antes de Imprimir o boleto taxa de matrícula para pagamento se faz necessário assinar o contrato!\nClique em "Abrir o Contrato" leia com atenção e assine concordando, em seguida tente imprimir o boleto taxa de matrícula novamente.`, // This is a alert message
                    {
                    textConfirm: 'OK',      // Label of button confirm
                    //textCancel: '',     // Label of button cancel
                    onCancel: () => {{}}, // Call your cancel function 
                    onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                    }
                  )
                  return false
                }
                setTaxa(contratos.CODCONTRATO);
                buscadadostaxa(contratos.RA,contratos.CODCONTRATO);
                //alert(contratos.RA+","+contratos.CODCONTRATO);
                
              }}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>                 
                    <View style={{flexDirection: 'column', alignItems: 'center',/* a justifyContent: 'center' */}}>
                    {taxa==contratos.CODCONTRATO ? (
                        <ActivityIndicator size={23} color='#004b9e' style={{ padding: 10 }} />
                    ) : (
                      <Icon name={(contratos.QT1PARC== 0 /* && contratos.ASSINADO=='S' */) ? "check-circle" : (contratos.ASSINADO=='N') ? "file-excel": (contratos.BOLETOPAGO =='NÃO') ? "file-powerpoint" : "file-invoice-dollar" } size={23} color={(contratos.QT1PARC == 0 /* && contratos.BOLETOPAGO =='SIM' */) ? "green" : (contratos.ASSINADO=='S' && (contratos?.BOLETOPAGO =='SIM' || contratos?.BOLETOPAGO ==null)) ? "#004b9e" : "#FF0000"} style={{ padding: 10 }} />                             
                    )}
                      <Text style={{fontWeight:'bold',fontSize: 15, color:'#004b9e', }}>Taxa Matrícula</Text>
                    </View>
                    
                </View>
              </TouchableOpacity>          
            </View>
            </>
            ): null} 
        </View>
        )}       
      </View>
    )
  }
  
  const fetchData = async () => {  
    try {        
          setIsLoading(true);
          var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/contratocelaluno2`;
          //console.log(`RA DO ALUNO: ${route.params.contratos[0].RA}`);
          
          await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({
                      raaluno: route.params.raaluno?? route.params.contratos[0].RA,
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      //"x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {                
                //console.log('Reteurn: '+JSON.stringify(responseJson));
                if(responseJson.sucesso){

                  if (responseJson.validtoken==false){
                    alert(responseJson.message); 
                    setIsLoading(false);
                    return
                 }

                  setDadosContrato(responseJson.dados);
                  setRaAlunoReturn('');
                  
                  setIsLoading(false);
                }else{
                  alert("Erro_lstcontrato: " + JSON.stringify(responseJson.message));
                  setIsLoading(false);
                }            
              })
              .catch((error) => {
                setIsLoading(false);
                if(error.message === 'Failed to fetch'){
                    alert("Erro na Consulta com Servidor");                      
                  }else{
                    alert("Erro2: " + error.message);
                  }
                                                 
              })
              .finally(()=>{
                setIsLoading(false);
              })
        
    } catch (err) {
        alert(err.message);
    } finally {{}}
}
  
  const buscadadostaxa = async (value_ra, value_codcontrato) => {  
    try {        
          setIsLoadingTaxa(true);
          var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/taxamatricula`;
          
          await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({
                      raaluno: value_ra,
                      codcontrato: value_codcontrato
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      //"x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {                
                //console.log('Reteurn: '+JSON.stringify(responseJson));
                if(responseJson.sucesso && responseJson.qttaxa>0 ){
                  //.log(responseJson.dados);
                  //alert(JSON.stringify(responseJson.dados[0].DATAVENCIMENTO))
                  let valuehistorico = (responseJson.dados[0].HISTORICO.length >200) ? responseJson.dados[0].HISTORICO.slice(0,200)+", ..." : responseJson.dados[0].HISTORICO;
                  let dadosboleto = responseJson.dados[0].DATAVENCIMENTO+";"+responseJson.dados[0].DATAEMISSAO+";"+responseJson.dados[0].DATACRIACAO+";"+
                                    responseJson.dados[0].VALORBOLETO+";"+responseJson.dados[0].NOSSONUMERO+";"+responseJson.dados[0].NOME+";"+
                                    responseJson.dados[0].IDBOLETO+";"+responseJson.dados[0].CODIGOBARRA+";"+responseJson.dados[0].CODIGOBARRADIG+";"+                                 
                                    responseJson.dados[0].LOGRADOURO+";"+responseJson.dados[0].CIDADE+";"+responseJson.dados[0].UF+";"+
                                    responseJson.dados[0].CEP+";"+responseJson.dados[0].BAIRRO+";"+responseJson.dados[0].CPF+";"+valuehistorico;
                                    
                 
                   //alert(`'${(responseJson.dados[0].CODIGOBARRADIG).slice(0,3)}'`);
                   
                   const banco = (responseJson.dados[0].CODIGOBARRADIG.toString()).slice(0,3)=="237" ? "bradesco" : (responseJson.dados[0].CODIGOBARRADIG.toString()).slice(0,3)=="033" ? "santander" : ""
                   const nomebanco = banco?? null;

                   if(nomebanco==null || nomebanco ==""){
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                      '## Boleto ##', // This is a title
                      `Boleto Desconhecido, informe o suporte!`, // This is a alert message
                      {
                      textConfirm: 'OK',      // Label of button confirm
                      //textCancel: '',     // Label of button cancel
                      onCancel: () => {{}}, // Call your cancel function 
                      onConfirm: () => {{setTaxa('');}} // Call your confirm function 
                      }
                    )
                    return false;
                   }

                  Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/${nomebanco}.php?token=${Base64Url.encode(dadosboleto) }`); 

                  setTaxa('');
                  setIsLoadingTaxa(false);
                }else{
                  alert("ErroTaxa: " + responseJson.message);
                  setIsLoadingTaxa(false);
                  setTaxa('');
                }            
              })
              .catch((error) => {
                setIsLoadingTaxa(false);
                setTaxa('');
                if(error.message === 'Failed to fetch'){
                    alert("Erro na Consulta com Servidor");                      
                  }else{
                    alert("ErroTaxa2: " + error.message);
                  }
                                                 
              })
              .finally(()=>{
                setIsLoadingTaxa(false);
                setTaxa('');
              })
        
    } catch (err) {
        alert(err.message);
    } finally {{}}
}
  

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        fetchData();
      }
  }, [focus]);

 useEffect(() => {   // whenever you are in the current screen, it will be true vice versa  
  
}, []);

  return (
    <ImageBackground>      
      <View style={{ flexDirection: 'row', width: width, height: Device.osName=='Windows' ? null : height, backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        {Device.osName=='Windows' ? (
        <RouteMenu /> 
        ) : null} 

        <View style={{flexDirection: 'column', left: Device.osName=='Windows' ? null : 10}}>
            <Card style={[styles.card,{marginTop: 10, height: Device.osName=='Windows' ? 570 : height*0.9}]} elevation={5} mode={'elevated'}>
              <View style={{flexDirection: 'row'}}>
              {Device.osName=='Windows' ? null : (
              <View style={{ position: 'relative', width: 30, backgroundColor: '#FFF' , marginLeft: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
                <Image style={styles.logo} source={require('../../img/literatus2.png')} />
              </View>
              )}
                <Text style={{
                    color: '#4F4F4F',
                    fontSize: Device.osName=='Windows' ? 15 : 10,
                    fontFamily: 'Time New Roman',
                    fontWeight: 'bold',
                    marginBottom: 10,
                    alignSelf: 'center',
                    marginLeft: 20
              }}>{`:: CONTRATO(S) DE ${nomealuno!=''? nomealuno : 'NÃO DEFINIDO'}  ::`}</Text>              
             
             {Device.osName=='Windows' ? null : ( 
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ position: 'relative', borderRadius: 5, backgroundColor: '#004b9e', height: 25, width: 70, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## SAIR ##', // This is a title
                        `Deseja realmente sair?`,
                        {
                            textConfirm: 'SIM',      // Label of button confirm
                            textCancel: 'NÃO',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { {
                              setIsAluno(false);
                              //setUserLogin('');
                              setUserPwd('');
                              setUserPwdNew('');
                              setNomeAluno('');
                              //AtivaMenu('INICIO');
                              navigation.navigate('Initial');
                              signOut() 
                            } } // Call your confirm function 
                        }
                    )
                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="door-open" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      SAIR
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
             )}
             </View>
              <ScrollView style={{height: height, marginTop: 10}}>                                                                                       
              <View style={{/* flex:1,  */width: '100%', alignItems: 'center', justifyContent: 'center'  /* marginTop: 1  */}}> 
                    <View style={{/* overflow:'scroll', */ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent:'center',/* height: 40, */ }}>
                        <View style={{width: width * 0.8,height: height*0.8,top: 2,borderRadius: 5, borderColor: '#ddd', borderWidth: 2, padding:5}}>
                                <ScrollView>
                                {Device.osName=='Windows' ? (
                                <View style={{ width: width * 0.8, paddingTop: 1, flexDirection: 'row', borderBottomColor: '#000', borderBottomWidth: 1.5 }}>
                                  <View style={{ width: 50}}><Text style={{ fontWeight: 'bold' }}>#</Text></View>
                                  <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>N°. Contrato</Text></View>
                                  <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Reg. Acad.</Text></View>
                                  {/* <View style={{ width: 200 }}><Text style={{ fontWeight: 'bold' }}>Aluno</Text></View> */}
                                  <View style={{ width: 75 }}><Text style={{ fontWeight: 'bold' }}>Turno</Text></View>
                                  <View style={{ width: 90 }}><Text style={{ fontWeight: 'bold' }}>Turma</Text></View>
                                  <View style={{ width: 80 }}><Text style={{ fontWeight: 'bold' }}>Assinado</Text></View>
                                  <View style={{ width: 300}}><Text style={{ fontWeight: 'bold' }}>Curso</Text></View>
                                  <View style={{ width: 150 }}><Text style={{ fontWeight: 'bold' }}>Taxa Matrícula</Text></View>
                                  <View style={{ width: 150 }}><Text style={{ fontWeight: 'bold' }}>Abrir Contrato</Text></View>
                                </View>
                                ) : null }
                                {isloading ? (
                                <View style={{ top: 10, alignSelf: 'center', height: 200 }}>
                                    <ActivityIndicator size={50} color='#004b9e' />
                                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                                </View> 
                                ) : (
                                    <FlatList
                                      data={dadoscontrato}
                                      style={{ width: '100%', height: '100%'}}
                                      keyExtractor={(item) => item.ITEM}
                                      showsVerticalScrollIndicator={true}
                                      renderItem={(item) => getcontratos(item)}
                                    />
                                )}
                                </ScrollView>
                            </View>                       
                        </View>
              </View>  
              </ScrollView>               
            </Card>
            <View style={{position: 'relative', alignSelf: 'flex-end', bottom: -5}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
          </View>
        </View>
      </View>
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  pdf: {
    flex:1,
    width:Dimensions.get('window').width,
    height:Dimensions.get('window').height,
  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: Device.osName=='Windows' ? width*0.86 : width*0.95,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    position: Device.osName=='Windows' ? null : 'relative',
    //alignItems: 'center',
    //justifyContent: 'center',
    //alignSelf: 'center'        
  },
  logo: {
    resizeMode: 'stretch',
    width: Device.osName=='Windows'? 110 : 50,
    height: Device.osName=='Windows'? 100 : 50,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },  

});
