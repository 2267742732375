import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator,  } from 'react-native'
import { useNavigation, useIsFocused  } from '@react-navigation/native';
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, Checkbox , TextInput } from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import { PDFReader } from 'react-read-pdf';
import axios from 'axios';

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App() {
  const { Aviso, dadoscontrato, isaluno, setIsAluno, accessdev } = useContext(UtilContext);
  const navigation = useNavigation();
  const { user, signOut } = useContext(AuthContext);
  const [guid, setGuid] = useState('');
  const [filepdf, setFilePdf] = useState('');
  const [loadingcontrato, setLoadingContrato] = useState(false);
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [checked, setChecked] = useState(false);
  const [pageatual, setPageAtual] = useState(1);
  const [pagetotal, setPageTotal] = useState(2);
  const [proxpage,setProxPage] = useState(true);
  const [antpage ,setAntPage] = useState(false);
  const [enabledsave ,setEnabledSave] = useState(false);

  const [ra, setRa] = useState('2307113');
  const [idperlet, setIdPerlet] = useState('88');
  const [codcontrato, setCodContrato] = useState('197717');
  
  const source = "data:application/pdf;base64,"  +  filepdf;

    
  const fetchData = async (ra_aluno, idperlet_aluno, codcontrato_aluno) => {
    try {
         setLoadingContrato(true);  
         setFilePdf('');
         var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/contratocel/${ra_aluno}/${idperlet_aluno}/${codcontrato_aluno}`;        
         await fetch(url, {
                  //timeout: 500000,
                  headers: {
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  /* if (responseJson.validtoken==false){
                    alert(responseJson.message); 
                    signOut();
                    return
                  } */

                  if (responseJson.sucesso){
                    alert(`É de supra importância que o Aluno leia o contrato!\nAo paginar o contrato até a última página será habilitado a opção\n"CONCORDO COM O CONTRATO E ACEITO OS TEMOS" e\nbotão "SALVAR".`);
                    //setGuid(responseJson.guid);
                    //console.log(`Retorno file ${responseJson.sucesso}`)
                    setFilePdf(responseJson.file)
                    //setFilePdf(responseJson.file);
                  }else{
                    //setGuid(responseJson.message);
                    //alert(responseJson.message);
                    //Aviso("Erro Soap", responseJson.message, 'danger', 3000);
                    alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente.\n\n`+responseJson.message);
                  }

                  setLoadingContrato(false);  

              })
              .catch((error) => {
                  //Aviso("Error1:", error.message, "danger", 2500);  
                  alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente.`);
                  setLoadingContrato(false);                       
              })
              .finally(function () {    
                setLoadingContrato(false);                     
              });  
            
      } catch (err) {
          Aviso("Error2:", err.message, "danger", 2500);
          setLoadingContrato(false); 
      } finally {
          {
            setLoadingContrato(false); 
          }
      }    
  }
  
  const DownloadFile = (data, name) => {
    //const linkSource = `data:application/pdf;base64,${data}`;
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;    
    downloadLink.click();
  };

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
          //fetchData();
          //DownloadFile(filepdfb64);
      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if(!loadingcontrato)
    setTimeOutLoading(0);

    if(loadingcontrato)
    setTimeout(() => {
     setTimeOutLoading((parseInt(timeoutloading)+1))
   }, 1000);
 }, [loadingcontrato, timeoutloading]);

 useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
  if (pageatual == 1){
      setAntPage(false);
      setProxPage(true);
  }else{
    setAntPage(true);
  }

  if (parseInt(pageatual) < parseInt(pagetotal)){
    setProxPage(true);
    setEnabledSave(false);
  }else{
    setProxPage(false); 
    setEnabledSave(true);   
  }

}, [pageatual]);


  return (
    <ImageBackground
      //source={require(width > 500 ? '../../img/background5.png' : '../../img/background5.1.png')}
      //style={[styles.imagebg, { width: width, height: '100%', paddingTop: 10 }]}
    >
      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={{flexDirection: 'column'}}>        
       
            <Card style={[styles.card,{marginTop: 10, height: 600}]} elevation={5} mode={'elevated'}>
                 
              
                {/* <Text style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>CONTRATO:</Text> */}
                <Text style={{
                color: '#4F4F4F',
                fontSize: 20,
                //fontFamily: 'cursive',
                fontFamily: 'Time New Roman',
                fontWeight: 'bold',
                //textShadowColor: 'rgba(0, 0, 0, 0.75)',
                //textShadowColor: 'rgb(218,165,32)',
                //textShadowOffset: { width: -1, height: 1 },
                //textShadowRadius: 10,
                marginBottom: 10,

              }}>{`:: CONTRATO :: Pag: ${filepdf != '' ? pageatual : '0'}`}</Text>
              <View style={{flexDirection: 'row', justifyContent:'space-between', }}>
              {filepdf != '' ? (
              <TouchableOpacity
                   onPress={() => {
                    //alert('Teste Dowaload');
                    DownloadFile(source, `Contrato_${ra}`);
                  }}
                  style={{ /*flex: 1, */  position: 'relative',width: 100,left: 25, flexDirection:'row', backgroundColor: '#eee' }}>
                <View style={{width: 130, height: 30,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: '#004b9e'}}>
                  <Icon name="file-download" size={20} color='#FFD700' style={{ padding: 10 }} />
                  {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                  <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>Download</Text>
                </View>
              </TouchableOpacity>
              ) : null}
                <View style={{flexDirection: 'row', left: filepdf != '' ? -100: 300}}>
                  <TextInput
                      placeholder={`RA`}
                      style={{ height: 30, width: 130, paddingRight: 20}}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='default'
                      value={ra}
                      onChangeText={(item) => setRa(item)}
                  />
                  <TextInput
                      placeholder={`IDPERLET`}
                      style={{ height: 30, width: 130, paddingRight: 20 }}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='default'
                      value={idperlet}
                      onChangeText={(item) => setIdPerlet(item)}
                  />
                  <TextInput
                      placeholder={`CODCONTRATO`}
                      style={{ height: 30, width: 170 }}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='default'
                      value={codcontrato}
                      onChangeText={(item) => setCodContrato(item)}
                  />
                  <TouchableOpacity
                        onPress={() => { 
                          //setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);    
                          
                          if(ra!='' && idperlet!='' && codcontrato !=''){
                            fetchData(ra, idperlet, codcontrato);
                          }else{
                            alert('Campos Pendente!');
                          }
                        }}
                        style={{ position: 'relative',width: 50, flexDirection:'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Icon name="search" size={20} color='#FFD700' style={{padding:5, backgroundColor: '#004b9e'}} />
                  </TouchableOpacity>
                </View>
                {filepdf != '' ? (
                <View style={{flexDirection: 'row',}}>
                    <TouchableOpacity
                        onPress={() => { 
                          setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);                           
                        }}
                        style={{ /*flex: 1, */  position: 'relative',width: 100, right: 160, flexDirection:'row', backgroundColor: '#eee' }}>
                      <View style={{width: 100, height: 30,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: antpage ? '#004b9e' : '#C0C0C0'}}>
                        <Icon name="angle-double-left" size={20} color='#FFD700' style={{ padding: 10 }} />
                        <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Página'}</Text>
                      </View>
                    </TouchableOpacity>
                    
                    <TouchableOpacity
                        onPress={() => { 
                            setPageAtual((parseInt(pageatual) < parseInt(pagetotal)) ? (parseInt(pageatual) + 1) : pagetotal );                            
                        }}

                        style={{ /*flex: 1, */  position: 'relative',width: 100, right: 60, flexDirection:'row', backgroundColor: '#eee' }}>
                      <View style={{width: 100, height: 30,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: proxpage ? '#004b9e' : '#C0C0C0' }}>
                        <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Página'}</Text>
                        <Icon name="angle-double-right" size={20} color='#FFD700' style={{ padding: 10 }} />
                      </View>
                    </TouchableOpacity>
                </View>
              ) : null}
              </View>
              
              <ScrollView style={{height: height, marginTop: 10}}>                                                                                       
              <View style={{/* flex:1,  */width: '100%', alignItems: 'center', justifyContent: 'center'  /* marginTop: 1  */}}>                   
                
                    <View style={{/* overflow:'scroll', */ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent:'center',/* height: 40, */ }}>
                        
                        {loadingcontrato ? (
                          <View style={{ paddingTop: 20}}>
                            <ActivityIndicator size={50} color='#004b9e' />
                            <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15, paddingTop: 10}}>{`Pesquisando Contrato Aguarde...\nTempo: ${timeoutloading}s`}</Text>
                          </View>   
                          
                        ):( 
                            <View style={{width: width * 0.8,height: height * 0.7,top: 2,borderRadius: 5, borderColor: '#ddd', borderWidth: 2, padding:5}}>
                                <ScrollView>
                                <View style={{width: '100%'}}>
                                    {/* <Text style={{flex:1, }}>{guid}</Text> */}    
                                    {/* https://github.com/forthealllight/react-read-pdf */}
                                    {/* <View style={{overflow:'scroll', height:600}}> */}
                                     {filepdf != '' ? (
                                        <PDFReader 
                                        onDocumentComplete={(numPages) => {
                                          //console.log(numPages);
                                          setPageTotal(numPages);
                                        }}
                                        showAllPage={false} page={pageatual} url={"data:application/pdf;base64,"  +  filepdf} />  
                                     ):null}
                                        
                                    
                                </View>
                                </ScrollView>
                                {filepdf != '' ? (
                                <View style={{flex:1, flexDirection: 'row', justifyContent:'space-between',position: 'relative', top: 5}}>
                                    <View  style={{left: 10, top: 10, flexDirection: 'row', padding: 10}}>
                                        <Text style={{fontSize: 16, fontWeight: enabledsave ? 'bold' : "normal", paddingRight: 20, top: 7, left: 20, position: 'relative', color: enabledsave ? "#000" : "#808080"}}>CONCORDO COM O CONTRATO E ACEITO OS TEMOS</Text>
                                        <Checkbox
                                          status={checked ? 'checked' : 'unchecked'}
                                          disabled={!enabledsave}
                                          onPress={() => {
                                            setChecked(!checked);
                                          }}
                                        />
                                    </View>
                                    <View style={{position: 'relative', right: 17}}>
                                        <TouchableOpacity
                                          onPress={() => {
                                            if(checked && enabledsave){
                                              alert('Botão Liberado!\n Para Salvar no BD')
                                            }else{
                                              alert('Só podemos continuar se estiver de acordo com o contrato e paginar até a última página.')
                                            }
                                          }}
                                        >
                                          
                                          <View style={{flexDirection: 'row', width: 100, top: 10, right:20,alignItems: 'center', justifyContent: 'center', backgroundColor: !checked ? '#A9A9A9' : '#004b9e', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                                            <Icon name="save" size={20} color={"#FFD700"} style={{ padding: 10 }} />
                                            {/* <Text style={{fontWeight:(empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null) ? 'bold' : 'normal', color: (empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null)?'blue':'black', fontSize: 12}}>{(empresa.codbarra != null && empresa.codbarradigitavel != null) ? empresa.idboleto : "----"}</Text> */}
                                            <Text style={{fontWeight:'bold', color: 'black', fontSize: 12, color: '#FFF'}}>SALVAR</Text>
                                          </View>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                ) : null}
                            </View>
                        )}
                        </View>
                         
                                
              </View>  
              </ScrollView>               
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -5}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
          </View>
        </View>
      </View>
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  pdf: {
    flex:1,
    width:Dimensions.get('window').width,
    height:Dimensions.get('window').height,
  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: width*0.86,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },

});
