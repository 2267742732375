import AsyncStorage from '@react-native-async-storage/async-storage'
import React, {useState, createContext} from 'react'
//import { AsyncStorage} from 'react-native'

export const AuthContext = createContext({})

function AuthProvider({ children }){
    const [user, setUser] = useState(null);   
    const [userra, setUserRA] = useState(null);   
    const [valid, setValid] = useState(false)
    async function signUpRA(raaluno, expired){
        let datara = {
            ra: raaluno, 
            expired: expired       
        }
        setUserRA(datara)            
        AsyncStorage.setItem('bdloginra',JSON.stringify(datara)) 
    }

    async function signUp(codcgo, nomefantasia, nome, cgccfo, expired, token, admin, adminti, aceitetermo, dataaceitetermo){
        
        if(token == '' || token == undefined){
            alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
            signOut();
            return false;
        }

        let url = `https://restlicense.t2kconsultoria.com.br/validtoken`;
        //valid token
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                sistema:'literatusempresacontrato',
                token: token
            }),
            timeout: 30000,
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
          })
        .then((response) => response.json())
        .then((responseJson) => {
            //
            if (responseJson.validtoken == true){
                //setValid(true);
                let data = {
                    codcgo: codcgo,
                    nomefantasia: nomefantasia,
                    nome: nome,
                    cgccfo: cgccfo,
                    expired: expired,
                    token: token,
                    admin: admin,
                    adminti: adminti,
                    aceitetermo: aceitetermo,
                    dataaceitetermo: dataaceitetermo          
                }
                    setUser(data)            
                    AsyncStorage.setItem('bdlogin',JSON.stringify(data))          
                
            }else{
                setValid(false);
                alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
                signOut();
                return false;
            }
        })
        .catch((error) => {
            alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
            signOut();
            return false;
        })
        .finally(function () {});  
      
        
    }        

    async function signOut(){
        AsyncStorage.removeItem('bdlogin');
        AsyncStorage.removeItem('bdloginra');
        AsyncStorage.removeItem('bddadoslogin');
        setUser(null);
        setUserRA(null);
        await AsyncStorage.clear();
            /* .then(               
               setUser(null)
            ) */
    }


    return(
        <AuthContext.Provider value={{ signed: (!!user || !!userra) , user, userra, signUp, signUpRA, signOut}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider