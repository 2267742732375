import React, { useEffect, useState, useContext } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, StyleSheet, Text, View, ActivityIndicator, Linking  } from 'react-native';
import { useNavigation, useIsFocused  } from '@react-navigation/native';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import { TextInput, Card } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome5';
import RouteMenu from '../../../routes/menu';
import Base64Url from 'base64-url';
import * as DocumentPicker from 'expo-document-picker';

const { width, height, } = Dimensions.get('window');
const dotenv = require('../../../app.config');

const FundoBackground = () => {
  return (
    <View style={{ position: 'abolute', width: '100%', height: '100%', backgroundColor: '#FFF', opacity: 0.7, borderRadius: 10 }} />
  )
}

export default function App() {
  const { Aviso, currencyFormatter } = useContext(UtilContext);
  const navigation = useNavigation();
  const { user, signOut } = useContext(AuthContext);
  const [datafilter, setDataFilter] = useState('');
  const [contratos, setContratos] = useState([
    /* { key: 1, descricao: 'REF MÊS 01/2023', vencimento: '30/01/2023', totalalunos: 40, valorbruto: '4.200', status: 'Fechado' },
    { key: 2, descricao: 'REF MÊS 02/2023', vencimento: '28/02/2023', totalalunos: 40, valorbruto: '4.100', status: 'Aberto' } */
  ]);
 
  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook

      }
  }, [focus]);

  const uploadfile = async () => {
        let result = await DocumentPicker.getDocumentAsync({type:'application/pdf'});
        //console.log(result.uri);
        //console.log(result);
        alert(`Name: ${result.output[0].lastModifiedDate}, Uri: ${(result.uri).replace("data:application/pdf;base64,","").slice(0,20)}`)
        console.log((result.uri).replace("data:application/pdf;base64,",""));
 };

 
  return (
    <ImageBackground>
      <View style={{ flex: 1, flexDirection: 'row', width: width,backgroundColor: '#ddd' }}>
        <RouteMenu />
        <View style={{flexDirection: 'column', top: 0 }}>
          <Card style={[styles.card, {marginTop: 10, height: 80}]} elevation={5} mode={'elevated'}>
          <View style={{ flexDirection: 'row', justifyContent:'space-between', }}>
            <View>
                <Text style={{
                    color: '#4F4F4F',
                    fontSize: 20,
                    //fontFamily: 'cursive',
                    fontFamily: 'Time New Roman',
                    fontWeight: 'bold',
                    //textShadowColor: 'rgba(0, 0, 0, 0.75)',
                    //textShadowColor: 'rgb(218,165,32)',
                    //textShadowOffset: { width: -1, height: 1 },
                    //textShadowRadius: 10,
                    marginBottom: 10,

                }}>:: UPLOAD - FILE ::</Text>
            </View>
         </View>                 
        </Card>
          <Card style={[styles.card, {marginTop: 10, height: 500}]} elevation={5} mode={'elevated'}>
          <View style={{ position: 'absolute', top: 0, padding: 10 }}>           
                <View style={{position: 'absolute', left: 200, padding: 5, borderRadius:5,top: 3, width: 300, height: 30, backgroundColor: '#004b9e', alignItems: 'center',justifyContent: 'center'}}>
                        <TouchableOpacity
                        style={{flexDirection: 'row',}}
                        onPress={() => { 
                            uploadfile();
                        }}
                        >
                        <Icon name="upload" size={20} color="#FFD700" style={{marginRight: 10}}/>
                        <Text style={{fontWeight: 'bold', color: '#FFD700'}}>CARREGAR ARQUIVO</Text>
                        </TouchableOpacity>
                </View>
          </View>
          </Card>
          <View style={{alignSelf: 'flex-end', bottom: -5}}>
              <TouchableOpacity onPress={() => {
                    /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                    const url = `${dotenv.URLT2K}`
                    Linking.openURL(url);
                }}>
                    <Text style={{fontSize: 13, fontWeight: 'bold'}}>{`Desenvolvimento por T2K Consultoria - ${dotenv.URLT2K}`}</Text>
                </TouchableOpacity>
          </View>
        </View>
      </View>

    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: width*0.86,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },

});
