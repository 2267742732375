import React from 'react';
import { Dimensions  } from 'react-native'
import * as Device from 'expo-device';//Device.osName
import ContAutoComputador from './contautocomputador';
import ContAutoMobile from './contautomobile';
const { width, height } = Dimensions.get('window');

export default function App({route}) {
  return (
    <>{ (Device.osName=='Windows' && width > 800) ? (<ContAutoComputador route={route} />) : (<ContAutoMobile route={route} />) }</>
  );
}
