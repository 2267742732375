
import React, {useContext, useEffect, useSate} from 'react';
import { Image, Text, TouchableOpacity, View, Dimensions, StyleSheet,  ActivityIndicator } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../contexts/auth';
import {  useNavigation  } from '@react-navigation/native';
import { UtilContext } from '../contexts/utilidade';
const { width, height } = Dimensions.get('window');
import * as Device from 'expo-device';//Device.osName

export default function App() {
    const { user, signOut } = useContext(AuthContext);
    const { Aviso, stsmenu, AtivaMenu, setModalVisible, lstalunos, isaluno, setIsAluno,
            setUserLogin, setUserPwd, setUserPwdNew,
            setNomeAluno, iscontrato, setIsContrato, dadoscontrato, visiblesalvar, setVisibleSalvar,
            raalunoreturn } = useContext(UtilContext);
    const navigation = useNavigation();
    //const [stsmenu, setStsMenu] = useState('INICIO');
    
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
       
    }, []);
    
    return(
        
        <View style={{ marginTop:10,width: 180, height: height * 0.98, opacity: 0.8, alignSelf: 'flex-start', borderTopRightRadius: 10, borderBottomRightRadius: 10, }}>
              <View style={{ position: 'relative', width: Device.osName=='Windows'? 150 : 90, backgroundColor: '#FFF' , marginLeft: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 5 }}>
                <Image style={styles.logo} source={require('../src/img/literatus2.png')} />
              </View>
              {!isaluno ? (
                  <View style={{margin: 10}}>
                    <TouchableOpacity
                        style={{ borderRadius: 5,  backgroundColor: stsmenu=='INICIO'?'#A9A9A9':'#004b9e',  height: 35,  width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => {
                            AtivaMenu('INICIO');
                            navigation.navigate('Initial');
                        }}
                        disabled={false}
                    >               
                        <View style={{width: '100%',flexDirection: 'row',}}>
                          <Icon name="home" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}}/>
                          <Text style={{ padding: Device.osName=='Windows'? 10: 3 , fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                              INÍCIO
                          </Text>
                        </View>
                    </TouchableOpacity>
                  </View>
              ) : null}
              {!isaluno ? (
                  <View style={{ margin: 10 }}>
                    <TouchableOpacity
                      style={{ borderRadius: 5, backgroundColor: stsmenu=='ALUNOS'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                      onPress={() =>  {
                          AtivaMenu('ALUNOS');
                          navigation.navigate('Alunos');
                        
                      }}
                    >
                      <View style={{ width: '100%', flexDirection: 'row', }}>
                        <Icon name="user-graduate" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                        <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                            ALUNOS                        
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
               ) : null}
              {/* {!isaluno ? (
                  <View style={{ margin: 10 }}>
                    <TouchableOpacity
                      style={{ borderRadius: 5, backgroundColor: stsmenu=='NOTAS'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                      onPress={() =>  {
                          AtivaMenu('NOTAS');
                          navigation.navigate('AlunosNotas');
                        
                      }}
                    >
                      <View style={{ width: '100%', flexDirection: 'row', }}>
                        <Icon name="file-invoice" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3 }} />
                        <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                            NOTAS                        
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
               ) : null} */}
               {!isaluno ? (
                    <View style={{ margin: 10 }}>
                      <TouchableOpacity
                        style={{ borderRadius: 5, backgroundColor: stsmenu=='FINANCEIRO'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                        onPress={() =>  {
                            AtivaMenu('FINANCEIRO');
                            navigation.navigate('Financeiro');
                          /* navigation.dispatch(CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Financeiro' }]
                          })) */
                        }}
                      >
                        <View style={{ width: '100%', flexDirection: 'row', }}>
                          <Icon name="money-check-alt" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3, paddingLeft: 5 }} />
                          <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                            FINANCEIRO
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
               ) : null}
              {(user?.adminti || user?.admin) && !isaluno ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: stsmenu=='CONTRATOS'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() =>  {
                    if (width<=500){
                      alert('Visão mobile Bloqueada!');
                      return false
                    }
                      AtivaMenu('CONTRATOS');
                      navigation.navigate('LstContratosAdmin', {raaluno: ''});
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="file-signature" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      CONTRATOS
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              ): null}
              {(user?.adminti || user?.admin) && !isaluno ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: stsmenu=='LOGINS'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    if (width<=500){
                      alert('Visão mobile Bloqueada!');
                      return false
                    }
                    AtivaMenu('LOGINS');
                    navigation.navigate('Logins');                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="user-friends" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      LOGINS
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>):null}
              {!isaluno && (user?.adminti || user?.admin) ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: stsmenu=='MANUTTI'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    if (width<=500){
                      alert('Visão mobile Bloqueada!');
                      return false
                    }
                    AtivaMenu('MANUTTI');
                    navigation.navigate('ManutTi');                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="database" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      CONSULTAS
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>):null}
              {/* BOTÃO UPLOAD ARQUIVO DE CONTRATO CONVERTIDO BASE64 */}
              {/* {user?.admin ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: stsmenu=='UPLOAD'?'#A9A9A9':'#004b9e', height: 35, width: 150, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    AtivaMenu('UPLOAD');
                    navigation.navigate('Upload');                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="upload" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: 10 }} />
                    <Text style={{ padding: 10, fontSize: 12, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      UPLOAD FILE
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>):null} */}
              {/* {!isaluno ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: stsmenu=='AJUDA'?'#A9A9A9':'#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    //AtivaMenu('AJUDA');
                    setModalVisible(true); 
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="question" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      AJUDA
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              ) : null} */}

              {isaluno && iscontrato ? (
              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    setIsContrato(false);
                    navigation.navigate('LstContratos', {raaluno: raalunoreturn}); 
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="reply-all" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5 }} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      VOLTAR
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              ) : null}

              <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: Device.osName=='Windows'? 150: 90, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## SAIR ##', // This is a title
                        `Deseja realmente sair?`,
                        {
                            textConfirm: 'SIM',      // Label of button confirm
                            textCancel: 'NÃO',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { {
                              setIsAluno(false);
                              //setUserLogin('');
                              setUserPwd('');
                              setUserPwdNew('');
                              setNomeAluno('');
                              AtivaMenu('INICIO');
                              navigation.navigate('Initial');
                              signOut() 
                              window.location.reload(false); 
                             } } // Call your confirm function 
                        }
                    )
                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="door-open" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      SAIR
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              {/* <View style={{ margin: 10 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 150, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    //alert(`AceiteTermo: ${user.aceitetermo} DataTermo: ${user.dataaceitetermo}`); 
                    alert(JSON.stringify(user)); 
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="door-open" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: 10 }} />
                    <Text style={{ padding: 10, fontSize: 12, color: '#FFF', fontWeight: 'bold', textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      TERMO
                    </Text>
                  </View>
                </TouchableOpacity>
              </View> */}
        </View>
    )

}

const styles = StyleSheet.create({
    logo: {
      resizeMode: 'stretch',
      width: Device.osName=='Windows'? 110 : 70,
      height: Device.osName=='Windows'? 100 : 70,
      overflow: 'hidden',
      //marginLeft: 30,
      borderRadius: 5
    },  
  });