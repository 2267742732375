import React, { useEffect, useState, useContext, useRef } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, StyleSheet, Text, View, ActivityIndicator, Linking, Modal, ScrollView  } from 'react-native';
import { useNavigation, useIsFocused  } from '@react-navigation/native';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import { TextInput, Card, Checkbox } from 'react-native-paper';
//import { StatusBar } from 'expo-status-bar';
//import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import RouteMenu from '../../../routes/menu';
import Base64Url from 'base64-url'
import utf8 from 'utf8';
import { bounce } from 'react-native/Libraries/Animated/Easing';
import * as Device from 'expo-device';//Device.osName

const { width, height, } = Dimensions.get('window');
const dotenv = require('../../../app.config');

const FundoBackground = () => {
  return (
    <View style={{ position: 'abolute', width: '100%', height: '100%', backgroundColor: '#FFF', opacity: 0.7, borderRadius: 10 }} />
  )
}

export default function App() {
  const { Aviso, currencyFormatter, accessdev } = useContext(UtilContext);
  const navigation = useNavigation();
  const { user, signOut } = useContext(AuthContext);
  const [datafilterde, setDataFilterDe] = useState('');
  const [datafilterate, setDataFilterAte] = useState('');
  const [contratos, setContratos] = useState([]);
  const [pagnumber, setPagNumber] = useState(1);
  const [totalpag, setTotalPag] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [cpfvisible, setCpfVisible] = useState(false);
  const [status, setStatus] = useState(2);
  const [isfiledownload, setIsFileDownload] = useState('');
  const refde = useRef(null);
  const refate = useRef(null);

  const [modalVisibleFiltro, setModalVisibleFiltro] = useState(false);
  

  const RowspPage = 10;

  
  function getinfoempresa({ item: empresa }) {
    //console.log(LstTicket);
    //if (empresa.codbarradigitavel === null) return
    //console.log(empresa);
    //return

    return (
      
      <View style={{position: 'relative',width: Device.osName=='Windows' ? width * 0.8 : width *0.6, paddingTop: 10, flexDirection: Device.osName=='Windows' ? 'row' : 'column', height: Device.osName=='Windows' ? 40 : '100%', top: -5, borderBottomColor: '#000', borderBottomWidth: 1.5}}>
      {Device.osName=='Windows' ? ( <>
        <View style={{ width: 350 }}><Text style={{}}>{((empresa.historico).replace("\r", "").replace("\n", "")).slice(0,40)+'...'}</Text></View>
        <View style={{ width: 100 }}><Text style={{}}>{empresa.vencimento}</Text></View>
        <View style={{ width: 70}}><Text style={{}}>{empresa.totalalunos}</Text></View>
        <View style={{ width: 100 }}><Text style={{}}>{currencyFormatter(parseFloat(empresa.valor.replace(",",".")))}</Text></View>
        <View style={{ width: 70 }}><Text style={{}}>{currencyFormatter(parseFloat(empresa.valor.replace(",",".")) - parseFloat(empresa.valorboleto.replace(",",".")))}</Text></View>
        <View style={{ width: 105 }}><Text style={{}}>{currencyFormatter(parseFloat(empresa.valorboleto.replace(",",".")))}</Text></View>
        <View style={{ width: 130, }}>
          {/* <View style={{ backgroundColor: empresa.status == 'ABERTO' ? '#FA8072' : empresa.status == 'BAIXADO' ? '#6A5ACD' : '#E0FFFF', borderRadius: 20, width: 100, height: 25, alignItems: 'center', justifyContent: 'center' }}> */}
          <View style={{ backgroundColor: empresa.status == 'ABERTO' ? '#FA8072' : empresa.status == 'BAIXADO' ? '#32CD32' : '#E0FFFF', borderRadius: 20, width: 100, height: 25, alignItems: 'center', justifyContent: 'center' }}>
            {/* <Text style={{ fontWeight: 'bold', textAlign: 'center', color: empresa.status == 'ABERTO' ? '#8B0000' : '#006400', }}>{empresa.status}</Text> */}
            <Text style={{ fontWeight: 'bold', textAlign: 'center', color: empresa.status == 'ABERTO' ? '#FFF' : '#FFF'}}>{empresa.status}</Text>
          </View>
        </View>
        {cpfvisible ? (
        <View style={{ width: 70, flexDirection: 'row', top: -10, left: -10 }}>
          {/* {empresa.scodnfe != 0 ? ( */}
          <TouchableOpacity
            disabled={empresa.scodnfe!=0?false:true}
            onPress={() => {
                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                 const url = `https://nfse-prd.manaus.am.gov.br/nfse/servlet/wvalidarautenticidadenfse?${empresa.scodnfe}`
                 Linking.openURL(url);
            }}
          >
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Icon name="file-invoice-dollar" size={20} color={empresa.scodnfe!=0?"green":"#A9A9A9"} style={{ padding: 10 }} />
              {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
              {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? 'NF' : '----'}</Text> */}
            </View>
          </TouchableOpacity>
        </View>
        ):null}
        <View style={{ width: 70, flexDirection: 'row', top: -10, left: -15 }}>
          {empresa.idboleto != null ? (
          <TouchableOpacity
            onPress={() => { 
              if(empresa.statusboleto==0 && empresa.codbarra == null && empresa.codbarradigitavel == null){
                //alert(`Status: ${empresa.statusboleto} Cod.Barra: ${empresa.codbarra}`);
                alert('Boleto ainda está em processamento,\nainda não é possível imprimir.');
                return
              }              
              if(empresa.statusboleto==1 ){
                //alert(`Status: ${empresa.statusboleto} Cod.Barra: ${empresa.codbarra}`);
                alert('Boleto já está pago!');
                return
              }
                let valuehistorico = (empresa.historico.length >200) ? empresa.historico.slice(0,200)+", ..." : empresa.historico;
                let dadosboleto = empresa.vencimento+";"+empresa.emissao+";"+empresa.criacao+";"+
                                  empresa.valororiginal+";"+empresa.nossonumero+";"+empresa.razaosocial+";"+
                                  empresa.idboleto+";"+empresa.codbarra+";"+empresa.codbarradigitavel+";"+                                 
                                  empresa.fcfologradouro+";"+empresa.fcfocidade+";"+empresa.fcfouf+";"+
                                  empresa.fcfocep+";"+empresa.fcfobairro+";"+empresa.cnpj+";"+valuehistorico;
                                  
               Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/santander.php?token=${Base64Url.encode(dadosboleto) }`);              
            }}
          >
            
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Icon name={empresa.statusboleto==1 ? "check" : "file-download"} size={20} color={(empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null)?"green":(empresa.statusboleto==0 && empresa.codbarra == null && empresa.codbarradigitavel == null)?"red":"#A9A9A9"} style={{ padding: 10 }} />
              {/* <Text style={{fontWeight:(empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null) ? 'bold' : 'normal', color: (empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null)?'blue':'black', fontSize: 12}}>{(empresa.codbarra != null && empresa.codbarradigitavel != null) ? empresa.idboleto : "----"}</Text> */}
              {/* <Text style={{fontWeight:(empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null) ? 'bold' : 'normal', color: (empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null)?'blue':'black', fontSize: (empresa.statusboleto==0 && empresa.codbarra != null && empresa.codbarradigitavel != null)?12:10}}>{(empresa.codbarra != null && empresa.codbarradigitavel != null) ? empresa.statusboleto==0 ? 'BOLETO': 'PAGO' : "PROCESSANDO"}</Text> */}
            </View>
          </TouchableOpacity>
          ) : null }
        </View>
        <View style={{ flexDirection: 'row', top: -10, left: -35 }}>
            <TouchableOpacity
                //disabled={empresa.scodnfe!=0?false:true}
                onPress={() => {
                  //if(empresa.scodnfe!=0){
                    /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                    //console.log(`${dotenv.SERVER_PHP}/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'`);
                    const url = `https://rel.literatus.edu.br/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'&idboleto=${empresa.idboleto}&resaluno=${(user.adminti || user.admin) ? true : false}&empresa='${empresa.razaosocial}'&cnpj='${empresa.cnpj}'`
                    //alert(url);
                    Linking.openURL(url);
                  //}
                }}
              >
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <Icon name="user-friends" size={20} color="#004b9e" style={{ padding: 10 }} />
                  {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                  {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? 'NF' : '----'}</Text> */}
                </View>
            </TouchableOpacity>
        </View>
      </>) : 
        (
          <>
              <View style={{fontSize: 11 }}><Text style={{}}>Vencimento: {empresa.vencimento}</Text></View>
              <View style={{fontSize: 11 }}><Text style={{}}>Valor: {currencyFormatter(parseFloat(empresa.valorboleto.replace(",",".")))}</Text></View>
              <View style={{}}><Text style={{}}>Histórico: {((empresa.historico).replace("\r", "").replace("\n", "")).slice(0,40)+'...'}</Text></View>
              <View style={{flex: 1, position: 'relative', flex: 1,flexDirection: 'row', top: 10}}>
                    <View style={{ backgroundColor: empresa.status == 'ABERTO' ? '#FA8072' : empresa.status == 'BAIXADO' ? '#32CD32' : '#E0FFFF', borderRadius: 5, width: Device.osName=='Windows' ? 100 : 50 , height: 25, alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontWeight: 'bold', textAlign: 'center', color: empresa.status == 'ABERTO' ? '#FFF' : '#FFF', fontSize: Device.osName=='Windows'? null : 9  }}>{empresa.status}</Text>
                    </View>
                  {cpfvisible ? (
                  <View style={{ /* width: 70, */ flexDirection: 'row', top: -10, left: 20}}>
                    <TouchableOpacity
                      disabled={empresa.scodnfe!=0?false:true}
                      onPress={() => {
                          /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                          const url = `https://nfse-prd.manaus.am.gov.br/nfse/servlet/wvalidarautenticidadenfse?${empresa.scodnfe}`
                          Linking.openURL(url);
                      }}
                    >
                      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <Icon name="file-invoice-dollar" size={20} color={empresa.scodnfe!=0?"green":"#A9A9A9"} style={{ padding: 10 }} />
                        {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                        {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? 'NF' : '----'}</Text> */}
                      </View>
                    </TouchableOpacity>
                  </View>
                  ):null}
                  <View style={{ /* width: 70, */ flexDirection: 'row', top: -10, left: 35 }}>
                    {empresa.idboleto != null ? (
                    <TouchableOpacity
                      onPress={() => { 
                        if(empresa.statusboleto==0 && empresa.codbarra == null && empresa.codbarradigitavel == null){
                          //alert(`Status: ${empresa.statusboleto} Cod.Barra: ${empresa.codbarra}`);
                          alert('Boleto ainda está em processamento,\nainda não é possível imprimir.');
                          return
                        }              
                        if(empresa.statusboleto==1 ){
                          //alert(`Status: ${empresa.statusboleto} Cod.Barra: ${empresa.codbarra}`);
                          alert('Boleto já está pago!');
                          return
                        }
                          let valuehistorico = (empresa.historico.length >200) ? empresa.historico.slice(0,200)+", ..." : empresa.historico;
                          let dadosboleto = empresa.vencimento+";"+empresa.emissao+";"+empresa.criacao+";"+
                                            empresa.valororiginal+";"+empresa.nossonumero+";"+empresa.razaosocial+";"+
                                            empresa.idboleto+";"+empresa.codbarra+";"+empresa.codbarradigitavel+";"+                                 
                                            empresa.fcfologradouro+";"+empresa.fcfocidade+";"+empresa.fcfouf+";"+
                                            empresa.fcfocep+";"+empresa.fcfobairro+";"+empresa.cnpj+";"+valuehistorico;
                                            
                        Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/santander.php?token=${Base64Url.encode(dadosboleto) }`);              
                      }}
                    >                      
                      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                      <TouchableOpacity
                          //disabled={empresa.scodnfe!=0?false:true}
                          onPress={() => {
                            //if(empresa.scodnfe!=0){
                              /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                              //console.log(`${dotenv.SERVER_PHP}/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'`);
                              const url = `https://rel.literatus.edu.br/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'&idboleto=${empresa.idboleto}&resaluno=${(user.adminti || user.admin) ? true : false}&empresa=${empresa.razaosocial}&cnpj=${empresa.cnpj}`
                              Linking.openURL(url);
                            //}
                          }}
                        >
                          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon name="user-friends" size={20} color="#004b9e" style={{ padding: 10 }} />
                            {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                            {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? 'NF' : '----'}</Text> */}
                          </View>
                      </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                    ) : null }
                  </View>
                  <View style={{ flexDirection: 'row', top: -10, left: 10 }}>
                      <TouchableOpacity
                          //disabled={empresa.scodnfe!=0?false:true}
                          onPress={() => {
                            //if(empresa.scodnfe!=0){
                              /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                              //console.log(`${dotenv.SERVER_PHP}/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'`);
                              const url = `https://rel.literatus.edu.br/relatoriopdf/alunosboletos.php?token=${user.token}&codcfo=${user.codcgo}&data='${empresa.criacao}'&idboleto=${empresa.idboleto}&resaluno=${(user.adminti || user.admin) ? true : false}&empresa=${empresa.razaosocial}&cnpj=${empresa.cnpj}`
                              Linking.openURL(url);
                            //}
                          }}
                        >
                          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <Icon name="user-friends" size={20} color="#004b9e" style={{ padding: 10 }} />
                            {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                            {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? 'NF' : '----'}</Text> */}
                          </View>
                      </TouchableOpacity>
                  </View>
              </View>
          </>
        )
      }
      </View>
    )
  }

  const [mes, setMes]=useState(0);
  const [mesate, setMesAte]=useState(0);
  const [ano, setAno]=useState(0);
  const [isdownload, setIsDownload] = useState(false);
  const [totallst, setTotalLst] = useState(0);

  const fetchData = async (pgselect) => {
    try {
        if(user.codcgo !== 0){

        if (user.codcgo=="" || user.codcgo==null){
          let bdintlogin = await AsyncStorage.getItem('bdlogin');  
          let parsed = JSON.parse(bdintlogin);
          if(parsed){
            user.codcgo=parsed.codcgo;
          }
          
        }
        setPagNumber(1);
        setLoading(true);
        setIsDownload(false);
        setContratos([]);
        setTotalLst(0);
        let data = new Date();
        let mesatual = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
        let mesatualate = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
        let anoatual = data.getFullYear();
        
        var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/cont2`;
          if(datafilterde!='' && datafilterate!=''){
            //var filtro = datafilterde.split("/");
            var dtini = datafilterde!='' ? datafilterde.split("/") : null;
            var dtate = datafilterate!='' ? datafilterate.split("/") : null;
          }
         await fetch(url, {
          method: 'POST',
                  body: JSON.stringify({
                      dtini: dtini!=null ? `${dtini[1]}-${dtini[0]}` : `${anoatual}-${mesatual}`,
                      dtate: dtate!=null ? `${dtate[1]}-${dtate[0]}` : `${anoatual}-${mesatualate}`,
                      pn:    `${pgselect ? pgselect : 1}`,
                      rp:    `${RowspPage}`,
                      status: status?? 2,
                      codcfo: user.codcgo
                  }),
                  timeout: 5000,
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                  //LISTA DE FORNECEDORES
                  //console.log(responseJson.data)
                  //console.log(responseJson)
                  if (responseJson.validtoken==false){
                    //console.log(responseJson);                    
                    //Aviso("Error:", responseJson.message, "danger", 3000);  
                    alert(responseJson.message); 
                    signOut();
                    //setLoading(false); 
                    return
                  }

                  //?dtini=01/2023&dtfim=04/2023&status=2&codcfo=90004
                  let dtini2  = dtini!=null ? `${dtini[0]}/${dtini[1]}` : `${mesatual}/${anoatual}`;
                  let dtfim2  = dtate!=null ? `${dtate[0]}/${dtate[1]}` : `${mesatual}/${anoatual}`;
                  let status2 = status?? 2; 
                  let codcfo2  = user.codcgo;

                  if(parseInt(responseJson.totafind) > 0){ 
                    setTotalLst(responseJson.totafind);

                    setCpfVisible(responseJson.data[0].cnpj.length === 18 ? true : true);  //SE FOR ==8 -> TRUE SENÃO FALSE. POR ENQAUNTO TUDO TRUE                 
                    setContratos(responseJson.data); 
                    setTotalPag(responseJson.data[0].total);   
                    setPagNumber(responseJson.data[0].pn);
                    setIsDownload(true);

                    let url2 = `${dotenv.SERVER_PHP}/relatoriopdf/relatorioboletos.php?dtini=${dtini2}&dtfim=${dtfim2}&status=${status2}&codcfo=${codcfo2}`
                    fetch(url2, {timeout: 5000, })
                          .then((response2) => response2.json())
                          .then((responseJson2) => {
                            
                            //console.log(responseJson2);
                            if(responseJson2.sucesso){
                                setIsFileDownload(responseJson2.file);
                                setLoading(false);
                            }else{
                                alert('Erro ao gerar arquivode Download.')
                                setIsDownload(false);
                                setLoading(false);
                            }

                          })
                          .catch((error2) => {alert(error2)})
                          .finally(function () {});

                    
                  }else{
                    setIsDownload(false);
                    Aviso("### FILTRO ###", `Nenhum Resultado atende o filtro ${dtini2} até ${dtfim2}.`, "danger", 3000);
                    setLoading(false); 
                  }
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000);  
                  setLoading(false);                       
              })
              .finally(function () {    
                setLoading(false);                     
              });  
            }else{
              setPagNumber(0);
            }
      } catch (err) {
          Aviso("Error:", err.message, "danger", 3000);
          setLoading(false); 
      } finally {
          {
            //setLoading(false); 
          }
      }
    
  }

  function validarfiltrode(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterDe(v);
  }
  function validarfiltroate(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterAte(v);
  }

  
  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
          let data = new Date();
          let mesatual = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
          let mesatualate = ('00'+ (parseInt(data.getMonth()) + 1) ).slice(-2);
          let anoatual = data.getFullYear();
          setMes(mesatual);
          setMesAte(mesatualate);
          setAno(anoatual);
          ///setDataFilterDe(`${mesatual}/${anoatual}`);
          //setDataFilterAte(`${mesatualate}/${anoatual}`);
          //alert(`Ajuste o Filtro "DE", "ATÉ", "Todos", "Aberto" ou "Pago" e mande buscar clicando na "LUPA" `);
          fetchData()
                    
      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if(!loading)
    setTimeOutLoading(0);

    if(loading)
    setTimeout(() => {
     setTimeOutLoading((parseInt(timeoutloading)+1))
   }, 1000);
 }, [loading, timeoutloading]);


  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if(!contratos){
      setLoading(false);
    }
 }, [contratos]);

 useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
  if (datafilterde.length == 7){
    refate.current.focus();
    //alert('De Tamanho 7');
}
}, [datafilterde]);


  
  return (
    <ImageBackground
      /* source={require(width > 500 ? '../../img/background5.png' : '../../img/background5.png')}
      style={[styles.imagebg, { resizeMode: 'cover', width: '100%',paddingTop: 10 }]} */
    >
      <View style={{ flex: 1, flexDirection: 'row', width: width,backgroundColor: '#ddd' }}>
        <RouteMenu />
        <View style={{flexDirection: 'column', top: 0,left: Device.osName=='Windows' ? null : -70, }}>
        {/* <View style={{ flex: 1, margin: 10, width: '100%', top: -7 }}> */}
          {/* <FundoBackground /> */}
          <Card style={[styles.card, {marginTop: 10, height: 80}]} elevation={5} mode={'elevated'}>
          {Device.osName=='Windows' ? (
              <View style={{ flexDirection: 'row', justifyContent:'space-between', }}>
                  <View>
                      <Text style={{
                        color: '#4F4F4F',
                        fontSize: 20,
                        //fontFamily: 'cursive',
                        fontFamily: 'Time New Roman',
                        fontWeight: 'bold',
                        //textShadowColor: 'rgba(0, 0, 0, 0.75)',
                        //textShadowColor: 'rgb(218,165,32)',
                        //textShadowOffset: { width: -1, height: 1 },
                        //textShadowRadius: 10,
                        marginBottom: 10,

                      }}>:: FINANCEIRO ::</Text>
                    </View>
                  
                    <View style={{ flexDirection: 'row', alignItems: 'center', top: -3, right: 20 }}>
                      <View style={{flexDirection: 'row'}}>
                      <Text style={{ fontSize: 13, fontFamily: 'Arial', fontWeight: 'bold', margin: 5, top: 5, position: 'relative' }}>
                        DE:
                      </Text>
                      <TextInput
                        placeholder={`ex.: ${mes}/${ano}`}
                        style={{ height: 30, width: 130 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        ref={refde}
                        value={datafilterde}
                        maxLength={7}
                        onChangeText={(item) => validarfiltrode(item)}
                      />
                      <Text style={{ fontSize: 13, fontFamily: 'Arial', fontWeight: 'bold', margin: 5,top: 5, position: 'relative' }}>
                        ATÉ:
                      </Text>
                      <TextInput
                        placeholder={`ex.: ${mesate}/${ano}`}
                        style={{ height: 30, width: 130 }}
                        editable={true}
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType='default'
                        ref={refate}
                        value={datafilterate}
                        maxLength={7}
                        onChangeText={(item) => validarfiltroate(item)}
                      />
                      </View>
                      <TouchableOpacity 
                        onPress={() => { 
                        if ((parseInt(datafilterde.length) < 7 ) && (parseInt(datafilterate.length) < 7) ){
                            alert('Formato do Filtro precisa ser corrigido. Ex: 00/0000');
                            return
                        }

                          fetchData(); 
                        }}>
                        <Icon name="search" size={20} color="#FFD700" style={{ padding: 5, borderTopEndRadius: 5, borderBottomEndRadius: 5, backgroundColor: '#004b9e', height: 30, alignItems: 'center', justifyContent: 'center' }} />
                      </TouchableOpacity>              
                    </View> 
                </View>
            ) : null} 
            <View style={{ flexDirection: Device.osName=='Windows' ? 'row' : 'column', alignItems: Device.osName=='Windows' ? 'center' : 'flex-start' }}>
              <Text style={{ fontSize: Device.osName=='Windows' ? 13: 12, fontFamily: 'Arial', fontWeight: 'bold', margin: 5 }}>
                EMPRESA:
              </Text>
              <Text style={{ fontSize: Device.osName=='Windows' ? 15: 10, top:Device.osName=='Windows' ? null : -10, position: Device.osName=='Windows' ? null : 'relative', fontFamily: 'Time New Roman', margin: 5, fontWeight: 'bold' }}>
                 {user?.nomefantasia ? user?.nomefantasia : user?.nome }
                 {' - '}
                 {user?.codcgo}
              </Text>
              {Device.osName=='Windows' ? null : (
                <View>
                  {isdownload ? (
                          <TouchableOpacity onPress={() => {
                             setModalVisibleFiltro(!modalVisibleFiltro);
                          }}>
                            <View style={{marginLeft: -5,flexDirection: 'row', backgroundColor: '#004b9e', borderRadius: 5, width: 100,  height: 22, top: -4, alignItems: 'center', justifyContent: 'center' }}>                  
                                <Icon name="search" size={Device.osName=='Windows' ? 13 : 11} color="#FFD700" style={{ position: 'relative',padding: 5, alignItems: 'center', justifyContent: 'center',  height: 25, top: 2 }} />
                                <Text style={{fontSize: Device.osName=='Windows' ? 13 : 11, fontWeight: 'bold', top: 0, position: 'relative', color: '#FFD700',}}>FILTRO</Text>
                            </View>
                          </TouchableOpacity>
                      ): null}
                </View>
              )}
                <View style={{flexDirection: 'row', width: 350, position: 'absolute', right: 35}}>
              {Device.osName=='Windows' ? (<>
                      <View style={{flexDirection: 'row'}}>                  
                          <Checkbox

                              status={status==2 ? 'checked' : 'unchecked'}
                              color='#004b9e'
                              uncheckedColor='#A9A9A9'
                              onPress={() => {
                                setStatus(2);
                              }}
                          />
                          <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Todos</Text>
                      </View>                      
                      <View style={{flexDirection: 'row'}}>                  
                          <Checkbox
                              status={status==0 ? 'checked' : 'unchecked'}
                              color='#004b9e'
                              uncheckedColor='#A9A9A9'
                              onPress={() => {
                                setStatus(0);
                              }}
                          />
                          <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Aberto</Text>
                      </View>
                      <View style={{flexDirection: 'row'}}>                  
                          <Checkbox
                              status={status==1 ? 'checked' : 'unchecked'}
                              color='#004b9e'
                              uncheckedColor='#A9A9A9'
                              onPress={() => {
                                setStatus(1);
                              }}
                          />
                          <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Pago</Text>
                      </View>
                      </>
              ): null}        
                       {isdownload ? (
                          <TouchableOpacity onPress={() => {
                             const linkSource = "data:application/pdf;base64,"  +  isfiledownload;
                             const downloadLink = document.createElement("a");
                             const fileName = "Relatório_Financeiro.pdf";
                             downloadLink.href = linkSource;
                             downloadLink.download = fileName;    
                             downloadLink.click();
                          }}>
                            <View style={{marginLeft: Device.osName=='Windows' ? 20 : 260,flexDirection: 'row', backgroundColor: '#004b9e', borderRadius: 5, width: 100,  height: 22, top: Device.osName=='Windows' ? 7 : 42, alignItems: 'center', justifyContent: 'center' }}>                  
                                <Icon name="file-download" size={Device.osName=='Windows' ? 13 : 11} color="#FFD700" style={{ position: 'relative',padding: 5, alignItems: 'center', justifyContent: 'center',  height: 25, top: 2 }} />
                                <Text style={{fontSize: Device.osName=='Windows' ? 13 : 11, fontWeight: 'bold', top: 0, position: 'relative', color: '#FFD700',}}>Relatório</Text>
                            </View>
                          </TouchableOpacity>
                      ): null}
                      
                </View>
                                              
            </View>
          </Card>
          <Card style={[styles.card, {marginTop: 10, height: Device.osName=='Windows' ? 470 : height*0.8, overflow: Device.osName=='Windows' ? null : 'scroll'}]} elevation={5} mode={'elevated'}>
          
          <View style={{ position: 'absolute', top: 0, padding: 10, overflow: 'hidden' }}>           
            {Device.osName=='Windows' ? (
            <View style={{ width: width * 0.8, paddingTop: 1, flexDirection: 'row', borderBottomColor: '#000', borderBottomWidth: 1.5 }}>
              <View style={{ width: 350 }}><Text style={{ fontWeight: 'bold' }}>Descrição</Text></View>
              <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Vencimento</Text></View>
              <View style={{ width: 70 }}><Text style={{ fontWeight: 'bold' }}>Alunos</Text></View>
              <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Valor Bruto</Text></View>
              <View style={{ width: 70 }}><Text style={{ fontWeight: 'bold' }}>Desconto</Text></View>
              <View style={{ width: 110 }}><Text style={{ fontWeight: 'bold' }}>Valor Líquido</Text></View>
              <View style={{ width: 125 }}><Text style={{ fontWeight: 'bold' }}>Status</Text></View>
              {cpfvisible ? (
                  <View style={{ width: 50 }}><Text style={{ fontWeight: 'bold' }}>NFSe</Text></View>
              ) : null}
              <View style={{ width: 50 }}><Text style={{ fontWeight: 'bold' }}>Boleto</Text></View>
              <View style={{ width: 50 }}><Text style={{ fontWeight: 'bold' }}>Alunos</Text></View>
            </View>
            ) : null }
            {/* <View style={{width: width*0.8,paddingTop: 10, flexDirection: 'row'}}> */}
            {loading ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height,left: 20}}>
                      <ActivityIndicator size={Device.osName=='Windows' ? 50 : 20} color='#004b9e' />
                      <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: Device.osName=='Windows' ? 15 : 11}}>{`Buscando as Informações...\nPesquisa pode demorar em torno de 20s.\nTempo: ${timeoutloading}s`}</Text>
                  </View>  
                ) : (
                <View style={{overflow:'hidden', height:450, width: width*0.83}}>
                <FlatList
                  data={contratos}
                  style={{ width: '100%', height: '100%' }}
                  keyExtractor={(item) => item.item}
                  showsVerticalScrollIndicator={true}
                 renderItem={(item) => getinfoempresa(item)}
                />
                </View>
            )}
            {/* </View> */}

               {!loading &&  totalpag >= 10 && false ? (  
                <View style={{flexDirection: 'row',alignItems: 'center', justifyContent: 'center', height: 50, width: '100%',}}>
                   <TouchableOpacity
                    onPress={() => {
                      
                      fetchData(1);
                    }}>
                    <Icon name="step-backward" size={25} color="#000" style={{padding: 10, marginRight: 50}}/>
                  </TouchableOpacity>
                   
                   <TouchableOpacity
                    onPress={() => {
                      fetchData((pagnumber - RowspPage) < 1 ? 1 : pagnumber - RowspPage);
                    }}>
                    <Icon name="outdent" size={25} color="#000" style={{padding: 10,  marginRight: 50}}/>
                  </TouchableOpacity>
                  
                    <Text style={{padding: 10,  marginRight: 50, fontWeight: 'bold'}}>{pagnumber} a {(pagnumber + (RowspPage -1)) > totalpag ? totalpag : pagnumber + (RowspPage - 1)}  de {totalpag}</Text>
                  
                  <TouchableOpacity
                    onPress={() => {
                      
                      fetchData((pagnumber + RowspPage)>totalpag ? pagnumber : pagnumber + RowspPage);
                    }}>
                    <Icon name="indent" size={25} color="#000" style={{padding: 10,   marginRight: 50}}/>
                  </TouchableOpacity>                  
                  
                  <TouchableOpacity
                    onPress={() => {
                      
                      fetchData(totalpag - ( RowspPage -1 ));
                    }}>
                    <Icon name="step-forward" size={25} color="#000" style={{padding: 10}}/>
                  </TouchableOpacity>                  
              </View>
           ):(<></>)}
          </View>
          </Card>
          <View style={{alignSelf: 'flex-end', bottom: -5, right: Device.osName=='Windows'? '': 10}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
          </View>
        </View>
      </View>
      <Modal animationType='slide' transparent={true} visible={modalVisibleFiltro}>
                    <ScrollView>
                    <View style={styles.viewmodal}>
                      <Card style={[styles.card,{height: 200, width: width * 0.9}]} elevation={5} mode={'elevated'}>                       
                            <Text style={{fontSize: 15, fontWeight: 'bold'}}>Selecione o Período:</Text>                             
                            <View style={{flexDirection: 'row', margin: 10, zIndex: -10, top: -10}}>  
                            <View style={{ flexDirection: 'row', alignItems: 'center', top: -3, right: 20 }}>
                                <View style={{flexDirection: 'row'}}>
                                <Text style={{ fontSize: 13, fontFamily: 'Arial', fontWeight: 'bold', margin: 5, top: 5, position: 'relative' }}>
                                  DE:
                                </Text>
                                <TextInput
                                  placeholder={`ex.: ${mes}/${ano}`}
                                  style={{ height: 30, width: 130 }}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  ref={refde}
                                  value={datafilterde}
                                  maxLength={7}
                                  onChangeText={(item) => validarfiltrode(item)}
                                />
                                <Text style={{ fontSize: 13, fontFamily: 'Arial', fontWeight: 'bold', margin: 5,top: 5, position: 'relative' }}>
                                  ATÉ:
                                </Text>
                                <TextInput
                                  placeholder={`ex.: ${mesate}/${ano}`}
                                  style={{ height: 30, width: 130 }}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  ref={refate}
                                  value={datafilterate}
                                  maxLength={7}
                                  onChangeText={(item) => validarfiltroate(item)}
                                />
                                </View>
                                             
                              </View>       
                            </View>
                            <View style={{position: 'relative', flexDirection: 'row', margin: 10, zIndex: -10, top: -10, left : -10}}>  
                              <View style={{flexDirection: 'row'}}>                  
                                  <Checkbox

                                      status={status==2 ? 'checked' : 'unchecked'}
                                      color='#004b9e'
                                      uncheckedColor='#A9A9A9'
                                      onPress={() => {
                                        setStatus(2);
                                      }}
                                  />
                                  <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Todos</Text>
                              </View>                      
                              <View style={{flexDirection: 'row'}}>                  
                                  <Checkbox
                                      status={status==0 ? 'checked' : 'unchecked'}
                                      color='#004b9e'
                                      uncheckedColor='#A9A9A9'
                                      onPress={() => {
                                        setStatus(0);
                                      }}
                                  />
                                  <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Aberto</Text>
                              </View>
                              <View style={{flexDirection: 'row'}}>                  
                                  <Checkbox
                                      status={status==1 ? 'checked' : 'unchecked'}
                                      color='#004b9e'
                                      uncheckedColor='#A9A9A9'
                                      onPress={() => {
                                        setStatus(1);
                                      }}
                                  />
                                  <Text style={{fontSize: 15, fontWeight: 'bold', top: 7, position: 'relative'}}>Pago</Text>
                              </View>      
                            </View>
                            <View style={{flexDirection: 'row', margin: 10, zIndex: -10, top: -10}}>  
                                  
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 10, bottom: 5, zIndex: -10}}> 
                                <View style={{ position: 'relative', left: -10, padding: 5,  borderRadius:5, width: 120, height:  30, backgroundColor: '#004b9e'}}> 
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 
                                            setModalVisibleFiltro(false);
                                        }}
                                      >
                                            <Icon name="window-close" size={20} color="#FFD700" style={{marginRight: 10, paddingLeft: 10}}/>
                                            <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: 15}}>FECHAR</Text>
                                      </TouchableOpacity>                        
                                </View>
                                <View style={{padding: 5,  borderRadius:5, width: 120, height: 30, backgroundColor: '#004b9e', /* left: -35 */}}>
                                  <TouchableOpacity
                                    style={{flexDirection: 'row',}}
                                    onPress={() => {                                                                                
                                      if ((parseInt(datafilterde.length) < 7 ) && (parseInt(datafilterate.length) < 7) ){
                                          alert('Formato do Filtro precisa ser corrigido. Ex: 00/0000');
                                          return
                                      }
    
                                        setModalVisibleFiltro(false);
                                        fetchData(); 
                                    }}
                                  >
                                    <Icon name="search" size={20} color="#FFD700" style={{marginRight: 10}}/>
                                    <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: 15}}>BUSCAR</Text>
                                  </TouchableOpacity>
                              </View> 
                            </View>
                      </Card>                                              
                    </View>
                    </ScrollView>
        </Modal>

    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: Device.osName=='Windows'? width*0.86 : width*0.7,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },
  viewmodal: {
    backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.99,
  },

});
