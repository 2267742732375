import React, { useState, useContext, useEffect } from 'react';
import { Dimensions, ImageBackground, TouchableOpacity, FlatList, Linking, ActivityIndicator} from 'react-native'
import { useNavigation, useIsFocused  } from '@react-navigation/native';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, TextInput,  } from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
const dotenv = require('../../../app.config');
import * as Network from 'expo-network';
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');

export default function App({route}) {
  const { isaluno, setIsAluno, nomealuno, iscontrato, setIsContrato, visiblesalvar, setVisibleSalvar, accessdev } = useContext(UtilContext);
  const { user } = useContext(AuthContext);
  const navigation = useNavigation();  
  const [ra, setRa] = useState('');
  const [loading, setLoading] = useState(false);
  const [dadoscontrato, setDadosContrato] = useState([]); 
  const [ipadress, setIpAdress] = useState('');
  const [alertvisible, setAlertVisible] = useState(true);

  //const dadoscontrato = [];

  const UpdContratoRemove = async (codcontratoupdrem, idperletupdrem, raupdrem) => {
    if(codcontratoupdrem == '' || idperletupdrem == '' || raupdrem ==''){
      alert(`Ocorreu um erro e não é possível atualizar o contato neste momento.`);
      return false;
    }

    try {
      setLoading(true);  
      var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/updcontratoremove`;  
      
      //TESTE REMOVER
      //console.log("token: "+ JSON.stringify(user));
      
      /* if(user.token == '' || user.token == undefined || user.toker == null){
        alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
        signOut();
        navigation.navigate('Initial');
        return false;
      } */
      
      await fetch(url, {
               method: 'POST',
               body: JSON.stringify({
                   ra: raupdrem?? -1,
                   idperlet: idperletupdrem?? -1,
                   codcontrato: codcontratoupdrem?? -1
               }),
               timeout: 5000,
               headers: {
                   "Content-type": "application/json; charset=UTF-8",
                   "x-access-token": user.token,
               },
             })
           .then((response) => response.json())
           .then((responseJson) => {               
               if (responseJson.sucesso){
                alert('Assinatura Removida com sucesso!');
                fetchData(raupdrem);
               }else{
                //setVisibleSalvar(false);
                 alert(responseJson.message);
               }
               setLoading(false);  

           })
           .catch((error) => {
               //Aviso("Error1:", error.message, "danger", 2500);  
               alert(`Ocorreu um erro inesperado, aguarde um minuto e tente novamente. Necessário efetuar o login novamente.`);
               setLoading(false);                       
           })
           .finally(function () {    
                setLoading(false);                     
           });  
         
   } catch (err) {
       Aviso("Error2:", err.message, "danger", 2500);
       setLoadingContrato(false); 
   } finally {} 
  }

    
  function getcontratos({ item: contratos }) {
    return (
      <View style={{ width: width * 0.8, paddingTop: 10, flexDirection: 'row', height: 40, top: -5, borderBottomColor: '#000', borderBottomWidth: 1.5, }}>
        <View style={{ width: 50 }}><Text style={{}}>{contratos.TBCCONTRATO}</Text></View>
        <View style={{ width: 100 }}><Text style={{}}>{contratos.CODCONTRATO}</Text></View>
        <View style={{ width: 100 }}><Text style={{}}>{contratos.RA}</Text></View>
       
        {contratos.TBCCONTRATO==null || contratos.CODPLANOPGTO==null? (
          <>
            {contratos.TBCCONTRATO==null? (
              <View style={{ width: 300 }}><Text style={{color:'#FF0000'}}>MODALIDADE CURSO: PENDENTE</Text></View>
              ):null}
            {contratos.CODPLANOPGTO==null? (
            <View style={{ width: 300 }}><Text style={{color:'#FF0000'}}>PLANO PGTO: PENDENTE</Text></View>
            ):null}

          </>
        ):null}



        {contratos.TBCCONTRATO!=null && contratos.CODPLANOPGTO!=null? (
        <>
        {/* <View style={{ width: 50 }}><Text style={{}}>{contratos.ITEM}</Text></View> */}
        {/* <View style={{ width: 200}}><Text style={{}}>{contratos.ALUNO}</Text></View> */}
        <View style={{ width: 75}}><Text style={{}}>{contratos.TURNO}</Text></View>
        <View style={{ width: 90}}><Text style={{}}>{contratos.TURMA}</Text></View>
        <View style={{ width: 80 }}><Text style={{}}>{contratos.ASSINADO=='N' ? 'NÃO': 'SIM'}</Text></View>
        <View style={{ width: 370 }}><Text style={{}}>{contratos.CURSO}</Text></View>
        <View style={{ width: 150, flexDirection: 'row', top: -10, left: 20 }}>        
          <TouchableOpacity onPress={() => {
            setIsContrato(false);
            navigation.navigate('ContratosAuto', {
                  count: contratos.COUNT, 
                  codcoligada: contratos.CODCOLIGADA,
                  tbccontrato: contratos.TBCCONTRATO, 
                  item: contratos.ITEM, 
                  aluno: contratos.ALUNO , 
                  codplanopgto: contratos.CODPLANOPGTO, 
                  ra: contratos.RA, 
                  idperlet: contratos.IDPERLET, 
                  codcontrato: contratos.CODCONTRATO, 
                  codcurso: contratos.CODCURSO, 
                  codgrade: contratos.CODGRADE, 
                  codturno: contratos.CODTURNO, 
                  assinado: contratos.ASSINADO,
                  dados: dadoscontrato, 
                  visiblesalvar: true,
                  cmdvoltar: true
                 });
          }}>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Icon name="file-signature" size={23} color="#004b9e" style={{ padding: 10 }} />              
            </View>
          </TouchableOpacity>
       
        </View>        
       {/*  <View style={{ width: 150, flexDirection: 'row', top: -10, left: 20 }}> 
        {contratos.ASSINADO=='S' ? (
          <TouchableOpacity onPress={() => {
            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
              '## CONTRATO ##', // This is a title
              `Deseja Remover Assinantura de:\n
                Aluno: ${contratos.ALUNO}\n 
                RA: ${contratos.RA}\n 
                CODPLANOPGTO: ${contratos.CODPLANOPGTO}\n 
                IDPERLET: ${contratos.IDPERLET}, 
                CODCONTRATO: ${contratos.CODCONTRATO}\n 
                CODCURSO: ${contratos.CODCURSO}\n 
                CODGRADE: ${contratos.CODGRADE}\n 
                CODTURNO: ${contratos.CODTURNO}\n
              `, // This is a alert message
              {
                  textConfirm: 'SIM',      // Label of button confirm
                  textCancel: 'NÃO',       // Label of button cancel
                  onCancel: () => {{}},    // Call your cancel function 
                  onConfirm: () => {{
                    setIsContrato(false);
                    UpdContratoRemove(contratos.CODCONTRATO, contratos.IDPERLET, contratos.RA);
                  }} // Call your confirm function 
              }
            )
              }}>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Icon name="handshake-slash" size={23} color="red" style={{ padding: 10 }} />              
            </View>
          </TouchableOpacity>
         ) : null}
        </View>  */}     
        </>  
        ):null}
      </View>
    )
  }

  const fetchData = async (ra_value) => {  
    try {        
          
          //alert(ra_value);
          //return false;
          setLoading(true);
          var url = `${accessdev==false ?  dotenv.LITERATUS : dotenv.LITERATUSDEV}/alunoscontratos2`;
          await fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({
                      raaluno: ra_value,
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                  },
                })
              .then((response) => response.json())
              .then((responseJson) => {                

                if(responseJson.sucesso){
                  
                  /* dadoscontrato.splice();
                  const contratoalunos = responseJson.dados.map(function(item){
                    dadoscontrato.push({
                          ITEM: item.ITEM,
                          COUNT: item.COUNT, 
                          RA: item.RA,
                          IDPERLET: item.IDPERLET,
                          CODCONTRATO: item.CODCONTRATO,
                          ALUNO: item.ALUNO,
                          ASSINADO: item.ASSINADO,
                          CURSO: item.CURSO,
                          TURNO: item.TURNO,
                          TURMA: item.TURMA,
                          ANOLETIVO: item.ANOLETIVO
                    }) 
                  }) */
                  setDadosContrato(responseJson.dados);
                  //console.log('DadosContrato: '+JSON.stringify(dadoscontrato));
                  setLoading(false);
                }else{
                  alert("Erro1: " + responseJson.message);
                  setLoading(false);
                }            
              })
              .catch((error) => {
                setLoading(false);
                if(error.message === 'Failed to fetch'){
                    alert("Erro na Consulta com Servidor");                      
                  }else{
                    alert("Erro2: " + error.message);
                  }
                                                 
              })
              .finally(()=>{
                setLoading(false);
              })
        
    } catch (err) {
        //Aviso("Error:", err.message, "danger", 3000);          //setLoadingFaltas(false); 
        alert(err.message);
    } finally {{}}
}

  
  

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        const strVoltar = route.params.raaluno ? true : false; 
        //console.log(user);
        if(strVoltar){
          fetchData(route.params.raaluno);
        }

        async function getNetworkAddress () {
          const ipadresstmp = await Network.getIpAddressAsync();
          setIpAdress(ipadresstmp);
      }

      //getNetworkAddress();
        

      }
  }, [focus]);

 useEffect(() => {   // whenever you are in the current screen, it will be true vice versa  
  
}, []);


  return (
    <ImageBackground>      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />         
        <View style={{flexDirection: 'column'}}>
            <Card style={[styles.card,{marginTop: 10, height: 570}]} elevation={5} mode={'elevated'}>
                <Text style={{
                    color: '#4F4F4F',
                    fontSize: 20,
                    fontFamily: 'Time New Roman',
                    fontWeight: 'bold',
                    marginBottom: 10,
              }}>{`:: CONTRATO(S) DO ALUNO  :: ${ipadress}`}</Text>
              
              <View style={{flexDirection: 'row', justifyContent:'space-between', }}>
             
             
                <View style={{flexDirection: 'row',left: 33}}>
                  <View style={{position:'relative', flexDirection:'column', top: 0}}>
                  <TextInput
                      placeholder={`Informe o(s) RA(s)`}
                      style={{ height: 30, width: 300, paddingRight: 20}}
                      editable={true}
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType='default'
                      /* onFocus={()=> {
                        if(alertvisible){
                          alert(`Use aspas simples em torno do(s) RA(s)\n 1 RA = '123456'\n ou RA = '123456','567890',...`);
                          setAlertVisible(false);
                        }
                      }} */
                      value={ra}
                      onChangeText={(item) => setRa(item)}
                  />
                  <Text style={{color:'red', fontSize: 12, fontWeight:'bold'}}>** Separe os RAs com vírgula.</Text>
                  </View>
                  <TouchableOpacity
                        onPress={() => { 
                          //setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);                            
                          if(ra!=''){
                            const splitRA = ra.replaceAll("'","").replaceAll(" ","").split(',');
                            const valuesplit = [];
                            valuesplit.splice();
                            for (let i = 0; i <= splitRA.length -1; i++) {
                                //if(i==0){
                                //  valuesplit.push(`'${splitRA[i]}'`);
                                //}else{
                                  valuesplit.push(`'${splitRA[i]}'`);
                                //}
                            }   
                            fetchData(valuesplit);
                            //alert(valuesplit);
                          }else{
                            alert('Campo Pendente!');
                          }
                        }}
                        style={{ position: 'relative',width: 50, flexDirection:'row', alignItems: 'center', justifyContent: 'center', top: -7 }}>
                      <Icon name="search" size={20} color='#FFD700' style={{padding:5, backgroundColor: '#004b9e'}} />
                  </TouchableOpacity>
                </View>
                
              </View>
             
              <ScrollView style={{height: height, marginTop: 10}}>                                                                                       
              <View style={{/* flex:1,  */width: '100%', alignItems: 'center', justifyContent: 'center'  /* marginTop: 1  */}}> 
                    <View style={{/* overflow:'scroll', */ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent:'center',/* height: 40, */ }}>
                        <View style={{width: width * 0.8,height: height * 0.7,top: 2,borderRadius: 5, borderColor: '#ddd', borderWidth: 2, padding:5}}>
                                <ScrollView>
                                <View style={{ width: width * 0.8, paddingTop: 1, flexDirection: 'row', borderBottomColor: '#000', borderBottomWidth: 1.5 }}>
                                  <View style={{ width: 50 }}><Text style={{ fontWeight: 'bold' }}>#</Text></View>
                                  <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>N°. Contrato</Text></View>
                                  <View style={{ width: 100 }}><Text style={{ fontWeight: 'bold' }}>Reg. Acad.</Text></View>
                                  <View style={{ width: 75 }}><Text style={{ fontWeight: 'bold' }}>Turno</Text></View>
                                  <View style={{ width: 90 }}><Text style={{ fontWeight: 'bold' }}>Turma</Text></View>
                                  <View style={{ width: 80 }}><Text style={{ fontWeight: 'bold' }}>Assinado</Text></View>
                                  <View style={{ width: 370}}><Text style={{ fontWeight: 'bold' }}>Curso</Text></View>
                                  <View style={{ width: 120 }}><Text style={{ fontWeight: 'bold' }}>Abrir Contrato</Text></View>
                                  {/* <View style={{ width: 150 }}><Text style={{ fontWeight: 'bold' }}>Remover Assinatura</Text></View> */}
                                </View>
                                {loading ? (
                                <View style={{ top: 10, alignSelf: 'center', height: 200 }}>
                                    <ActivityIndicator size={50} color='#004b9e' />
                                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Buscando as Informações...`}</Text>
                                </View> 
                                ) : (
                                <FlatList
                                  data={dadoscontrato}
                                  style={{ width: '100%', height: '100%' }}
                                  keyExtractor={(item) => item.ITEM}
                                  showsVerticalScrollIndicator={true}
                                  renderItem={(item) => getcontratos(item)}
                                />
                                )}

                                </ScrollView>
                            </View>                       
                        </View>
              </View>  
              </ScrollView>               
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -5}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
          </View>
        </View>
      </View>
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  pdf: {
    flex:1,
    width:Dimensions.get('window').width,
    height:Dimensions.get('window').height,
  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: width*0.86,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //alignItems: 'center',
    //justifyContent: 'center',
        
  },

});
