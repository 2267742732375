import React, { useState, useContext, useEffect, useCallback , useRef} from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Linking, StyleSheet, Text, View  } from 'react-native'
import { useNavigation, useIsFocused  } from '@react-navigation/native';
//import { StatusBar } from 'expo-status-bar';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, Checkbox , TextInput } from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import { PDFReader, MobilePDFReader } from 'react-read-pdf';
import axios from 'axios';
import Tooltip from "react-native-walkthrough-tooltip";
import Base64Url from 'base64-url';
import * as Device from 'expo-device';//Device.osName
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import ListaContratoAluno from './contratosAlunos';
import ListaAlunosEmpresas from './alunosEmpresas';
import Contratos from './contratos';
import ValidCNPJ from './validcnpj';
import ConsCadAlunoRA from './conscadalunora';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');

export default function App({route}) {
  const { Aviso, dadoscontrato, isaluno, setIsAluno, iscontrato, setIsContrato, visiblesalvar, setVisibleSalvar,
          setRaAlunoReturn, setUserPwd,setUserPwdNew,setNomeAluno } = useContext(UtilContext);
  const { user, signOut } = useContext(AuthContext);
  
  const [loadingcontrato, setLoadingContrato] = useState(false);
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [valuera, setValueRa] = useState('');  
  const [selconsulta, setSelConsulta]=useState(null);
  const [select, setSelect]=useState('');
  const dropdownController = useRef(null);
  const onOpenSuggestionsList = useCallback(isOpened => {}, []);
  const searchRef = useRef(null);
  
  let tipoconsulta = [];
  
  if (user.adminti==1){
  tipoconsulta = [
    {id: 1, title:'Listar Contrato Por Aluno', name: 'Listar Contrato Por Aluno', value: 1},
    {id: 2, title:'Listar Alunos por Empresa', name: 'Listar Alunos por Empresa', value: 2},
    {id: 5, title:'Consulta Cadastro Aluno', name: 'Consulta Cadastro Aluno', value: 5},
    {id: 3, title:'Validar CNPJ Login', name: 'Validar CNPJ Login', value: 3},
    {id: 4, title:'Contratos', name: 'Contratos', value: 4},
  ]
 }else{
    tipoconsulta = [
    {id: 1, title:'Listar Contrato Por Aluno', name: 'Listar Contrato Por Aluno', value: 1},
    {id: 2, title:'Listar Alunos por Empresa', name: 'Listar Alunos por Empresa', value: 2},
    {id: 5, title:'Consulta Cadastro Aluno', name: 'Consulta Cadastro Aluno', value: 5},
    {id: 3, title:'Validar CNPJ Login', name: 'Validar CNPJ Login', value: 3},
    //{id: 4, title:'Contratos', name: 'Contratos', value: 4},
    ]
  }

  const ItemViewTipo = ({ item }) => {
    return (
        <Text onPress={() => getItem(item)}>
            {item.name}
        </Text>
    );
  }

  //GET DO FORM MODAL - TIPO
  const getItem = (item) => {
    setSelect(item.value);
    //alert(item.value)
  };
  
  return (
    <ImageBackground style={{backgroundColor: '#ddd'}}>      
      <View style={{ flex:1, position:'absolute',flexDirection: 'row', height: height*0.98,width: width, /* backgroundColor: '#000', borderColor:'blue', borderWidth:1 */ }}>
        {/* COLOCAR O MENU ABAIXO */}
        {Device.osName=='Windows' ? (
        <RouteMenu />
        ) : null}         
        <View style={{flex:1,position:'relative', flexDirection:'column', top:10}}> 
        <Card elevation={5} mode={'elevated'}>       
              <View style={{/* width: width*0.85,  */height: 100, /* backgroundColor:'green', */ flexDirection:'column', borderBottomColor:'#ddd', borderBottomWidth:3, zIndex: 10}}>
                  <View style={{position:'relative', margin: 10, left: 10}}>
                      <Text style={{color: '#4F4F4F', fontSize: 15, fontFamily: 'Time New Roman',  fontWeight: 'bold', marginLeft: 5}}>
                        {`:: ÁREA RESTRITA ADMIN  / TI - CONSULTAS POR IRREGULARIDADES ::`}
                      </Text>
                  </View>                  
                  <View style={{flex:1, minWidth:300 ,position:'relative', flexDirection:'row', margin: 10, padding: 10, alignItems: 'center',zIndex: 10 }}>
                      <View style={{position:'relative', marginRight: 50, flexDirection:'row', alignItems:'center', top: -5}}>
                            <AutocompleteDropdown
                                    ref={searchRef}
                                    controller={controller => {
                                        dropdownController.current = controller
                                    }}
                                    direction='down'
                                    onOpenSuggestionsList={onOpenSuggestionsList}
                                    ChevronIconComponent={<Feather name="chevron-down" size={20} style={{position: 'relative', color:'red',padding: 10, left: -20}} color="#383b42" />}
                                    ClearIconComponent={<Feather name="x-circle" size={20}  style={{position: 'relative', padding: 10, left: -30 }} color="#FF0000" />}                                                                                                    
                                    clearOnFocus={true}
                                    closeOnBlur={false}
                                    closeOnSubmit={false}
                                    initialValue={{ id: 0}} // or just '2'
                                    position='absolute'
                                    debounce={600}
                                    onSelectItem={item => {
                                        if(item?.name){
                                            getItem(item)
                                        }
                                    }}
                                    onFocus={() => {                                                            
                                            setSelConsulta(null)
                                            setSelect('');
                                    }}
                                    onClear={() => {
                                            setSelConsulta(null)
                                            setSelect('');
                                    }}
                                    suggestionsListMaxHeight={height}
                                    dataSet={tipoconsulta}
                                    textInputProps={{
                                        placeholder: 'Selecione Tipo de Consulta',
                                        autoCorrect: false,
                                        autoCapitalize: 'none',
                                        style: {
                                            borderRadius: 5,
                                            color: '#000',                                                          
                                            backgroundColor: '#fff',
                                            fontSize: 15,
                                            height: 30,   
                                            paddingLeft: 18,                                                                
                                        }}}
                                        inputContainerStyle={{
                                            borderRadius: 5,
                                            color:'#000',
                                            width: 400,
                                            height: 20,
                                        }}
                                        containerStyle={{ flexGrow: 1, flexShrink: 1, }}
                                        renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, }}>{item.name}</Text>}
                                        inputHeight={30}
                                        EmptyResultComponent={<Text style={{ padding: 10, fontSize: 15, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                        useFilter={false}
                                        editable={true}                                  
                                />
                      </View>                                          
                  </View>
                  
              </View>
              <View style={{position: 'relative',height: height*0.7, overflow: 'scroll', zIndex: 8}}>
                  <>
                        {(select==1) ? (
                            <ListaContratoAluno />
                        ) : null} 

                        {(select==2) ? (
                            <ListaAlunosEmpresas />
                        ) : null}
                        
                        {(select==3) ? (
                            <ValidCNPJ />
                        ) : null} 
                        
                        {(select==4) ? (
                            <Contratos />
                        ) : null} 
                        
                        {(select==5) ? (
                            <ConsCadAlunoRA />
                        ) : null} 
                 </>                  
              </View>
              <View style={{/* width: width*0.85,  */height: 35/* , backgroundColor:'yellow' */, flexDirection:'row', padding:5, alignItems:'center',}}>
                    <View style={{position:'relative', margin: 10, flexDirection:'row', alignItems:'center',}}>
                        
                    </View>
                    <View style={{position:'relative', margin: 10, paddingLeft: 30, flexDirection:'row'}}>
                          
                    </View>
              </View>
              <View style={{alignSelf: 'flex-end', height: 30, right:10}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, marginTop: -25 }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
              </View>
        </Card>
        </View>
      </View>      
      
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,
  },
  card: {
    width:Device.osName=='Windows' ? width*0.86 : width*0.95,
    height: Device.osName=='Windows' ? 60 : height,
    paddingLeft: 20,
    paddingTop: 10,        
  },
  logo: {
    resizeMode: 'stretch',
    width: Device.osName=='Windows'? 110 : 50,
    height: Device.osName=='Windows'? 100 : 50,
    overflow: 'hidden',
    borderRadius: 5
  }, 
  orienta: {
    resizeMode: 'stretch',
    width: Device.osName=='Windows'? 110 : 150,
    height: Device.osName=='Windows'? 100 : 150,
    overflow: 'hidden',
    display: 'block',
  }, 

});
