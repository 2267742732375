import React, { useState } from 'react';
import { Linking } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AuthProvider from '../../contexts/auth';

import UtilProvider from '../../contexts/utilidade';
import SuperAlert from 'react-native-super-alert';
import FlashMessage from "react-native-flash-message";

import Initial from '../../routes';
import Home from './home';
import Alunos from './alunosmat';
import AlunosFaltas from './alunosfaltas';
import AlunosNotas from './alunosnotas';
import Financeiro from './financeiro';
import Contratos from './contratos';
import ContratosAuto from './contratosauto';
import LstContratos from './lstcontratos';
import LstContratosAdmin from './lstcontratosadmin';
import Logins from './logins';
import Upload from './upload';
import ManutTi from './manutti';

const Stack = createNativeStackNavigator()

function App() {
    const [islocal, setIsLocal] = useState('');

    Linking.getInitialURL().then((url) => {
        if(url === 'https://contratos.literatus.edu.br/'){
            setIsLocal('CONTRATOS');
        }else{
            setIsLocal('EMPRESAS');
        }
      });
    
    return (
        <NavigationContainer>
            <AuthProvider>
                <UtilProvider>
                    <Stack.Navigator
                        initialRouteName="Initial"
                        screenOptions={screenOptions}>
                        <Stack.Screen
                            name='Initial'
                            component={Initial}
                            options={({ navigation }) => {
                                return {
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:`LITERATUS ${islocal}`,
                                }
                            }}
                        />
                        <Stack.Screen
                            name='Home'
                            component={Home}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='Alunos'
                            component={Alunos}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='AlunosFaltas'
                            component={AlunosFaltas}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='AlunosNotas'
                            component={AlunosNotas}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='Financeiro'
                            component={Financeiro}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='Contratos'
                            component={Contratos}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='ContratosAuto'
                            component={ContratosAuto}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='LstContratos'
                            component={LstContratos}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='LstContratosAdmin'
                            component={LstContratosAdmin}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='Logins'
                            component={Logins}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='Upload'
                            component={Upload}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                        <Stack.Screen
                            name='ManutTi'
                            component={ManutTi}
                            options={{
                                headerTintColor: '#FFF',
                                headerShown: false,
                                title:`LITERATUS ${islocal}`,
                            }}
                        />
                    </Stack.Navigator>
                </UtilProvider>
            </AuthProvider>
            <FlashMessage position="top" /> {/* <--- here as last component */}
            <SuperAlert customStyle={customStyle} />
        </NavigationContainer>

    )
}

export default App;

const screenOptions = {
  headerStyle: {
      backgroundColor: '#f4511e'
  },
  headerTintColor: '#fff',
  headerTitleStyle: {
      fontWeight: 'bold'
  },

}

const customStyle = {
  container: {
      backgroundColor: '#e8e8e8',
      borderRadius: 10,
      //position: 'absolute', 
      zIndex: 2000
      //paddingTop: 0,
  },
  buttonCancel: {
      backgroundColor: '#b51919',
      borderRadius: 5,
      width: 90,
      height: 35,
      left: -30,
  },
  buttonConfirm: {
      backgroundColor: '#4490c7',
      borderRadius: 5,
      width: 90,
      height: 35,
      left: 30,
  },
  textButtonCancel: {
      color: '#fff',
      fontWeight: 'bold'
  },
  textButtonConfirm: {
      color: '#fff',
      fontWeight: 'bold'
  },
  title: {
      color: '#003d69',
      fontSize: 15
  },
  message: {
      color: '#4f4f4f',
      fontSize: 12,
      fontWeight: 'bold',
      paddingBottom: 25,
  }
}

