import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Dimensions, Image, ImageBackground, TouchableOpacity, FlatList, ActivityIndicator, Alert, Linking, Modal } from 'react-native'
import { CommonActions, useNavigation,useIsFocused  } from '@react-navigation/native';
//import {Card, TextInput} from 'react-native-paper'
//import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../../contexts/auth';
import { UtilContext } from '../../../contexts/utilidade';
import {Card, RadioButton, TextInput} from 'react-native-paper'
import { ScrollView } from 'react-native-web';
import RouteMenu from '../../../routes/menu';
import Tooltip from "react-native-walkthrough-tooltip";
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import { PDFReader, MobilePDFReader  } from 'react-read-pdf';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const dotenv = require('../../../app.config');
const moment = require('moment');
import API from '../../../server/axios'


export default function App({route, navigation}) {
  const { Aviso, loadingalunos, setLoadingAlunos, 
          alunos, setAlunos, tipovalue, setTipoValue, 
          totalpag, setTotalPag, pagnumber, setPagNumber, 
          alunosativos, setAlunosAtivo, nometipofiltro, 
          setNomeTipoFiltro, RowspPage, BuscarAlunos,
          lstalunos, setLstAlunos, CleanCampoAlunos } = useContext(UtilContext);
 
  const { user, signOut } = useContext(AuthContext); 
  const [showTip, setTip] = useState(false);
  const [alunosfaltas, setAlunosFaltas] = useState();
  const [nomedoaluno, setNomeAluno] = useState();
  const [timeoutloading, setTimeOutLoading]=useState(0);
  const [filepdf, setFilePdf]=useState('')

  /* const [dia, setDia]=useState(0);
  const [mes, setMes]=useState(0);
  const [ano, setAno]=useState(0); */
  const [dataini, setDataIni]=useState(0);
  const [datafim, setDataFim]=useState(0);
  const [datainiatual, setDataIniAtual]=useState(0);
  const [datafimatual, setDataFimAtual]=useState(0);
  const [datafilterde, setDataFilterde] = useState('');
  const [datafilterate, setDataFilterate] = useState('');
  const [alunoslstfaltas, setLstAlunosFaltas] = useState([]);
  const [loadingalunosfaltas, setLoadingAlunosFaltas] = useState(false);
  const [nometipofiltrofaltas, setNomeTipoFiltrofaltas] = useState(null);

  const [modalVisibleFiltro, setModalVisibleFiltro] = useState(false);
  const [pageatual, setPageAtual] = useState(1);
  const [pagetotal, setPageTotal] = useState(2);
  const [proxpage, setProxPage] = useState(true);
  const [antpage, setAntPage] = useState(false);
  

  //Tipo de Curso
  const [searchQueryTipo, setSearchQueryTipo] = useState('');
  const onChangeSearchTipo = query => setSearchQueryTipo(query);
  const dropdownControllertipo = useRef(null);
  const searchRefTipo = useRef(null);
  const refde = useRef(null);
  const refate = useRef(null);
  const onOpenSuggestionsListTipo = useCallback(isOpened => {}, []);

  function capitalize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
       
        var w = words[a];
        if (
            w.toLowerCase() !== 'de' 
            && w.toLowerCase() !== 'da' 
            && w.toLowerCase() !== 'das' 
            && w.toLowerCase() !== 'do' 
            && w.toLowerCase() !== 'dos' 
            && w.toLowerCase() !== 'em' 
            && w.toLowerCase() !== 'e'  
          ){
          //if(w[0] !== undefined || w[0] !== null)
          words[a] = w[0].toUpperCase() + w.slice(1);
        }else{
          words[a] = w[0] + w.slice(1);
        }
    }
    return words.join(" ");
  }

  const DownloadFile = (data, name) => {
    //const linkSource = `data:application/pdf;base64,${data}`;
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;    
    downloadLink.click();
  };

  /* function getalunos({ item: alunos }) {
    //console.log(LstTicket);
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    let dataagora = `${month}/${date}/${year}`
    const date2 = new Date(dataagora);
    const date1 = new Date(alunos.DTPREVISTA);
    let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
    let dias = moment.duration(diff).asDays();
    //console.log('Dias:', dias);
    
    return (
      <View style={{}}>
        <View style={{ width: width*0.83,  flexDirection: 'row', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
          <View style={{ width: 50, left: 5 }}><Text style={{}}>{alunos.ITEM}</Text></View>
          <View style={{ width: 100 }}><Text style={{}}>{alunos.RA}</Text></View>
          <View style={{ width: 300 }}><Text style={{}}>{alunos.NOME}</Text></View>
          <View style={{ width: 50 }}><Text style={{}}>{capitalize(alunos.ATIVO)}</Text></View>
          <View style={{ width: 120}}><Text style={{}}>{(alunos.TIPO)}</Text></View>
          <View style={{ width: 170 }}><Text style={{}}>{capitalize(alunos.CURSO)}</Text></View>
          <View style={{ width: 100 }}><Text style={{}}>{moment((alunos.DTINICIO).slice(0,10)).format("DD/MM/YYYY")}</Text></View>
          <View style={{ width: 90 }}><Text style={{color: dias < 15 ? '#FF0000': ''}}>{moment((alunos.DTPREVISTA).slice(0,10)).format("DD/MM/YYYY")}</Text></View>
          <View style={{ width: 90, alignItems: 'center', left: -10 }}><Text style={{color: dias < 15 ? '#FF0000': ''}}>{dias >= 0 ?  `${parseInt(dias)} dias` : ''}</Text></View>
          <TouchableOpacity onPress={()=>{

              if(alunos.FALTAS === 0){
                alert('Não existe Falta');
                return
              }

            let mesfiltro = ("00" + datafilterde.slice(0,2)).slice(-2);
            let anofiltro = ("0000" + datafilterde.slice(-4)).slice(-4);
            fetchDataAlunosFaltas(alunos.RA, alunos.NOME, mesfiltro=='00'?mes:mesfiltro, anofiltro=='0000'?ano:anofiltro);
            Aviso('','Pesquisando Faltas, aguarde...', 'success', 2000);                 
          }}>
              <View style={{ width: '10%', alignItems: 'center', left: 23,}}><Text style={{textDecorationLine: 'underline',color: alunos.FALTAS > 0 ?  '#FF0000': '#00008B', fontWeight: alunos.FALTAS > 0 ? 'bold': 'normal'}}>{dias >= 0 ? alunos.FALTAS : ''}</Text></View>
          </TouchableOpacity>
        </View>
      </View>
    )
  } */

  function validarfiltrode(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2/$3")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterde(v);
    setDataIni(v);
  }
  function validarfiltroate(v){
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d{2})(\d)/,"$1/$2/$3")             //Coloca ponto entre o segundo e o terceiro dígitos
    //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    setDataFilterate(v);
    setDataFim(v);
  }

  function validarData (s) {
    var l = s.length
    var j = 0
    var dt = { 0: '', 1:'', 2:'' }
    
    // dias de cada mês
    var n = [ 0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
    
    // divide a data para o objeto "dt"
    for (var i=0; i<l; i++) {
      var c = s[i]
      if (c!=='/')
        dt[j] += c
      else
        j++
    }
    
    // converte strings em número
    var d = +dt[0]
    var m = +dt[1]
    var y = +dt[2]
    
    // atualiza dias do ano bisexto
    n[2] += +(y % 400 === 0 || y % 4 === 0 && y % 100 !== 0)
    
    // regras de validação
    // mês deve ser entre 1-12 e dia deve ser maior que zero
    if (m<1 || m>12 || d<1) {
      return false
    }
    // valida número de dias do mês
    else if (d > n[m]) {
      return false
    }
    
    // passou nas validações
    return true
  }

  /*   function getalunosfaltas({ item: alunosfaltas }) {
    return (
      <View style={{}}>
        <View style={{ flexDirection: 'row', backgroundColor: alunos.ITEM %2 ? '#D3D3D3' : ''}}>
          <View style={{ width: 50, left: 5 }}><Text style={{}}>{alunosfaltas.ITEM}</Text></View>
          <View style={{ width: 100 }}><Text style={{}}>{moment(alunosfaltas.DATA).format("DD/MM/YYYY")}</Text></View>
          <View style={{ width: '100%' }}><Text style={{}}>{alunosfaltas.NOME}</Text></View>            
        </View>
      </View>
    )
  } */

  const fetchData = async (dtini, dtfim) => {
    try {
        if(user.codcgo !== 0){
          setLoadingAlunosFaltas(true);
          setFilePdf('');
          //alert(nometipofiltrofaltas=='Jovem Aprendiz' ? 5 : nometipofiltrofaltas=='Agente Integrador' ? 8 : 0);
          //return
          
         //var url = `${dotenv.SERVER_PHP}/relatoriopdf/relatoriofaltas.php?codcfo=${user.codcgo}&mes=${messel==null? mes : messel}&ano=${anosel==null? ano : anosel}&filtro=${nometipofiltrofaltas}`;
         var url = `${dotenv.SERVER_PHP}/relatoriopdf/relatoriofaltas.php?codcfo=${user.codcgo}&dtini=${dataini}&dtfim=${datafim}&filtro=${nometipofiltrofaltas=='Jovem Aprendiz' ? 5 : nometipofiltrofaltas=='Agente Integrador' ? 8 : 0}`;
         //console.log(url);
         //return
         await API.post(url)                        
          .then(function (responseJson) {
              setLoadingAlunosFaltas(false); 
              if (!responseJson){
                alert('Ocorreu um Erro'); 
                return
              }
              /* if (responseJson.validtoken==false){
                alert(responseJson.message); 
                signOut();
                return
              } */
                //if(responseJson.data.length > 0){
                    //console.log(responseJson.data.file);
                    setFilePdf("data:application/pdf;base64,"  +  responseJson.data.file);
                //} 
                setLoadingAlunosFaltas(false); 
          })
          .catch(function (error) {

            console.log(error);
            setLoadingAlunosFaltas(false);   
            if(error.code ==='ERR_NETWORK'){
              alert('Erro Comunicação com Servidor dos Relatórios');
              return
            }else{
              Aviso("Error1:", error.message, "danger", 3000);  
            }

                
          })
          .finally(function () {  
            setLoadingAlunosFaltas(false);
          });
        }
      } catch (err) {
          setLoadingAlunosFaltas(false); 
          console.log(err);
          Aviso("Error2:", err.message, "danger", 3000);
      } finally {
            setLoadingAlunosFaltas(false); 
      }
    
  }

  const focus = useIsFocused();  // useIsFocused as shown 
  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
      if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
        //colocar data no filtro
        let date = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        let dataagora = `01/${("00"+month).slice(-2)}/${year}`;
        setDataFilterde(dataagora);
        setDataIni(dataagora);
        setDataFilterate(moment().endOf('month').format('DD/MM/YYYY'));
        setDataFim(moment().endOf('month').format('DD/MM/YYYY'));

      }
  }, [focus]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
     if(!loadingalunosfaltas)
     setTimeOutLoading(0);

     if(loadingalunosfaltas)
     setTimeout(() => {
      setTimeOutLoading((parseInt(timeoutloading)+1))
    }, 1000);
  }, [loadingalunosfaltas, timeoutloading]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if (pageatual == 1){
        setAntPage(false);
        setProxPage(true);
    }else{
      setAntPage(true);
    }
  
    if (parseInt(pageatual) < parseInt(pagetotal)){
      setProxPage(true);      
    }else{
      setProxPage(false); 
    }
  
  }, [pageatual]);

  useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
    if (datafilterde.length == 10 && modalVisibleFiltro){
      refate.current.focus();
      //alert('De Tamanho 7');
    }
  }, [datafilterde]);

  //VIEW DO FORM MODAL - TIPO
  const ItemViewTipo = ({ item }) => {
    return (
        <Text style={styles.itemStyle} onPress={() => getItemTipo(item)}>
            {item.name}
        </Text>
    );
  }

  //GET DO FORM MODAL - TIPO
  const getItemTipo = (item) => {
     setNomeTipoFiltrofaltas(item.filtro.trim());
  };

  const Mountpdf = () => {
    return (
       <>
        {Device.osName=='Windows' ? (
            <PDFReader 
            onDocumentComplete={(numPages) => {
              //console.log(numPages);
              setPageTotal(numPages);
            }}
            //showAllPage={true} 
            showAllPage={false} page={pageatual}
            url={filepdf}
          />
        ) : (          

          <MobilePDFReader 
            showAllPage={true} 
            url={filepdf}
          />
        )}
       </>
    )
  }


  return (
    <ImageBackground>      
      <View style={{ flex: 1, flexDirection: 'row', width: '100%', backgroundColor: '#ddd' }}>
        {/* COLOCAR O MENU ABAIXO */}
        <RouteMenu />
         
        <View style={{flexDirection: 'column', left: Device.osName=='Windows' ? null : -70, }}>
            <Card style={[styles.card,{marginTop: 10, height: 80, zIndex: 5}]} elevation={5} mode={'elevated'}>
            <View style={{flexDirection: 'row'}}>                   
              <Text style={{
                color: '#4F4F4F',
                fontSize: Device.osName=='Windows'? 20 : 12,
                fontFamily: 'Time New Roman',
                fontWeight: 'bold',
              }}> </Text>
              <View style={{ padding: 5, marginLeft:  Device.osName=='Windows' ? 10 : -100, borderRadius:5, top: Device.osName=='Windows' ? -5 : 45, width:  Device.osName=='Windows' ? 120 : 80, height:  Device.osName=='Windows'  ? 30 : 23, backgroundColor: '#004b9e', alignItems: 'center', justifyContent: 'center'}}>
                  <TouchableOpacity
                    style={{flexDirection: 'row',}}
                    onPress={() => { 
                      navigation.navigate('Alunos');
                    }}
                  >
                    <Icon name="reply-all" size={Device.osName=='Windows' ? 20 : 10} color="#FFD700" style={{marginRight: 10}}/>
                    <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 10}}>VOLTAR</Text>
                  </TouchableOpacity>
              </View>

            </View>
              <View style={{ flex: 1, width: '100%' }}>
                <Text style={{position: 'relative',top: Device.osName=='Windows' ? 15 : 0, fontWeight: 'bold', alignSelf: 'flex-start', fontSize:Device.osName=='Windows'? 13 : 10  }}>{`EMPRESA: ${user?.nomefantasia ? user?.nomefantasia : user?.nome }`}</Text>                
              </View> 
              <View style={{ alignSelf:  'flex-end', top: Device.osName=='Windows' ? -40 : -2, right: Device.osName=='Windows' ? 70 : null }}>
                 {/*  <RadioButton.Group onValueChange={newValue => setAlunosAtivo(newValue)} value={alunosativos}> */}
                 {Device.osName=='Windows' ? (
                        <View style={{position: 'relative',flexDirection:'row', alignItems: 'center', justifyContent: 'center', width: Device.osName=='Windows' ? 350: 150,  borderTopEndRadius: 5,}}>
                           
                            <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'flex-start', marginBottom: 5, left: -70}}>
                                <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, color: '#004b9e'}}> De:</Text>
                                <TextInput
                                  placeholder={`00/00/0000`}
                                  style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 80, fontSize: Device.osName=='Windows' ? 15 : 9,/*  marginLeft: 10, */ marginRight: 80 }}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  value={datafilterde}
                                  ref={refde}
                                  maxLength={10}
                                  onChangeText={(item) => validarfiltrode(item)}
                                />
                          </View>
                          <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'flex-start', marginBottom: 5, left: -130}}>
                                <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 11, color: '#004b9e'}}>Até:</Text>
                                <TextInput
                                  placeholder={`00/00/0000`}
                                  style={{ height: Device.osName=='Windows' ? 30 : 10, width: Device.osName=='Windows' ? 130 : 80, fontSize: Device.osName=='Windows' ? 15 : 9, /* marginLeft: 10, marginRight: 80  */}}
                                  editable={true}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  keyboardType='numeric'
                                  value={datafilterate}
                                  ref={refate}
                                  maxLength={10}
                                  onChangeText={(item) => validarfiltroate(item)}
                                  onSubmitEditing={()=>{
                                          if (!datafilterde || !datafilterate){
                                            alert(`Informe a data do Filtro.\n De: e Ate:`);
                                            return
                                          }

                                          var patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
                                          if(!patternData.test(datafilterde)){
                                              alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                              return false;
                                          }
                                          if(!patternData.test(datafilterate)){
                                              alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                              return false;
                                          }

                                          if(validarData(datafilterde) && validarData(datafilterate)){
                                            fetchData(datafilterde, datafilterate); 
                                            //alert(`Data OK`);
                                          }else{
                                            alert(`Data(s) informado é inválido!`);
                                            return
                                          }
                                  }}
                                />
                          </View>
                        </View>
                 ) : null}
                    {/* </RadioButton.Group> */} 
                    <View style={{ position: 'relative',flexDirection: 'row', width: Device.osName=='Windows' ? '100%' : 150, left: Device.osName=='Windows' ? -90 : -140,top: Device.osName=='Windows' ? '' : 1}}>
                      {Device.osName=='Windows' ? (
                        <>
                          <AutocompleteDropdown
                                  ref={searchRefTipo}
                                  controller={controller => {
                                      dropdownControllertipo.current = controller
                                  }}
                                  onOpenSuggestionsList={onOpenSuggestionsListTipo}
                                  ChevronIconComponent={<Feather name="chevron-down" size={Device.osName=='Windows'?20 :10} style={{position: 'relative', right: Device.osName=='Windows' ? 20 : 20, top: Device.osName=='Windows' ? 0: -5, color:'red',}} color="#383b42" />}
                                  ClearIconComponent={<Feather name="x-circle" size={Device.osName=='Windows'?20 :10}  style={{position: 'relative', right: Device.osName=='Windows' ? 30: 15, top: Device.osName=='Windows' ? 0: -5}} color="#FF0000" />}                                                                                                    
                                  clearOnFocus={true}
                                  closeOnBlur={false}
                                  closeOnSubmit={false}
                                  initialValue={{ id: '2'}} // or just '2'
                                  position='absolute'
                                  debounce={600}
                                  onSelectItem={item => {
                                      if(item?.name){
                                          getItemTipo(item)
                                      }
                                  }}
                                  onFocus={() => {                                                            
                                    setNomeTipoFiltrofaltas(null)
                                  }}
                                  onClear={() => {
                                    setNomeTipoFiltrofaltas(null)
                                  }}
                                  suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
                                  dataSet={tipovalue}
                                  textInputProps={{
                                      placeholder: 'Tipo de Curso',
                                      autoCorrect: false,
                                      autoCapitalize: 'none',
                                      style: {
                                          borderRadius: 5,
                                          color: '#000',                                                          
                                          backgroundColor: '#fff',
                                          fontSize: 10,
                                          height: Device.osName=='Windows' ? 30 : 20,                                                                          
                                      }}}
                                      inputContainerStyle={{
                                          borderRadius: 5,
                                          color:'#000',
                                          width: Device.osName=='Windows' ? 250 : 150,
                                          height: Device.osName=='Windows' ? 20 : 15,
                                      }}
                                      containerStyle={{ flexGrow: 1, flexShrink: 1, }}
                                      renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, width:'100%'}}>{item.name}</Text>}
                                      inputHeight={30}
                                      EmptyResultComponent={<Text style={{ padding: 10, fontSize: Device.osName=='Windows' ? 15 : 12, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                      useFilter={false}
                                      editable={true}                                  
                              />
                            
                              <View style={{flexDirection: 'column', top: Device.osName=='Windows' ? 2 : 3, left: Device.osName=='Windows' ? 40 : 120, position: 'relative'}}>
                                  <View style={{/* position: 'absolute', right: -30,*/ padding: 5,  borderRadius:5,/* top: 3,  */width: Device.osName=='Windows' ? 120 : 70, height: Device.osName=='Windows' ? 30 : 23, backgroundColor: '#004b9e'}}>
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 
                                            //let mesfiltro = ("00" + datafilterde.slice(0,2)).slice(-2);
                                            //let anofiltro = ("0000" + datafilterde.slice(-4)).slice(-4);
                                            //alert(`Mes: ${mesfiltro} Ano: ${anofiltro}`)
                                            //if(mesfiltro !== '00' && anofiltro !== '0000')
                                            

                                            if (!datafilterde || !datafilterate){
                                              alert(`Informe a data do Filtro.\n De: e Ate:`);
                                              return
                                            }

                                            var patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
                                            if(!patternData.test(datafilterde)){
                                                alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                return false;
                                            }
                                            if(!patternData.test(datafilterate)){
                                                alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                return false;
                                            }

                                            if(validarData(datafilterde) && validarData(datafilterate)){
                                              fetchData(datafilterde, datafilterate); 
                                              //alert(`Data OK`);
                                            }else{
                                              alert(`Data(s) informado é inválido!`);
                                              return
                                            }

                                            
                                          //BuscarAlunos();
                                        }}
                                      >
                                        <Icon name="search" size={Device.osName=='Windows' ? 20: 10} color="#FFD700" style={{marginRight: 10}}/>
                                        <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 10}}>BUSCAR</Text>
                                      </TouchableOpacity>
                                  </View>                              
                              </View>
                          </>
                        ) : (
                          <View style={{flexDirection: 'column', top: Device.osName=='Windows' ? null : 11, right: Device.osName=='Windows' ? null : -200, position: 'relative'}}>
                                  <View style={{padding: 5,  borderRadius:5,width: Device.osName=='Windows' ? 120 : 70, height: Device.osName=='Windows' ? 30 : 23, backgroundColor: '#004b9e'}}>
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 
                                            setModalVisibleFiltro(!modalVisibleFiltro);
                                        }}
                                      >
                                        <Icon name="search" size={Device.osName=='Windows' ? 20: 10} color="#FFD700" style={{marginRight: 10}}/>
                                        <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 10}}>FILTRO</Text>
                                      </TouchableOpacity>
                                  </View>                              
                          </View>
                        )}
                  </View>  
                </View>
            </Card>
            <Card style={[styles.card,{marginTop: 10, height: 500}]} elevation={5} mode={'elevated'}>
               {filepdf !== '' ? (
                <>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', width: width * 0.7}}>
                  <TouchableOpacity
                      onPress={() => {
                        //alert('Teste Dowaload');
                        DownloadFile(filepdf, "Relatório_Aluno_"+user.codcgo);
                      }}
                      style={{ /*flex: 1, */  position: 'relative',width: 100,left: 25, flexDirection:'row', backgroundColor: '#eee' }}>
                    <View style={{width: Device.osName=='Windows' ? 130 : 90, height: Device.osName=='Windows' ? 30 : 20,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: '#004b9e'}}>
                      <Icon name="file-download" size={Device.osName=='Windows' ? 20 : 10} color='#FFD700' style={{ padding: 10 }} />
                      {/* <Text style={{fontWeight:empresa.scodnfe!=0?'bold':'normal', color:empresa.scodnfe!=0?'blue':'black', fontSize: 12}}>{empresa.numnfse!=0 ? empresa.numnfse : '----'}</Text> */}
                      <Text style={{fontWeight:'bold', color:'#FFF', fontSize: Device.osName=='Windows' ? 12 : 11}}>Download</Text>
                    </View>
                  </TouchableOpacity>
                  
                    <View style={{position:'relative', margin: 5}}>
                        <TouchableOpacity
                            onPress={() => { 
                              //setChecked(false);
                              setPageAtual(parseInt(pageatual) > 1 ? parseInt(pageatual) - 1 : 1);                           
                            }}

                            style={{ /*flex: 1, */  position: 'relative',width: 75, height:25, /* right: 60, */ flexDirection:'row' }}>
                          <View style={{width: 75, height:25,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: antpage ? '#004b9e' : '#C0C0C0' }}>
                            {/* <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Ant.'}</Text> */}
                            <Icon name="angle-double-left" size={15} color='#FFD700' style={{ padding: 10 }} />
                          </View>
                        </TouchableOpacity>      
                    </View>
                    <View style={{position:'relative', margin: 5}}>
                          <Text style={{fontWeight: 'bold', fontSize: 15, color: '#004b9e',position:'relative', alignSelf:'center'}}>{pageatual}/{pagetotal}</Text>
                    </View>                                
                    <View style={{position:'relative', margin: 5}}>
                          <TouchableOpacity
                              onPress={() => { 
                                  setPageAtual((parseInt(pageatual) < parseInt(pagetotal)) ? (parseInt(pageatual) + 1) : pagetotal );                            
                              }}

                              style={{position: 'relative',width: 75, height:25,flexDirection:'row'}}>
                            <View style={{width: 75, height:25,borderRadius: 5,flexDirection: 'row', /* right: 50, */ position: 'relative',alignItems: 'center', justifyContent: 'center', backgroundColor: proxpage && parseInt(pagetotal) > 1  ? '#004b9e' : '#C0C0C0' }}>
                              {/* <Text style={{fontWeight:'bold', color:'#FFF', fontSize: 12}}>{'Prox.'}</Text> */}
                              <Icon name="angle-double-right" size={15} color='#FFD700' style={{ padding: 10 }} />
                            </View>
                          </TouchableOpacity>
                    </View>
                    </View>
                </>
               ):null}
              <ScrollView style={{height: height, marginTop: 10}}>   
              {/* <View style={{ flex: 1, width: '100%' }}>
                <Text style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>MATRICULADOS:</Text>
              </View> */}
                                                                                                     
              <View style={{width: '100%', alignItems: 'flex-start', marginTop: 1 }}>
                {(loadingalunosfaltas) ? (
                  <View style={{ top: 10, alignSelf: 'center', height: height }}>
                    <ActivityIndicator size={Device.osName=='Windows' ? 50 : 20} color='#004b9e' />
                    <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: Device.osName=='Windows' ? 15 : 11}}>{`Buscando as Informações...\nPesquisa pode demorar em torno de 20s.\nTempo: ${timeoutloading}s`}</Text>
                    {/* <Text style={{textAlign:'center', fontWeight:'bold', color:'#004b9e', fontSize: 15}}>{`Tempo: ${timeoutloading}`}</Text> */}
                  </View>  
                ) : (
                  <>
                  
                    {/* <View style={{ width: width * 0.8, paddingTop: 0, flexDirection: 'row',}}>
                      <View style={{ width: 50, left: 5 }}><Text style={{fontWeight: 'bold'}}>#</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>RA</Text></View>
                      <View style={{ width: 300 }}><Text style={{fontWeight: 'bold'}}>NOME</Text></View>
                      <View style={{ width: 50 }}><Text style={{fontWeight: 'bold'}}>ATIVO</Text></View>
                      <View style={{ width: 120 }}><Text style={{fontWeight: 'bold'}}>TIPO</Text></View>
                      <View style={{ width: 170 }}><Text style={{fontWeight: 'bold'}}>CURSO</Text></View>
                      <View style={{ width: 100 }}><Text style={{fontWeight: 'bold'}}>ÍNICIO</Text></View>
                      <View style={{ width: 90 }}><Text style={{fontWeight: 'bold'}}>FIM</Text></View>
                      <View style={{ width: 90 }}><Text style={{fontWeight: 'bold'}}>RESTANTE</Text></View>
                      <View style={{ width: '100%' }}><Text style={{fontWeight: 'bold'}}>FALTAS</Text></View>
                    </View>   */}  
                                    
                    {/* <FlatList
                      data={alunoslstfaltas}
                      style={{ width: '100%', }}
                      keyExtractor={(item) => item.ITEM.toString()}
                      showsVerticalScrollIndicator={true}
                      renderItem={(item) => getalunos(item)}
                    />   */}

                                <View style={{width:'100%', /* overflow:'scroll', */height: Device.osName=='Windows' ? '' : 600}}>
                                    {/* <Text style={{flex:1, }}>{guid}</Text> */}    
                                    {/* https://github.com/forthealllight/react-read-pdf */}
                                    {/* <View style={{overflow:'scroll', height:600}}> */}
                                      

                                        { filepdf !== ''? 
                                          (
                                            <Mountpdf />
                                          ):(
                                            <View style={{width: Device.osName=='Windows' ?  width* 0.9 : width*0.6, height: height * 0.5 ,alignItems: 'center', justifyContent: 'center', }}>
                                              <Text style={{textAlign: 'center', fontSize:  Device.osName=='Windows' ? 18 : 12, fontWeight: 'bold', color: '#004b9e'}}>
                                                    Ajuste o filtro conforme a necessidade e acione o botão "Buscar".
                                              </Text>
                                            </View>
                                          )}                                     


                                    {/* </View>  */}      
                                    
                                </View>
                  </>
                )}                                
              </View>  
              </ScrollView>               
            </Card>
            <View style={{alignSelf: 'flex-end', bottom: -5,right: Device.osName=='Windows'? '': 10,}}>
                      <View style={{flexDirection: 'row', padding: 10, right: 50, }}>
                        <TouchableOpacity 
                            style={{marginRight: 30,marginTop: -5}}
                            onPress={() => {
                                const url = `https://t2kconsultoria.com.br`
                                Linking.openURL(url);
                            }}>
                            <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}>{`Desenvolvimento T2K Consultoria\nwww.t2kconsultoria.com.br`}</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={{marginRight: 30, backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.instagram.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="instagram" size={Device.osName=='Windows' ? 20 : 15} color="#DC143C" style={{padding:  3,}}/>
                        </TouchableOpacity>                    
                        
                        <TouchableOpacity 
                            style={{marginRight: -20 ,backgroundColor: '#FFF', borderRadius: 50, width: 25, height: 25, justifyContent: 'center'}}
                            onPress={() => {
                                /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                                const url = `https://www.facebook.com/t2kconsultoria`
                                Linking.openURL(url);
                            }}>
                            {/* <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvimento por T2K Consultoria - www.t2kconsultoria.com.br </Text> */}
                            <Icon name="facebook" size={Device.osName=='Windows' ? 20 : 15} color="#0000FF" style={{padding:  3,}}/>
                        </TouchableOpacity>
                      </View>
            </View>
        </View>        
      </View>
      <Modal animationType='slide' transparent={true} visible={modalVisibleFiltro}>
                    <ScrollView>
                    <View style={styles.viewmodal}>
                      <Card style={[styles.card,{height: 200, width: width * 0.9}]} elevation={5} mode={'elevated'}>  
                            <Text style={{fontSize: 15, fontWeight: 'bold'}}>Selecione o Tipo de Curso:</Text>                     
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 10, left: -10}}>
                             
                              <AutocompleteDropdown
                                  ref={searchRefTipo}
                                  controller={controller => {
                                      dropdownControllertipo.current = controller
                                  }}
                                  onOpenSuggestionsList={onOpenSuggestionsListTipo}
                                  ChevronIconComponent={<Feather name="chevron-down" size={Device.osName=='Windows'?20 :20} style={{position: 'relative', right: Device.osName=='Windows' ? 20 : 20, top: Device.osName=='Windows' ? 0: 0, color:'red',}} color="#383b42" />}
                                  ClearIconComponent={<Feather name="x-circle" size={Device.osName=='Windows'?20 :20}  style={{position: 'relative', right: Device.osName=='Windows' ? 30: 20, top: Device.osName=='Windows' ? 0: 0}} color="#FF0000" />}                                                                                                    
                                  clearOnFocus={true}
                                  closeOnBlur={false}
                                  closeOnSubmit={false}
                                  initialValue={{ id: '2'}} // or just '2'
                                  position='absolute'
                                  debounce={600}
                                  onSelectItem={item => {
                                      if(item?.name){
                                          getItemTipo(item)
                                      }
                                  }}
                                  onFocus={() => {                                                            
                                    setNomeTipoFiltrofaltas(null)
                                  }}
                                  onClear={() => {
                                    setNomeTipoFiltrofaltas(null)
                                  }}
                                  suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
                                  dataSet={tipovalue}
                                  textInputProps={{
                                      placeholder: 'Tipo de Curso',
                                      autoCorrect: false,
                                      autoCapitalize: 'none',
                                      style: {
                                          borderRadius: 5,
                                          color: '#000',                                                          
                                          backgroundColor: '#fff',
                                          fontSize: Device.osName=='Windows' ? 10 : 13,
                                          height: Device.osName=='Windows' ? 30 : 30,                                                                          
                                      }}}
                                      inputContainerStyle={{
                                          borderRadius: 5,
                                          color:'#000',
                                          width: Device.osName=='Windows' ? 250 : '100%',
                                          height: Device.osName=='Windows' ? 20 : 30,
                                      }}
                                      containerStyle={{ flexGrow: 1, flexShrink: 1, }}
                                      renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, width:'100%'}}>{item.name}</Text>}
                                      inputHeight={30}
                                      EmptyResultComponent={<Text style={{ padding: 10, fontSize: Device.osName=='Windows' ? 15 : 12, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                      useFilter={false}
                                      editable={true}                                  
                              />
                            </View>

                            <View style={{flexDirection: 'row', margin: 10, zIndex: -10, top: -18}}>                              
                                <View style={{position: 'relative',flexDirection:'column', width: 150,  borderTopEndRadius: 5,}}>                           
                                    <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'center', marginBottom: 5, paddingLeft: 8, position: 'relative', paddingBottom: 10}}>
                                            <Text style={{position: 'relative',fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 16,top: -10, color: '#004b9e', paddingRight: 10}}> De:</Text>
                                            <TextInput
                                              placeholder={`00/00/0000`}
                                              style={{ height: Device.osName=='Windows' ? 30 : 15, width: Device.osName=='Windows' ? 130 : '100%', fontSize: Device.osName=='Windows' ? 15 : 16,/*  marginLeft: 10,  marginRight: 80*/ }}
                                              editable={true}
                                              autoCapitalize="none"
                                              autoCorrect={false}
                                              keyboardType='numeric'
                                              value={datafilterde}
                                              ref={refde}
                                              maxLength={10}
                                              onChangeText={(item) => validarfiltrode(item)}
                                            />
                                      </View>
                                      <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'center', marginBottom: 5, paddingLeft: 8, position: 'relative'}}>
                                            <Text style={{fontWeight: 'bold', fontSize: Device.osName=='Windows' ? 15 : 16, color: '#004b9e', paddingRight: 10}}>Até:</Text>
                                            <TextInput
                                              placeholder={`00/00/0000`}
                                              style={{ height: Device.osName=='Windows' ? 30 : 15, width: Device.osName=='Windows' ? 130 : '100%', fontSize: Device.osName=='Windows' ? 15 : 16, /* marginLeft: 10, marginRight: 80  */}}
                                              editable={true}
                                              autoCapitalize="none"
                                              autoCorrect={false}
                                              keyboardType='numeric'
                                              value={datafilterate}
                                              ref={refate}
                                              maxLength={10}
                                              onChangeText={(item) => validarfiltroate(item)}
                                              onSubmitEditing={()=>{
                                                      if (!datafilterde || !datafilterate){
                                                        alert(`Informe a data do Filtro.\n De: e Ate:`);
                                                        return
                                                      }

                                                      var patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
                                                      if(!patternData.test(datafilterde)){
                                                          alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                          return false;
                                                      }
                                                      if(!patternData.test(datafilterate)){
                                                          alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                          return false;
                                                      }

                                                      if(validarData(datafilterde) && validarData(datafilterate)){
                                                        fetchData(datafilterde, datafilterate); 
                                                        setModalVisibleFiltro(false);
                                                        //alert(`Data OK`);
                                                      }else{
                                                        alert(`Data(s) informado é inválido!`);
                                                        return
                                                      }
                                              }}
                                            />
                                      </View>
                                </View>  
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 10,bottom: 20, zIndex: -10}}> 
                                <View style={{ position: 'relative', left: -10, /* paddingRight: 120, */ padding: 5,  borderRadius:5, width: Device.osName=='Windows' ? 120 : 120, height: Device.osName=='Windows' ? 30 : 30, backgroundColor: '#004b9e'}}> 
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 

                                            setModalVisibleFiltro(false);
                                        }}
                                      >
                                        <Icon name="window-close" size={Device.osName=='Windows' ? 20: 20} color="#FFD700" style={{marginRight: 10, paddingLeft: 10}}/>
                                        <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 15}}>FECHAR</Text>
                                      </TouchableOpacity>                            
                                </View>
                                <View style={{ position: 'relative',/*right: -30,*/ padding: 5,  borderRadius:5,/* top: 3,  */width: Device.osName=='Windows' ? 120 : 120, height: Device.osName=='Windows' ? 30 : 30, backgroundColor: '#004b9e'}}> 
                                      <TouchableOpacity
                                        style={{flexDirection: 'row',}}
                                        onPress={() => { 

                                            if (!datafilterde || !datafilterate){
                                              alert(`Informe a data do Filtro.\n De: e Ate:`);
                                              return
                                            }

                                            var patternData = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
                                            if(!patternData.test(datafilterde)){
                                                alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                return false;
                                            }
                                            if(!patternData.test(datafilterate)){
                                                alert("Digite a data no formato correto! \n ex.: XX/YY/ZZZZ");
                                                return false;
                                            }

                                            if(validarData(datafilterde) && validarData(datafilterate)){
                                              setModalVisibleFiltro(false)
                                              fetchData(datafilterde, datafilterate); 
                                              //alert(`Data OK`);
                                            }else{
                                              alert(`Data(s) informado é inválido!`);
                                              return
                                            }

                                            
                                          //BuscarAlunos();
                                        }}
                                      >
                                        <Icon name="search" size={Device.osName=='Windows' ? 20: 20} color="#FFD700" style={{marginRight: 10, paddingLeft: 10}}/>
                                        <Text style={{fontWeight: 'bold', color: '#FFD700', fontSize: Device.osName=='Windows' ? 15 : 15}}>BUSCAR</Text>
                                      </TouchableOpacity>                            
                                </View>
                            </View>
                      </Card>                                              
                    </View>
                    </ScrollView>
        </Modal>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    width: width,
    height: height,

  },
  logo: {
    resizeMode: 'stretch',
    width: 110,
    height: 60,
    overflow: 'hidden',
    //marginLeft: 30,
    borderRadius: 5
  },
  card: {
    width: Device.osName=='Windows'? width*0.86 : width*0.7,//400,
    height: 60,
    paddingLeft: 20,
    paddingTop: 10,
    //left: Device.osName=='Windows'? 0 : -70
    //alignItems: 'center',
    //justifyContent: 'center',        
  },
  viewmodal: {
    backgroundColor: '#004b9e',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
  },

});
